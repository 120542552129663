import Head from "next/head";

const Links = ({ canonical }) => (
  <Head>
    <link rel="canonical" href={canonical} />

    <link rel="preconnect" href="https://cdn.savemyexams.com" />
    <link rel="preconnect" href="https://cdn.rudderlabs.com" />
    {/* dns-prefetch as fallback for older browsers */}
    <link rel="dns-prefetch" href="https://cdn.savemyexams.com" />
    <link rel="dns-prefetch" href="https://cdn.rudderlabs.com" />

    <link
      rel="apple-touch-icon"
      sizes="180x180"
      href="https://cdn.savemyexams.com/favicon/apple-touch-icon.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href="https://cdn.savemyexams.com/favicon/favicon-32x32.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="16x16"
      href="https://cdn.savemyexams.com/favicon/favicon-16x16.png"
    />
    <link
      rel="manifest"
      href="https://cdn.savemyexams.com/favicon/site.webmanifest"
    />
    <link
      rel="mask-icon"
      href="https://cdn.savemyexams.com/favicon/safari-pinned-tab.svg"
      color="#265bf6"
    />
    <link
      rel="shortcut icon"
      href="https://cdn.savemyexams.com/favicon/favicon.ico"
    />
    <meta name="msapplication-TileColor" content="#265bf6" />
    <meta
      name="msapplication-config"
      content="https://cdn.savemyexams.com/favicon/browserconfig.xml"
    />
    <meta name="theme-color" content="#ffffff" />
  </Head>
);

export default Links;
