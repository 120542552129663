import Head from "next/head";

const formatTitle = (title) => {
  if (!title) return "Save My Exams";

  return title;
};

const Metadata = ({
  canonical,
  title,
  metaTitle = "The Home of Revision",
  metaDescription = "Exam paper questions organised by topic and difficulty. Our worksheets cover all topics from GCSE, IGCSE and A Level courses. Give them a try and see how you do!",
  metaImage,
  noIndex,
}) => {
  return (
    <Head>
      <title key="title">{formatTitle(title)}</title>

      <meta charSet="utf-8" />
      {noIndex ? (
        <meta key="robots" name="robots" content="noindex" />
      ) : (
        <meta key="robots" name="robots" content="index,follow" />
      )}
      <meta key="rating" name="rating" content="general" />
      <meta
        key="viewport"
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta
        key="copyright"
        name="copyright"
        content="Copyright Save My Exams Ltd. All Rights Reserved."
      />
      <meta key="description" name="description" content={metaDescription} />

      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:url" content={canonical} />
      <meta property="og:site_name" content="Save My Exams" />
      {metaImage && <meta property="og:image" content={metaImage} />}

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@savemyexams" />
      <meta name="twitter:creator" content="@savemyexams" />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      {metaImage && <meta name="twitter:image" content={metaImage} />}
      <meta
        name="facebook-domain-verification"
        content="egjgj7tdbhp7vie3e21jhy22nvw6p9"
      />
      <meta
        name="google-site-verification"
        content="lbJxJ4NK7Sayu5rC9z2rpi6iGPrq_C4ubneTJZjT92Q"
      />
    </Head>
  );
};

export default Metadata;
