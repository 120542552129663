const TikTokIcon = ({ className = "" }) => (
  <svg
    className={className}
    width="28"
    height="28"
    viewBox="0 0 32 32"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M26.4119 6.8448C26.1959 6.73318 25.9857 6.61082 25.7819 6.47817C25.1895 6.08651 24.6463 5.62502 24.1641 5.10364C22.9575 3.7231 22.5069 2.32257 22.3409 1.342H22.3476C22.2089 0.528081 22.2663 0.00146484 22.2749 0.00146484H16.7795V21.2514C16.7795 21.5367 16.7795 21.8187 16.7675 22.0973C16.7675 22.132 16.7641 22.164 16.7621 22.2013C16.7621 22.2166 16.7621 22.2326 16.7588 22.2486C16.7588 22.2526 16.7588 22.2566 16.7588 22.2606C16.7009 23.0231 16.4565 23.7596 16.0471 24.4054C15.6377 25.0513 15.0759 25.5866 14.411 25.9643C13.7181 26.3585 12.9344 26.5653 12.1372 26.5642C9.57683 26.5642 7.5017 24.4764 7.5017 21.898C7.5017 19.3196 9.57683 17.2318 12.1372 17.2318C12.6219 17.2313 13.1036 17.3076 13.5644 17.4578L13.5711 11.8623C12.1722 11.6816 10.7509 11.7928 9.39709 12.1888C8.04326 12.5849 6.78619 13.2572 5.7052 14.1634C4.75801 14.9864 3.96169 15.9684 3.3521 17.0651C3.12012 17.4651 2.24487 19.0723 2.13888 21.6807C2.07222 23.1612 2.51685 24.6951 2.72882 25.329V25.3423C2.86215 25.7156 3.37876 26.9895 4.22068 28.0634C4.89957 28.9248 5.70166 29.6816 6.60112 30.3092V30.2959L6.61445 30.3092C9.27486 32.117 12.2246 31.9984 12.2246 31.9984C12.7352 31.9777 14.4457 31.9984 16.3882 31.0778C18.5426 30.0572 19.7692 28.5367 19.7692 28.5367C20.5528 27.6282 21.1758 26.5928 21.6117 25.475C22.109 24.1678 22.2749 22.5999 22.2749 21.9733V10.6998C22.3416 10.7398 23.2295 11.327 23.2295 11.327C23.2295 11.327 24.5087 12.147 26.5045 12.6809C27.9364 13.0609 29.8655 13.1409 29.8655 13.1409V7.68539C29.1896 7.75871 27.8171 7.5454 26.4119 6.8448Z" />
  </svg>
);

export default TikTokIcon;
