const isLocalStorageAvailable = () => {
  const check = "Sme.local-storage-check";
  try {
    window.localStorage.setItem(check, check);
    window.localStorage.removeItem(check);
    return true;
  } catch {
    return false;
  }
};

export const setItem = (key, value) => {
  if (!isLocalStorageAvailable()) return false;

  window.localStorage.setItem(key, value);
};

export const removeItem = (key) => {
  if (!isLocalStorageAvailable()) return false;

  window.localStorage.removeItem(key);
};

export const isJsonValue = (value) =>
  value?.startsWith("[") || value?.startsWith("{");

export const getItem = (key) => {
  if (!isLocalStorageAvailable()) return false;

  const value = window.localStorage.getItem(key);
  if (!isJsonValue(value)) return value;

  try {
    return JSON.parse(value);
  } catch {
    return null;
  }
};
