import cn from "classnames";
import styles from "./index.module.scss";

// Applies styles to match @mui/icons-material icon components (previously used in this app).
const Icon = ({
  className,
  size = "M",
  svg,
  dataTestId,
}: {
  className?: string;
  size?: "XS" | "S" | "M" | "L";
  dataTestId?: string;
  svg: React.ComponentType<React.SVGProps<SVGGElement>>;
}) => {
  const Component = svg;
  return (
    <Component
      aria-hidden={true}
      className={cn(styles.icon, styles[size.toLowerCase()], className)}
      focusable={false}
      viewBox="0 0 24 24"
      data-testid={dataTestId}
    />
  );
};

export default Icon;
