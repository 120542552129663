import Link from "next/link";
import CloseIcon from "@material-design-icons/svg/filled/close.svg";

import { useGlobalContext } from "src/context/global";
import Wrapper from "src/components/Wrapper";
import ButtonIcon from "src/components/ButtonIcon";
import Icon from "src/components/Icon";

import styles from "./index.module.scss";

const ImportQueueNote = ({ item }) => (
  <>
    <span className="d-block">
      Part <strong>{item.partTitle}</strong> from{" "}
      <Link href={item.postUrl} target="_blank">
        {item.title}
      </Link>
    </span>
    <span className="d-block small">
      Post:{" "}
      <Link
        href={`/nexus/resources/revision-notes/editor/${item.postHash}/`}
        target="_blank"
      >
        {item.postHash}
      </Link>
    </span>
    <span className="d-block small">
      Part:{" "}
      <Link
        href={`/nexus/resources/revision-notes/editor/${item.postHash}/parts/${item.partHash}/`}
        target="_blank"
      >
        {item.partHash}
      </Link>
    </span>
  </>
);

const ImportQueueQuestion = ({ item }) => (
  <>
    <span className="d-block">
      Question <strong>#{item.questionNumber}</strong> from{" "}
      <Link href={item.postUrl} target="_blank">
        {item.title}
      </Link>
    </span>
    <span className="d-block small">
      Post:{" "}
      <Link
        href={`/nexus/resources/topic-questions/editor/${item.postHash}/`}
        target="_blank"
      >
        {item.postHash}
      </Link>
    </span>
    <span className="d-block small">
      Question parts:{" "}
      {item.partHashes.map((partHash, index) => (
        <span key={index}>
          {index !== 0 && ", "}
          <Link
            href={`/nexus/resources/revision-notes/editor/${item.postHash}/parts/${partHash}/`}
            target="_blank"
          >
            {partHash}
          </Link>
        </span>
      ))}
    </span>
  </>
);

const ImportQueueItem = ({ item, index }) => {
  const globalContext = useGlobalContext();
  return (
    <li className="mb-2">
      <Wrapper className="bg-body-secondary p-2 rounded d-flex justify-content-between">
        <div className="me-2">
          {item.postType === "revision-notes" && (
            <ImportQueueNote item={item} />
          )}
          {item.postType === "topic-questions" && (
            <ImportQueueQuestion item={item} />
          )}
        </div>
        <ButtonIcon
          icon={<Icon svg={CloseIcon} />}
          size="S"
          variant="primary"
          className={styles.remove}
          ariaLabel="Remove"
          onClick={() => globalContext.actions.removeImportQueueItem(index)}
        />
      </Wrapper>
    </li>
  );
};

export default ImportQueueItem;
