import useSWR from "swr";

import { KEYS } from "@/config/data/localStorage";
import ROUTES from "@/config/data/routes";
import fetcher from "src/lib/fetcher";
import * as ls from "src/lib/localStorage";

export const ROUTE = `${ROUTES.API.USER_SETTINGS}?include=trial_eligibility`;

export const isEligibleForTrial = (data) => {
  const include = data?.included.find(
    (include) => include.type === "trial_eligibility",
  );

  return Boolean(include?.attributes.is_eligible);
};

const useFreeTrialEligibility = (fetch = true) => {
  const response = useSWR(fetch ? ROUTE : null, fetcher, {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
  });
  const isEligible =
    isEligibleForTrial(response?.data?.data) ??
    ls.getItem(KEYS.freeTrialEligibility) === "true";

  if (isEligible) {
    ls.setItem(KEYS.freeTrialEligibility, isEligible);
  }

  return isEligible ?? false;
};

export default useFreeTrialEligibility;
