import { ApiErrorResponseBody } from "@sme/schemas";

import { DEFAULT_API_ERROR } from "src/config/data/errors";

type ApiMethod = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

type RequestBody<T = unknown> = T | { data: T };

// Matches the old Nexus API response format, i.e. endpoints added > 1 year ago
type GenericOldNexusResponseBody = {
  success?: boolean;
  error?: string;
  message?: string;
};

type ResponseBody<T = unknown> =
  | ApiErrorResponseBody
  | GenericOldNexusResponseBody
  | T;

export type ParsedError = {
  code: number;
  title: string;
  description?: string;
  raw: Error | string;
};

export type ParsedResponse<T = unknown> =
  | {
      data: T;
      error: null;
    }
  | {
      data: null;
      error: ParsedError;
    };

const getApiErrorTitle = (
  error: ApiErrorResponseBody["error"] | string | undefined,
) => {
  if (typeof error === "string") {
    return error;
  }

  if (error?.validation_errors) {
    return error.validation_errors.map((error) => error.message).join(", ");
  }

  if (error?.message) {
    return error.message;
  }

  return undefined;
};

const parseError = (
  body: ApiErrorResponseBody | GenericOldNexusResponseBody,
) => {
  const errorTitle =
    getApiErrorTitle(body.error) ||
    ("message" in body && body.message) ||
    DEFAULT_API_ERROR.title;
  // If "error" or "message" value returned in response, do not return default description.
  const errorDescription =
    body.error || ("message" in body && body.message)
      ? null
      : DEFAULT_API_ERROR.description;
  const errorRaw = new Error(errorTitle);

  return {
    title: errorTitle,
    description: errorDescription,
    raw: errorRaw,
  };
};

const isResponseAnError = (
  body: ResponseBody,
): body is ApiErrorResponseBody | GenericOldNexusResponseBody => {
  return (
    (body as GenericOldNexusResponseBody).success === false ||
    !!(body as ApiErrorResponseBody).error
  );
};

const _call = async <TData = unknown, TBody = unknown>(
  method: ApiMethod,
  url: string,
  body: RequestBody<TBody> | undefined,
): Promise<ParsedResponse<TData>> => {
  try {
    const response = await fetch(url, {
      method,
      ...(body && {
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    });

    let json: ResponseBody<TData> = {};

    try {
      json = response.status != 204 ? await response.json() : {};
    } catch (error) {
      // Ignore error and leave response body as empty object
    }

    // json.success === false || json.error -> Handle error even if statusCode == 200 (current implementation of most endpoints)
    // !response.ok -> Handle error even if statusCode !== 200 (future implementation of endpoints)
    if (!response.ok || isResponseAnError(json)) {
      const parsedError = parseError(json);

      return {
        data: null,
        error: {
          title: parsedError.title,
          description: parsedError.description,
          raw: parsedError.raw,
          code: response.status,
        },
      };
    }

    return { error: null, data: json };
  } catch (error) {
    return {
      data: null,
      error: { ...DEFAULT_API_ERROR, raw: error, code: 500 },
    };
  }
};

const createApiFunction = (method: ApiMethod) => {
  return <TData = unknown, TBody = unknown>(
    url: string,
    body?: RequestBody<TBody>,
  ) => {
    return _call<TData, TBody>(method, url, body);
  };
};

const api = {
  get: createApiFunction("GET"),
  post: createApiFunction("POST"),
  put: createApiFunction("PUT"),
  delete: createApiFunction("DELETE"),
  patch: createApiFunction("PATCH"),
};

export default api;
