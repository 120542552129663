import localFont from "next/font/local";

const jakarta = localFont({
  src: [
    {
      path: "../../fonts/PlusJakartaSans-Variable-Latin-Regular.woff2",
      weight: "200 800",
    },
    {
      path: "../../fonts/PlusJakartaSans-Variable-Latin-Italic.woff2",
      style: "italic",
      weight: "200 800",
    },
  ],
});

/**
 * By adding the --font-plus-jakarta-sans variable to the :root, we can access
 * it inside `src/styles/global/variables.scss` and use it to set a
 * Bootstrap variable.
 */
const Font = () => (
  <style jsx global>{`
    :root {
      --font-plus-jakarta-sans: ${jakarta.style.fontFamily};
    }
  `}</style>
);

export default Font;
