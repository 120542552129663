import Button from "@/components/Button";
import ButtonIcon from "@/components/ButtonIcon";
import LogoutLink from "@/components/LogoutLink";
import MissingCourseButton from "@/components/MissingCourseButton";
import JoinLink from "@/components/Navbar/components/JoinLink";
import JoinModal from "@/components/Navbar/components/JoinModal";
import LoginModal from "@/components/Navbar/components/LoginModal";
import Theme from "@/components/Theme";
import NAVIGATION, { type Level, type Subject } from "@/config/data/navigation";
import ROUTES from "@/config/data/routes";
import { EVENT_TYPES, EVENT_VALUES } from "@/config/data/tracking";
import useHideThemeToggle from "@/lib/hooks/useHideThemeToggle";
import { useUser } from "@/lib/hooks/user";
import * as tracking from "@/lib/tracking";
import ArrowBackIcon from "@material-design-icons/svg/filled/arrow_back.svg";
import ChevronRightIcon from "@material-design-icons/svg/filled/chevron_right.svg";
import CloseIcon from "@material-design-icons/svg/filled/close.svg";
import MenuIcon from "@material-design-icons/svg/filled/menu.svg";
import cn from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Icon from "src/components/Icon";
import { KEYS } from "src/config/data/localStorage";
import * as ls from "src/lib/localStorage";
import { trackInteraction } from "../..";
import Pill from "../Pill";
import styles from "./index.module.scss";

type SetLevel = Dispatch<SetStateAction<Level | null>>;
type SetSubject = Dispatch<SetStateAction<Subject | null>>;

type LevelsProps = {
  level: Level;
  setLevel: SetLevel;
};
const Levels = ({ level, setLevel }: LevelsProps) => {
  const show = !level;
  return (
    <div
      aria-hidden={!show}
      className={cn(styles.menuSection, { [styles.active]: show })}
    >
      <ul className="list-unstyled mb-0">
        {NAVIGATION.tops.map((top, index) => (
          <li key={index}>
            <button
              type="button"
              onClick={() => {
                setLevel(top);
                trackInteraction(
                  EVENT_VALUES.navigationBarInteraction.hovered,
                  [top.title],
                );
              }}
              className={styles.item}
            >
              {top.title}{" "}
              <Icon svg={ChevronRightIcon} className={styles.chevron} />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

type SubjectsProps = {
  level: Level;
  subject: Subject;
  setLevel: SetLevel;
  setSubject: SetSubject;
};
const Subjects = ({ level, subject, setLevel, setSubject }: SubjectsProps) => {
  const show = level && !subject;
  return (
    <div
      aria-hidden={!show}
      className={cn(styles.menuSection, { [styles.active]: show })}
    >
      <div className={styles.menuHeader}>
        <ButtonIcon
          ariaLabel="Back"
          type="button"
          variant="shadow"
          className="me-3"
          onClick={() => setLevel(null)}
          icon={<Icon svg={ArrowBackIcon} size="S" />}
        />
        {level && (
          <Button
            flush
            variant="link"
            tag={level.url ? Link : undefined}
            href={level.url}
            className="fw-bold"
            onClick={() =>
              level.url &&
              trackInteraction(EVENT_VALUES.navigationBarInteraction.clicked, [
                level.title,
              ])
            }
          >
            {level.title}
          </Button>
        )}
      </div>
      <ul className="list-unstyled mb-0">
        {level?.lefts.map((subject, index) => (
          <li key={index}>
            <button
              type="button"
              className={styles.item}
              onClick={() => {
                setSubject(subject);
                trackInteraction(
                  EVENT_VALUES.navigationBarInteraction.hovered,
                  [level.title, subject.title],
                );
              }}
            >
              {subject.title}{" "}
              <Icon svg={ChevronRightIcon} className={styles.chevron} />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

type BoardsProps = {
  level: Level;
  subject: Subject;
  setSubject: SetSubject;
};
const Boards = ({ level, subject, setSubject }: BoardsProps) => {
  const show = !!subject;
  return (
    <div
      aria-hidden={!show}
      className={cn(styles.menuSection, { [styles.active]: show })}
    >
      <div className={styles.menuHeader}>
        <ButtonIcon
          ariaLabel="Back"
          type="button"
          className="me-3"
          variant="shadow"
          onClick={() => setSubject(null)}
          icon={<Icon svg={ArrowBackIcon} size="S" />}
        />
        <Button
          flush
          variant="link"
          tag={subject?.url ? Link : undefined}
          href={subject?.url}
          className="fw-bold"
          onClick={() =>
            subject?.url &&
            trackInteraction(EVENT_VALUES.navigationBarInteraction.clicked, [
              level.title,
              subject.title,
            ])
          }
        >
          {level?.title}{" "}
          <Icon
            svg={ChevronRightIcon}
            className={styles.levelSubjectChevron}
            size="S"
          />{" "}
          {subject?.title}
        </Button>
      </div>
      <ul className="list-unstyled mb-0">
        {subject?.rights.map((board, index) => {
          const Element = board.url ? Link : "button";
          return (
            <li key={index}>
              <Element
                href={board.url}
                className={cn(styles.item, styles.bold)}
                onClick={() =>
                  board?.url &&
                  trackInteraction(
                    EVENT_VALUES.navigationBarInteraction.clicked,
                    [level.title, subject.title, board.title],
                  )
                }
              >
                {board.title}{" "}
                {board.url && (
                  <Icon svg={ChevronRightIcon} className={styles.chevron} />
                )}
              </Element>
              <ul className="list-unstyled">
                {board.links.map((link, index) => (
                  <li key={index}>
                    <Link
                      href={link.url}
                      className={cn(styles.item, styles.indented)}
                      onClick={() =>
                        link?.url &&
                        trackInteraction(
                          EVENT_VALUES.navigationBarInteraction.clicked,
                          [level.title, subject.title, board.title, link.title],
                        )
                      }
                    >
                      {link.title}
                      {!!link?.label && <Pill text={link.label} />}
                      <Icon svg={ChevronRightIcon} className={styles.chevron} />
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const MobileNav = () => {
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [level, setLevel] = useState(null);
  const [subject, setSubject] = useState(null);
  const { user } = useUser();
  const router = useRouter();
  const hideThemeToggle = useHideThemeToggle();

  useEffect(() => {
    const hideMobileNav = () => setShowMobileNav(false);

    router.events.on("routeChangeComplete", hideMobileNav);

    return () => {
      router.events.off("routeChangeComplete", hideMobileNav);
    };
  }, [router]);

  const trackCtaClick = () => {
    ls.setItem(
      KEYS.joinPageViewTrigger,
      EVENT_VALUES.viewJoinPageTriggers.dropdownMobileNavbarJoin,
    );

    tracking.event(EVENT_TYPES.clickedNavigationBarJoinButton, {
      buttonLocation: EVENT_VALUES.buttonLocation.dropdown,
    });
  };

  return (
    <>
      <button
        className={cn(styles.toggler, "d-lg-none")}
        onClick={() => {
          setLevel(null);
          setSubject(null);
          setShowMobileNav(!showMobileNav);
        }}
        type="button"
        aria-controls="site-navigation"
        aria-label="Toggle site navigation"
      >
        {!showMobileNav ? <Icon svg={MenuIcon} /> : <Icon svg={CloseIcon} />}
      </button>
      <div
        className={cn(styles.menuWrapper, { [styles.show]: showMobileNav })}
        id="site-navigation"
        aria-modal={showMobileNav ? "true" : undefined}
        role={showMobileNav ? "dialog" : undefined}
      >
        <div className={styles.menuInner}>
          <Levels setLevel={setLevel} level={level} />
          <Subjects
            setLevel={setLevel}
            setSubject={setSubject}
            level={level}
            subject={subject}
          />
          <Boards setSubject={setSubject} level={level} subject={subject} />

          <div className="mt-3 p-4 pt-0 d-grid gap-3">
            {user && (
              <>
                <Button
                  variant="primary"
                  href={ROUTES.PAGES.ACCOUNT}
                  tag={Link}
                  prefetch={false}
                >
                  My account
                </Button>
                <Button
                  variant="light"
                  href={ROUTES.PAGES.ONBOARDING.ABOUT_YOU}
                  tag={Link}
                  prefetch={false}
                >
                  My learner profile
                </Button>

                <JoinLink
                  text="Upgrade"
                  buttonLocation={EVENT_VALUES.buttonLocation.dropdown}
                  pageReferralMethod={
                    EVENT_VALUES.viewJoinPageTriggers.dropdownMobileNavbarJoin
                  }
                  href={ROUTES.PAGES.ONBOARDING.YOUR_PLAN}
                  onClick={() =>
                    ls.setItem(
                      KEYS.planPageViewTrigger,
                      EVENT_VALUES.viewJoinPageTriggers
                        .dropdownMobileNavbarJoin,
                    )
                  }
                />
              </>
            )}
            {!user && (
              <div className="d-flex flex-column gap-3">
                <JoinModal
                  text="Join now"
                  variant="primary"
                  onClick={trackCtaClick}
                  className="ms-sm-2 w-100"
                  trigger={
                    EVENT_VALUES.shownRegistrationFormTrigger
                      .dropdownMobileNavbarCta
                  }
                />
                <LoginModal buttonVariant="light" />
              </div>
            )}
          </div>
          <div className="text-center pb-4">
            <MissingCourseButton variant="link-primary" />
            {user && (
              <>
                <hr className="opacity-100 mx-4" />
                <LogoutLink className="text-danger" />
              </>
            )}
          </div>
          {!hideThemeToggle && <Theme className={styles.themeButton} />}
        </div>
      </div>
    </>
  );
};

export default MobileNav;
