import cn from "classnames";
import { ReactNode, useState, useTransition } from "react";
import Modal from "react-bootstrap/Modal";

import styles from "./index.module.scss";

const BS_SIZE_PROP = {
  S: "sm",
  M: undefined,
  L: "lg",
  XL: "xl",
} as const;

export const useModal = () => {
  const [isShown, setIsShown] = useState(false);
  const [isShowing, startShowingTransition] = useTransition();
  const [hasClosed, setHasClosed] = useState(false);

  const show = () => {
    startShowingTransition(() => {
      setIsShown(true);
      setHasClosed(false);
    });
  };

  const hide = () => {
    if (!isShown) return;
    setIsShown(false);
    setHasClosed(true);
  };
  return { isShown, isShowing, hasClosed, show, hide };
};

type CommonModalProps = {
  /**
   * Applied to the header, body and footer. Useful for setting background colour classes.
   */
  className?: string;
  /**
   * Optional - if omitted, and a different heading added inside `content` - that heading should have an `id`
   * that matches the modal id plus `-label`, for example `id="quick-answers-label"`.
   */
  headerText?: string;
  hide: () => void;
  isShown: boolean;
  size?: keyof typeof BS_SIZE_PROP;
  centered?: boolean;
  headerChildren?: ReactNode;
  header?: ReactNode;
  content?: ReactNode;
  isDismissable?: boolean;
  onExit?: () => void;
  backdropClassName?: string;
  dialogClassName?: string;
  bodyClassName?: string;
};

/**

---

### Usage

```js
import Modal, { useModal } from "src/components/Modal"

// Use the hook inside a component
const quickAnswersModal = useModal()

// Call `.show()` to open the modal
<Button onClick={() => quickAnswersModal.show()}>Quick Answers</Button>

// Spread the values returned from the `useModal` hook on to the `<Modal/>` component
<Modal {...quickAnswersModal} headerText=“Quick Answers” content={<QuickAnswers/>} size="XL" />
```

---

 */
const CommonModal = ({
  className,
  content,
  headerText,
  hide,
  isShown,
  size = "M",
  centered = false,
  headerChildren,
  header,
  onExit,
  isDismissable = true,
  dialogClassName = "",
  backdropClassName = "",
  bodyClassName = "",
}: CommonModalProps) => (
  <Modal
    show={isShown}
    size={BS_SIZE_PROP[size]}
    centered={centered}
    backdrop={isDismissable ? true : "static"}
    keyboard={isDismissable}
    contentClassName={styles.modal}
    dialogClassName={dialogClassName}
    backdropClassName={backdropClassName}
    onHide={hide}
    onExit={onExit}
  >
    {!header && (
      <Modal.Header className={className} closeButton={isDismissable}>
        {headerChildren}
        <Modal.Title className="mb-0">{headerText}</Modal.Title>
      </Modal.Header>
    )}
    {header}
    <Modal.Body className={cn(className, bodyClassName, "py-0")}>
      {content}
    </Modal.Body>
    <Modal.Footer className={className} />
  </Modal>
);

export default CommonModal;
