import { parseISO, subDays } from "date-fns";
import { RESOURCE_TYPES } from "@/config/data/paths";
import { EVENT_TYPES, EVENT_VALUES } from "@/config/data/tracking";
import * as helpers from "@/lib/helpers";
import * as ls from "@/lib/localStorage";
import { KEYS } from "@/config/data/localStorage";
import * as rudderstack from "@/lib/rudderstack";
import { parseMembershipType, allSubscriptionProducts } from "./products";
import getConfig from "next/config";

const {
  publicRuntimeConfig: { googleAnalyticsMeasurementId },
} = getConfig();

const gaMeasurementId = googleAnalyticsMeasurementId;

/**
 * We transform certain subjects to help track performance by "parent" subjects, currently
 * only "Maths", but we may need to add to this as more content is added to the site.
 */
const PARENT_SUBJECTS = {
  Maths: ["Additional Maths", "Further Maths", "Statistics"],
};

/* Removes a space and a single letter at the end of a string. */
const removeSyllabusLetter = (subject) => subject.replace(/\s[A-Z]$/, "");

const getSubjectForEvent = (subject) => {
  if (!subject) return null;
  const stripped = removeSyllabusLetter(subject);

  const parentSubject = Object.keys(PARENT_SUBJECTS).find((key) => {
    return PARENT_SUBJECTS[key].includes(stripped);
  });

  return parentSubject || stripped;
};

/**
 * We may want to send an event as the page is being closed, e.g. watch video, and rudderstack has a chance to
 * send the event off but doen't have a chance to remove the event from local storage so sends the event again
 * when a user loads up the website again.
 *
 * This function can be used to clear out the in progess list just before closing so no events are duplicated when
 * the user loads the site again.
 */
export const clearInProgressEvents = () => {
  const rudderInProgressKey = Object.keys(window.localStorage).filter(
    (key) => key.startsWith("rudder") && key.endsWith("inProgress"),
  )[0];

  ls.setItem(rudderInProgressKey, {});
};

/** convert date string (eg. 2022-01-01T01:01:01.123Z) into readable format (eg. 2022-01-01 01:01:01) */
const parseDate = (date) => {
  return date ? date.substring(0, 10) : date;
};

const replaceNullOrUndefinedWithEmptyString = (data) => {
  // Rudderstack ignores the attributes with NULL or UNDEFINED, so set them to an empty string to make it persist
  for (const [k, v] of Object.entries(data)) {
    if (v === null || v === undefined) {
      data[k] = "";
    } else if (typeof v === "object") {
      replaceNullOrUndefinedWithEmptyString(v);
    }
  }
};

const getDiscountData = (event, data) => {
  const whitelistedEvents = [
    EVENT_TYPES.viewedOrderConfirmedPage,
    EVENT_TYPES.orderedNewProduct,
    EVENT_TYPES.interactedWithCheckoutPage,
    EVENT_TYPES.startedFreeTrial,
  ];

  if (whitelistedEvents.includes(event) && data.discount > 0) {
    return {
      discount: Number(data.discount) ?? 0,
      discount_type: data.discount_type ?? "",
      revenue: Number(data.revenue) ?? data.price,
      total: Number(data.revenue) ?? data.price,
    };
  }

  return {};
};

const getMembershipData = (event, data) => {
  const whitelistedEvents = [
    EVENT_TYPES.interactedWithJoinPage,
    EVENT_TYPES.directedToCheckoutPage,
    EVENT_TYPES.viewedOrderConfirmedPage,
    EVENT_TYPES.orderedNewProduct,
    EVENT_TYPES.landedOnCheckoutPage,
    EVENT_TYPES.interactedWithCheckoutPage,
    EVENT_TYPES.interactedWithPurchaseWall,
    EVENT_TYPES.initiatedFreeTrial,
    EVENT_TYPES.startedFreeTrial,
  ];
  if (whitelistedEvents.includes(event) && data.planId) {
    const products = allSubscriptionProducts();
    const membership = products.find(
      (product) =>
        product.planId === data.planId && product.currency === data.currency,
    );

    const formattedProduct = {
      category: membership.category,
      currency: membership.currency,
      price: membership.price,
      quantity: membership.quantity,
      product_id: membership.product_id,
      sku: membership.sku,
      name: membership.name,
      discount_type: "",
    };

    const discountData = getDiscountData(event, data);

    const membershipData = {
      ...membership,
      ...discountData,
      price: data.price ?? membership.price,
      products: [formattedProduct],
    };

    return membershipData;
  }

  return {};
};

const transformEventForGA = (event, data) => {
  const whitelistedEvents = [
    EVENT_TYPES.interactedWithJoinPage,
    EVENT_TYPES.directedToCheckoutPage,
    EVENT_TYPES.viewedOrderConfirmedPage,
    EVENT_TYPES.orderedNewProduct,
    EVENT_TYPES.landedOnCheckoutPage,
    EVENT_TYPES.interactedWithCheckoutPage,
  ];
  if (whitelistedEvents.includes(event)) {
    const billingData = helpers.getUserAnalyticsBilling();

    const currency = data.currency || billingData.currency || "gbp";
    const order_id = rudderstack.getSessionId();
    const revenue = data.revenue || data.price || billingData.value || 60;
    const gaData = {
      currency,
      order_id,
      revenue,
    };
    Object.assign(data, gaData);
  }

  return data;
};

/**
 * identify a session / user / non-user
 *
 * we may want to identify a user before
 */
export const identify = () => {
  const isLoggedIn = !!Sme.data.user;
  const userTrackId = Sme.data.user?.hash || "";

  let userTrackData = {
    pageStyle: `${ls.getItem("Sme.theme") || "light"} mode`,
  };

  let extraTrackData = {
    version: window.sme_version,
  };

  if (isLoggedIn) {
    const billingData = helpers.getUserAnalyticsBilling();

    const data = {
      billingCountry: billingData.country,
      customerType: EVENT_VALUES.userRelationship[Sme.data.user.relationship],
      email: window.Sme.data.user.email,
      lastPaymentDate: parseDate(billingData.lastPaymentDate),
      ltv: billingData.value,
      ltvCurrency: billingData.currency,
      membershipStartDate: parseDate(billingData.startDate),
      membershipType: parseMembershipType(billingData.cycle),
      nextPaymentDate: parseDate(billingData.nextPaymentDate),
      marketingOptIn: parseDate(Sme.data.user.marketingOptedInAt),
    };
    Object.assign(userTrackData, data);
  }

  const name = Sme.data.user?.name || "";
  // Very rudimentary way to get parts of a name. Just treat last word as surname and first as first name. If name is one word then treat it as first name.
  // can revise when we have our own checkout flow and can split out first and last name in user creation
  const names = name ? name.split(" ") : [];
  const firstName = names.shift() || "";
  const surname = names.pop() || "";
  const isMember = Sme.data?.user?.roles?.includes("member") || false;
  const isStaff = Sme.data?.user?.roles?.includes("staff") || false;

  const hasActiveSubscription = isMember;
  const userAnalyticsBilling = helpers.getUserAnalyticsBilling();
  const isBillingInfoEmpty =
    !userAnalyticsBilling || Object.keys(userAnalyticsBilling).length === 0;
  const member = isLoggedIn
    ? hasActiveSubscription
      ? "active"
      : isBillingInfoEmpty
        ? "registered free user"
        : "formerly active"
    : "prospect";

  const isFreeTrialMember =
    userAnalyticsBilling &&
    userAnalyticsBilling.isFreeTrialMember &&
    parseISO(userAnalyticsBilling.startDate) >= subDays(new Date(), 7);

  const countryOfStudy = Sme.data.user?.countryName || "";

  const userYearGroup = Sme.data.user?.yearGroup || {};
  const yearGroup = userYearGroup ? userYearGroup.name : "";
  const standardisedYearGroup = userYearGroup
    ? userYearGroup.standardisedValue
    : null;

  const courses = Sme.data.user?.courses || [];
  const courseObject = (courses || []).map(({ titles }) => titles);
  const courseArray = (courses || [])
    .map(
      ({ titles }) =>
        `${titles.level} ${titles.subject}${
          titles.board ? ` ${titles.board}` : ""
        }`,
    )
    .filter((title, index, arr) => index === arr.indexOf(title));

  Object.assign(userTrackData, {
    isRegistered: isLoggedIn,
    hasActiveSubscription,
    member,
    isStaff,
    isFreeTrialMember,
    surname,
    firstName,
    countryOfStudy,
    yearGroup,
    standardisedYearGroup,
    courseObject,
    courseArray,
  });

  replaceNullOrUndefinedWithEmptyString(userTrackData);
  console.log("RDS - identify...", userTrackId, userTrackData, extraTrackData);

  // If user is now logged out, but was previously logged in, reset the user identity
  // to ensure membership traits aren't inhereted by the logged out user
  if (userTrackId === "" && rudderstack.getUserId()) {
    reset();
  }

  window.rudderanalytics.identify(userTrackId, userTrackData, extraTrackData);

  window.gtag("config", gaMeasurementId, {
    user_id: userTrackId || null,
    user_type: userTrackData.customerType,
    membershipType: userTrackData.membershipType,
  });
};

export const identifyConversionTrigger = ({
  registrationTrigger = null,
  paywallTrigger = null,
}) => {
  const userTrackId = Sme.data.user?.hash || "";
  const trackingData = registrationTrigger
    ? { registrationTrigger }
    : { paywallTrigger };

  if (!registrationTrigger && !paywallTrigger) return;
  console.log("RDS - identify...", userTrackId, trackingData);
  window.rudderanalytics.identify(userTrackId, trackingData);
};

export const trackViewedOtherPage = () => {
  setTimeout(() => {
    if (window.Sme.track && !window.Sme.track.viewedPageEventTriggered) {
      event(EVENT_TYPES.viewedOtherPage);
    }
  }, 2000);
};

const PREFIXES_ELIGIBLE_FOR_VIEWED_PAGE_EVENT = ["Viewed", "Landed"];
/**
 * sends a track event to RudderStack
 *
 * @param {EVENT_TYPES} eventType
 * @param {*} eventData
 */
export const event = (...args) => {
  const eventType = args[0];
  const eventPrefix = eventType.split(" ")[0];

  if (
    PREFIXES_ELIGIBLE_FOR_VIEWED_PAGE_EVENT.includes(eventPrefix) ||
    eventType === EVENT_TYPES.pageNotFound
  ) {
    window.Sme.track.viewedPageEventTriggered = true;
  }

  window.requestIdleCallback(
    () => {
      rudderstack.ready(() => eventOnReady(...args));
    },
    { timeout: 500 },
  );
};

const eventOnReady = async function (eventType, eventData) {
  if (!eventData) {
    eventData = {};
  }

  const isMember = Sme.data?.user?.roles?.includes("member") || false;
  const page = document.title;
  const pageUrl = window.location.href;
  const previousPage = window.Sme.track._previousLocation
    ? window.Sme.track._previousLocation.href
    : null;

  const level = eventData.level;
  const subject = eventData.subject;
  const subjectAlias = eventData.subjectAlias;
  const subjectModule = eventData.module;
  const board = eventData.board;
  const year = eventData.year;
  const section = eventData.section;
  const topic = eventData.topic;
  const subtopic = eventData.subtopic;
  const subarea = eventData.subarea;
  const courseData = {
    course: [level, subjectAlias || subject, board].filter((v) => v).join(" "),
    level,
    subject: getSubjectForEvent(eventData.subject),
    module: subjectModule,
    board,
    year,
    content_ids: [level, subjectAlias || subject, board, subjectModule]
      .filter((v) => v)
      .map((e) => e.replace(/\s+/g, "_"))
      .join("_"),
  };
  const fullCourseData = Object.assign({}, courseData, {
    section,
    topic,
    subtopic,
    difficulty: subarea,
  });

  const pageResourceSectionTitles = [
    "Past Papers",
    "Past Paper Topic Questions",
    "Topic Questions",
    "Gold Questions",
    "Practice Papers",
    "Bronze-Silver-Gold Questions",
    "Past Paper",
  ];
  if (pageResourceSectionTitles.includes(fullCourseData.section)) {
    fullCourseData.section = null;
  }

  const baseData = {
    version: window.sme_version,
    page,
    pageUrl,
    previousPage,
  };

  const lastViewedCourse = {
    lastViewedCourse: ls.getItem(KEYS.lastViewedCourse)
      ? ls.getItem(KEYS.lastViewedCourse)
      : {},
  };

  let utmTagsData = { utmTagCampaign: null, utmTagSource: null };
  try {
    const urlObj = new URL(document.location.href);
    const utmTagCampaign = urlObj.searchParams.has("utm_campaign")
      ? urlObj.searchParams.get("utm_campaign")
      : null;
    const utmTagSource = urlObj.searchParams.has("utm_source")
      ? urlObj.searchParams.get("utm_source")
      : null;
    utmTagsData = { utmTagCampaign, utmTagSource };
  } catch {
    // Swallow this exception - URL API not supported.
  }

  if (eventData.membershipType) {
    eventData.membershipType = parseMembershipType(eventData.membershipType);
  }

  let data = Object.assign({}, baseData);

  switch (eventType) {
    case EVENT_TYPES.loggedIn:
      Object.assign(data, utmTagsData, {
        result: eventData.outcome,
        outcome: eventData.outcome,
      });
      break;

    case EVENT_TYPES.loggedOut:
      break;

    case EVENT_TYPES.pageNotFound:
      Object.assign(data, {
        errorType: eventData.errorType,
      });
      break;

    case EVENT_TYPES.downloadedPdf:
      Object.assign(data, fullCourseData, utmTagsData, {
        format: eventData.format,
        interaction: eventData.interaction,
        resourceType: eventData.resourceType,
        contentAccess: eventData.contentAccess,
        result: eventData.outcome,
        outcome: eventData.outcome,
        numberQuestions: eventData.numberQuestions,
        numberQuestionParts: eventData.numberQuestionParts,
      });
      break;

    case EVENT_TYPES.viewedPdfOverviewPage:
      Object.assign(data, courseData, utmTagsData, {
        resourceType: eventData.resourceType,
        hasMultipleSyllabusVersions: eventData.hasMultipleSyllabusVersions,
      });
      break;

    case EVENT_TYPES.viewedRevisionNotePage:
      Object.assign(data, eventData, fullCourseData, utmTagsData, {
        resourceType: EVENT_VALUES.resourceType.revisionNote,
        hasVideo: eventData.hasVideo,
        hasMultipleSyllabusVersions: eventData.hasMultipleSyllabusVersions,
        outcome: eventData.outcome,
      });
      ls.setItem(
        KEYS.lastViewedCourse,
        JSON.stringify({
          ...fullCourseData,
          pageUrl,
          resourceType: RESOURCE_TYPES.revisionNotes,
        }),
      );
      break;

    case EVENT_TYPES.viewedTopicQuestionsPage:
      Object.assign(data, fullCourseData, {
        resourceType: EVENT_VALUES.resourceType.topicQuestion,
        format: eventData.format,
        hasDifficulty: eventData.hasDifficulty,
        hasVideo: eventData.hasVideo,
        numberQuestions: eventData.numberQuestions,
        numberQuestionParts: eventData.numberQuestionParts,
        questionNumber: eventData.questionNumber,
        hasMultipleSyllabusVersions: eventData.hasMultipleSyllabusVersions,
        markingMethod: eventData.markingMethod,
        questionType: EVENT_VALUES.topicQuestionType[eventData.questionType],
      });
      ls.setItem(
        KEYS.lastViewedCourse,
        JSON.stringify({
          ...fullCourseData,
          pageUrl,
          resourceType: RESOURCE_TYPES.topicQuestions,
        }),
      );
      break;

    case EVENT_TYPES.viewedPastPapersPage:
      Object.assign(data, fullCourseData, {
        resourceType: EVENT_VALUES.resourceType.pastPaper,
      });
      ls.setItem(KEYS.lastViewedCourse, JSON.stringify(fullCourseData));
      break;

    case EVENT_TYPES.viewedOverviewPage:
      Object.assign(data, utmTagsData, fullCourseData, {
        resourceType: eventData.resourceType,
        overviewType: eventData.overviewType,
        format: eventData.format,
        hasMultipleSyllabusVersions: eventData.hasMultipleSyllabusVersions,
      });
      ls.setItem(KEYS.lastViewedCourse, JSON.stringify(fullCourseData));
      break;

    case EVENT_TYPES.viewedAiMarkingModal:
      Object.assign(data, utmTagsData, fullCourseData, eventData);
      break;

    case EVENT_TYPES.interactedWithAiMarkingModal:
      Object.assign(data, utmTagsData, fullCourseData, eventData);
      break;

    case EVENT_TYPES.viewedAiMarkingExplanationModal:
    case EVENT_TYPES.interactedWithAiMarkingExplanationModal:
      Object.assign(data, utmTagsData, fullCourseData, eventData);
      break;

    case EVENT_TYPES.shownAiMarkingResults:
      Object.assign(data, utmTagsData, fullCourseData, eventData);
      break;

    case EVENT_TYPES.watchedVideo:
      Object.assign(data, utmTagsData, fullCourseData, {
        resourceType: eventData.resourceType,
        format: eventData.format,
        contentAccess: eventData.contentAccess,
        interaction: eventData.interaction,
        duration: eventData.duration,
        videoPlayTime: eventData.videoPlayTime,
        percent: eventData.percent,
        result: eventData.outcome,
        outcome: eventData.outcome,
        numberQuestions: eventData.numberQuestions,
        numberQuestionParts: eventData.numberQuestionParts,
        questionType: EVENT_VALUES.topicQuestionType[eventData.questionType],
        questionNumberPart: eventData.questionNumberPart,
        questionNumber: eventData.questionNumber,
      });
      break;

    case EVENT_TYPES.viewedSolution:
      Object.assign(data, fullCourseData, {
        resourceType: EVENT_VALUES.resourceType.topicQuestion,
        format: EVENT_VALUES.topicQuestionFormat.new,
        interaction: eventData.interaction,
        contentAccess: eventData.contentAccess,
        duration: eventData.duration,
        videoPlayTime: eventData.videoPlayTime,
        percent: eventData.percent,
        result: eventData.outcome,
        outcome: eventData.outcome,
        questionType: EVENT_VALUES.topicQuestionType[eventData.questionType],
        numberQuestions: eventData.numberQuestions,
        numberQuestionParts: eventData.numberQuestionParts,
        questionNumberPart: eventData.questionNumberPart,
        questionNumber: eventData.questionNumber,
      });
      break;

    case EVENT_TYPES.shownPopup:
      Object.assign(data, courseData, {
        popupType: eventData.popupType,
        resourceType: eventData.resourceType,
        trigger: eventData.trigger,
      });
      break;

    case EVENT_TYPES.interactedWithPopup:
      Object.assign(data, courseData, {
        interaction: eventData.interaction,
        popupType: eventData.popupType,
        resourceType: eventData.resourceType,
        trigger: eventData.trigger,
      });
      break;

    case EVENT_TYPES.viewedJoinPage:
      Object.assign(data, lastViewedCourse, {
        pageReferralMethod: eventData.pageReferralMethod,
        currency: eventData.currency,
      });
      break;

    case EVENT_TYPES.viewedRegistrationPage:
      Object.assign(data, lastViewedCourse, {
        registrationStage: eventData.registrationStage,
        pageReferralMethod: eventData.pageReferralMethod,
        registrationPageAccessMethod: eventData.registrationPageAccessMethod,
      });
      break;

    case EVENT_TYPES.interactedWithJoinPage:
      Object.assign(data, lastViewedCourse, {
        interaction: eventData.interaction,
        membershipType: eventData.membershipType,
        membershipInteraction: eventData.membershipInteraction,
        questionInteraction: eventData.questionInteraction,
        couponId: eventData.couponId,
        outcome: eventData.outcome,
        products: eventData.products,
        planId: eventData.planId,
        currency: eventData.currency,
      });
      break;

    case EVENT_TYPES.assessedAnswer:
      Object.assign(data, fullCourseData, {
        format: EVENT_VALUES.topicQuestionFormat.new,
        resourceType: EVENT_VALUES.resourceType.topicQuestion,
        contentAccess: EVENT_VALUES.contentAccess.paid,
        interaction: eventData.interaction,
        result: isMember
          ? EVENT_VALUES.outcome.successful
          : EVENT_VALUES.outcome.unsuccessful,
        outcome: isMember
          ? EVENT_VALUES.outcome.successful
          : EVENT_VALUES.outcome.unsuccessful,
        questionType: EVENT_VALUES.topicQuestionType[eventData.questionType],
        hasVideo: eventData.hasVideo,
        numberQuestions: eventData.numberQuestions,
        numberQuestionParts: eventData.numberQuestionParts,
        questionNumber: eventData.questionNumber,
        questionNumberPart: eventData.questionNumberPart,
        markingMethod: eventData.markingMethod,
        isAnswerCorrect: eventData.isAnswerCorrect,
      });
      break;

    case EVENT_TYPES.interactedWithHomePage:
      Object.assign(data, lastViewedCourse, {
        interaction: eventData.interaction,
        membershipType: eventData.membershipType,
        couponId: eventData.couponId,
      });
      break;

    case EVENT_TYPES.interactedWithRevisionNotePage:
      Object.assign(data, utmTagsData, fullCourseData, utmTagsData, {
        resourceType: EVENT_VALUES.resourceType.revisionNote,
        contentAccess: eventData.contentAccess,
        interaction: eventData.interaction,
        result: eventData.outcome,
        outcome: eventData.outcome,
        hasVideo: eventData.hasVideo,
      });
      break;

    case EVENT_TYPES.interactedWithTopicQuestionsPage:
      Object.assign(data, utmTagsData, fullCourseData, {
        format: eventData.format
          ? eventData.format
          : EVENT_VALUES.topicQuestionFormat.new,
        resourceType: EVENT_VALUES.resourceType.topicQuestion,
        interaction: eventData.interaction,
        contentAccess: eventData.contentAccess,
        result: eventData.outcome,
        outcome: eventData.outcome,
        questionSource:
          EVENT_VALUES.topicQuestionSource[eventData.questionSource],
        questionType: EVENT_VALUES.topicQuestionType[eventData.questionType],
        hasDifficulty: eventData.hasDifficulty,
        hasVideo: eventData.hasVideo,
        numberQuestions: eventData.numberQuestions,
        numberQuestionParts: eventData.numberQuestionParts,
        questionNumberPart: eventData.questionNumberPart,
        questionNumber: eventData.questionNumber,
        markingMethod: eventData.markingMethod,
        isAnswerCorrect: eventData.isAnswerCorrect,
      });
      break;

    case EVENT_TYPES.interactedWithPastPapersPage:
      Object.assign(data, utmTagsData, fullCourseData, {
        resourceType: EVENT_VALUES.resourceType.pastPaper,
        interaction: eventData.interaction,
        contentAccess: eventData.contentAccess,
        result: eventData.outcome,
        outcome: eventData.outcome,
      });
      break;

    case EVENT_TYPES.interactedWithOverviewPage:
      Object.assign(data, utmTagsData, fullCourseData, {
        resourceType: eventData.resourceType,
        interaction: eventData.interaction,
        questionInteraction: eventData.questionInteraction,
        format: eventData.format,
      });
      break;

    case EVENT_TYPES.interactedWithPdfOverviewPage:
      Object.assign(data, utmTagsData, fullCourseData, {
        interaction: eventData.interaction,
      });
      break;

    case EVENT_TYPES.interactedWithRegistrationPage:
      Object.assign(data, utmTagsData, lastViewedCourse, {
        interaction: eventData.interaction,
        outcome: eventData.outcome,
        registrationPageAccessMethod: eventData.registrationPageAccessMethod,
        pageReferralMethod: eventData.pageReferralMethod,
      });
      break;

    case EVENT_TYPES.canceledSubscription:
    case EVENT_TYPES.reactivatedSubscription:
      Object.assign(data, {
        isTrial: eventData.isTrial,
        trialDays: eventData.trialDays,
      });
      break;

    case EVENT_TYPES.cancelledFreeTrial:
    case EVENT_TYPES.reactivatedFreeTrial:
      Object.assign(data, {
        trialDays: eventData.trialDays,
      });
      break;

    case EVENT_TYPES.directedToCheckoutPage:
      Object.assign(data, lastViewedCourse, {
        origin: eventData.origin,
        membershipType: eventData.membershipType,
        couponId: eventData.couponId,
        checkoutPageType: eventData.checkoutPageType,
        planId: eventData.planId,
        currency: eventData.currency,
        isTrial: eventData.trialDays > 0,
        trialDays: eventData.trialDays ?? 0,
      });
      break;

    case EVENT_TYPES.viewedExperimentPage:
      Object.assign(data, utmTagsData, {
        experimentName: eventData.experimentName,
        variationName: eventData.variationName,
      });
      break;

    case EVENT_TYPES.viewedOtherPage:
      Object.assign(data, {
        title: data.page.split(" | ").shift(),
      });
      break;

    case EVENT_TYPES.orderedNewProduct:
      Object.assign(data, {
        membershipType: eventData.membershipType,
        price: eventData.price,
        currency: eventData.currency,
        couponId: eventData.couponId,
        checkout_id: rudderstack.getSessionId(),
        planId: eventData.planId,
        origin: eventData.origin,
        isTrial: eventData.trialDays > 0,
        trialDays: eventData.trialDays ?? 0,
        discount: eventData.discount ?? 0,
        discount_type: eventData.discount_type,
        revenue: eventData.revenue ?? data.price,
      });
      break;

    case EVENT_TYPES.registrationCompleted:
      Object.assign(data, lastViewedCourse, {
        registrationPageAccessMethod: eventData.registrationPageAccessMethod,
        registrationMethod: eventData.registrationMethod,
      });
      break;

    case EVENT_TYPES.viewedOrderConfirmedPage:
      Object.assign(data, lastViewedCourse, {
        membershipType: eventData.membershipType,
        price: eventData.price,
        currency: eventData.currency,
        planId: eventData.planId,
        isTrial: eventData.trialDays > 0,
        trialDays: eventData.trialDays ?? 0,
        discount: eventData.discount ?? 0,
        revenue: eventData.revenue ?? data.price,
      });
      break;

    case EVENT_TYPES.shownBanner:
      Object.assign(data, fullCourseData, {
        bannerType: eventData.bannerType,
        trigger: eventData.trigger,
        resourceType: eventData.resourceType,
        meteredContentType: eventData.meteredContentType,
        hasVideo: eventData.hasVideo,
        meteredLimit: eventData.meteredLimit,
        meterCurrentLevel: eventData.meterCurrentLevel,
      });
      break;

    case EVENT_TYPES.shownPaywall:
      Object.assign(data, fullCourseData, {
        paywallType: eventData.paywallType,
        trigger: eventData.trigger,
        resourceType: eventData.resourceType,
        meteredContentType: eventData.meteredContentType,
        hasVideo: eventData.hasVideo,
        meteredLimit: eventData.meteredLimit,
        format: eventData.format,
      });
      break;

    case EVENT_TYPES.interactedWithBanner:
      Object.assign(data, courseData, {
        bannerType: eventData.bannerType,
        trigger: eventData.trigger,
        resourceType: eventData.resourceType,
        meteredContentType: eventData.meteredContentType,
        hasVideo: eventData.hasVideo,
        meteredLimit: eventData.meteredLimit,
        meterCurrentLevel: eventData.meterCurrentLevel,
        interaction: eventData.interaction,
      });
      break;

    case EVENT_TYPES.interactedWithPaywall:
      Object.assign(data, courseData, {
        interaction: eventData.interaction,
        paywallType: eventData.paywallType,
        trigger: eventData.trigger,
        resourceType: eventData.resourceType,
        meteredContentType: eventData.meteredContentType,
        hasVideo: eventData.hasVideo,
        meteredLimit: eventData.meteredLimit,
        format: eventData.format,
      });
      break;

    case EVENT_TYPES.interactedWithCampaign:
      Object.assign(data, lastViewedCourse, {
        interaction: eventData.interaction,
      });
      break;

    case EVENT_TYPES.viewedOnboardingPage:
      Object.assign(data, lastViewedCourse, {
        onboardingPage: eventData.onboardingPage,
        onboardingPageAccessMethod: eventData.onboardingPageAccessMethod,
      });
      break;

    case EVENT_TYPES.finishedOnboardingPage:
      Object.assign(data, lastViewedCourse, {
        onboardingPage: eventData.onboardingPage,
        onboardingPageFinishingMethod: eventData.onboardingPageFinishingMethod,
        onboardingPageAccessMethod: eventData.onboardingPageAccessMethod,
        numberOfLevels: eventData.numberOfLevels,
        numberOfCourses: eventData.numberOfCourses,
        coursesObject: eventData.coursesObject,
        coursesArray: eventData.coursesArray,
        levels: eventData.levels,
        subjects: eventData.subjects,
        coursesString: eventData.coursesString,
        countryOfStudy: eventData.countryOfStudy,
        yearGroup: eventData.yearGroup,
        standardisedYearGroup: eventData.standardisedYearGroup,
        marketingOptInDefault: "Marketing opt out",
        marketingOptIn: eventData.marketingOptIn,
      });
      break;

    case EVENT_TYPES.finishedOnboarding:
      Object.assign(data, lastViewedCourse, {
        onboardingPagesSkipped: eventData.onboardingPagesSkipped,
        onboardingTimeTaken: eventData.onboardingTimeTaken,
        onboardingPageAccessMethod: eventData.onboardingPageAccessMethod,
      });
      break;

    case EVENT_TYPES.submittedQuickFeedback:
      Object.assign(data, fullCourseData, {
        feedback: eventData.feedback,
        feedbackMethod: eventData.feedbackMethod,
        feedbackResourceType: eventData.feedbackResourceType,
        hasVideo: eventData.hasVideo,
        hasDifficulty: eventData.hasDifficulty,
        numberQuestionParts: eventData.numberQuestionParts,
        numberQuestions: eventData.numberQuestions,
        questionNumber: eventData.questionNumber,
        videoDuration: eventData.videoDuration,
        videoWatchedPercentage: eventData.videoWatchedPercentage,
      });
      break;

    case EVENT_TYPES.changedPageStyle:
      Object.assign(data, {
        styleChange: eventData.styleChange,
      });
      break;

    case EVENT_TYPES.viewedLaunchpadPage:
      Object.assign(data, lastViewedCourse);
      break;

    case EVENT_TYPES.interactedWithLaunchpadPage:
      Object.assign(data, lastViewedCourse, {
        interaction: eventData.interaction,
      });
      break;

    case EVENT_TYPES.shownFreewall:
      Object.assign(data, fullCourseData, {
        freewallType: eventData.freewallType,
        trigger: eventData.trigger,
        resourceType: eventData.resourceType,
        meteredContentType: eventData.meteredContentType,
        hasVideo: eventData.hasVideo,
        meteredLimit: eventData.meteredLimit,
        format: eventData.format,
      });
      break;

    case EVENT_TYPES.interactedWithFreewall:
      Object.assign(data, courseData, {
        interaction: eventData.interaction,
        freewallType: eventData.freewallType,
        trigger: eventData.trigger,
        resourceType: eventData.resourceType,
        meteredContentType: eventData.meteredContentType,
        hasVideo: eventData.hasVideo,
        meteredLimit: eventData.meteredLimit,
      });
      break;

    case EVENT_TYPES.viewedSyllabusModal:
      Object.assign(data, fullCourseData, {
        alternativeSyllabusYear: eventData.alternativeSyllabusYear,
        trigger: eventData.trigger,
        pageType: eventData.pageType,
      });
      break;

    case EVENT_TYPES.landedOnCheckoutPage:
      Object.assign(data, lastViewedCourse, {
        origin: eventData.origin,
        membershipType: eventData.membershipType,
        couponId: eventData.couponId,
        checkoutPageType: eventData.checkoutPageType,
        planId: eventData.planId,
        currency: eventData.currency,
      });
      break;

    case EVENT_TYPES.interactedWithCheckoutPage:
      Object.assign(data, lastViewedCourse, eventData, {
        isTrial: eventData.trialDays > 0,
        trialDays: eventData.trialDays ?? 0,
        discount: eventData.discount ?? 0,
        discount_type: eventData.discount_type ?? "",
        revenue: eventData.revenue ?? eventData.price,
        ...(eventData.promotion_code && {
          promotion_code: eventData.promotion_code,
        }),
      });
      break;

    case EVENT_TYPES.interactedWithSyllabusModal:
      Object.assign(data, fullCourseData, {
        alternativeSyllabusYear: eventData.alternativeSyllabusYear,
        interaction: eventData.interaction,
      });
      break;

    case EVENT_TYPES.clickedNavigationBarJoinButton:
      Object.assign(data, {
        buttonLocation: eventData.buttonLocation,
      });
      break;

    case EVENT_TYPES.viewedArticlePage:
      Object.assign(data, lastViewedCourse, {
        category: eventData.category,
        article: eventData.article,
        author: eventData.author,
        published: parseDate(eventData.published),
      });
      break;

    case EVENT_TYPES.viewedBlogHomepage:
      Object.assign(data, lastViewedCourse);
      break;

    case EVENT_TYPES.viewedBlogCategoryPage:
      Object.assign(data, lastViewedCourse, {
        category: eventData.category,
      });
      break;

    case EVENT_TYPES.interactedWithBlog:
      Object.assign(data, lastViewedCourse, {
        interaction: eventData.interaction,
        category: eventData.category,
        pageType: eventData.pageType,
      });
      break;

    case EVENT_TYPES.viewedMemberDetailsPage:
      Object.assign(data, {
        provider:
          EVENT_VALUES.viewedMemberDetailsPageProvider[eventData.provider],
      });
      break;
    case EVENT_TYPES.viewedLoginPage:
      Object.assign(data);
      break;

    case EVENT_TYPES.interactedWithLoginPage:
      Object.assign(data, {
        interaction: eventData.interaction,
      });
      break;

    case EVENT_TYPES.interactedWithNavigationBar:
      Object.assign(data, {
        navigationBar: eventData.navigationBar,
        interaction: eventData.interaction,
      });
      break;

    case EVENT_TYPES.viewedAiEssayMarkingPage:
      Object.assign(data, courseData, eventData);
      break;

    case EVENT_TYPES.interactedWithAiEssayMarkingPage:
      Object.assign(data, courseData, eventData);
      break;

    case EVENT_TYPES.shownAiEssayMarkingResults:
      Object.assign(data, courseData, eventData);

      break;

    case EVENT_TYPES.viewedPurchaseWall:
      Object.assign(data, {
        currency: eventData.currency,
        trigger: eventData.trigger,
        step: eventData.step,
      });
      break;

    case EVENT_TYPES.interactedWithPurchaseWall:
      Object.assign(data, {
        currency: eventData.currency,
        interaction: eventData.interaction,
        membershipType: eventData.membershipType,
        planId: eventData.planId,
        question: eventData.question,
        outcome: eventData.outcome,
        isTrial: eventData.trialDays > 0,
        trialDays: eventData.trialDays ?? 0,
      });
      break;

    case EVENT_TYPES.interactedWithOnboardingPage:
      Object.assign(data, eventData);

      break;

    case EVENT_TYPES.startedFreeTrial:
      Object.assign(data, {
        membershipType: eventData.membershipType,
        price: eventData.price,
        currency: eventData.currency,
        couponId: eventData.couponId,
        checkout_id: rudderstack.getSessionId(),
        planId: eventData.planId,
        origin: eventData.origin,
        trialDays: eventData.trialDays ?? 0,
        discount: eventData.discount ?? 0,
        discount_type: eventData.discount_type,
        revenue: eventData.revenue ?? data.price,
      });
      break;

    case EVENT_TYPES.initiatedFreeTrial:
      Object.assign(data, {
        currency: eventData.currency,
        membershipType: eventData.membershipType,
        planId: eventData.planId,
        question: eventData.question,
        outcome: eventData.outcome,
        trialDays: eventData.trialDays ?? 0,
      });
      break;

    case EVENT_TYPES.viewedFlashcardCollectionPage:
      Object.assign(data, utmTagsData, fullCourseData, eventData);
      ls.setItem(
        KEYS.lastViewedCourse,
        JSON.stringify({
          ...fullCourseData,
          pageUrl,
          resourceType: RESOURCE_TYPES.flashcards,
        }),
      );
      break;

    case EVENT_TYPES.finishedFlashcardCollection:
      Object.assign(data, utmTagsData, fullCourseData, eventData);
      break;

    case EVENT_TYPES.interactedWithFlashcardCollectionPage:
      Object.assign(data, utmTagsData, fullCourseData, eventData);
      break;

    case EVENT_TYPES.interactedWithFlashcardsOverviewPage:
      Object.assign(data, utmTagsData, fullCourseData, {
        interaction: eventData.interaction,
        format: eventData.format,
      });
      break;

    case EVENT_TYPES.viewedFlashcardsReviewPage:
      Object.assign(data, utmTagsData, fullCourseData, {
        reviewStatus: eventData.reviewStatus,
        format: eventData.format,
      });
      break;

    case EVENT_TYPES.interactedWithFlashcardsReviewPage:
      Object.assign(data, utmTagsData, fullCourseData, {
        interaction: eventData.interaction,
        reviewStatus: eventData.reviewStatus,
        format: eventData.format,
      });
      break;

    case EVENT_TYPES.shownMarketingPopup:
      Object.assign(data, {
        trigger: eventData.trigger,
      });
      break;

    case EVENT_TYPES.interactedWithMarketingPopup:
      Object.assign(data, {
        interaction: eventData.interaction,
        trigger: eventData.trigger,
      });
      break;

    case EVENT_TYPES.viewedInternalAdvert:
      Object.assign(data, {
        advertType: eventData.advertType,
        pageType: eventData.pageType,
      });
      break;

    case EVENT_TYPES.interactedWithInternalAdvert:
      Object.assign(data, {
        interaction: eventData.interaction,
        advertType: eventData.advertType,
        pageType: eventData.pageType,
      });
      break;

    case EVENT_TYPES.interactedWithCarousel:
      Object.assign(data, {
        carouselType: eventData.carouselType,
        interaction: eventData.interaction,
        slideTitle: eventData.slideTitle,
        nextSlideTitle: eventData.nextSlideTitle ?? "",
        prevSlideTitle: eventData.prevSlideTitle ?? "",
      });
      break;

    case "$exposure":
      Object.assign(data, eventData);
      break;

    default:
      alert(`Error #894654658: Analytics event ${eventType} not found.`);
      return;
  }

  replaceNullOrUndefinedWithEmptyString(data);
  const membershipData = getMembershipData(eventType, data);

  Object.assign(data, membershipData);

  transformEventForGA(eventType, data);
  delete data.planId;

  console.log("RDS - event...", eventType, data);

  sendToRudderstack({ event: eventType, data });

  sendGAEvent(eventType, data);
};

const getValueAttribute = (data) => {
  return data.discount > 0 ? data.revenue : data.price;
};

const sendToRudderstack = ({ event, data }) => {
  let value;

  if (
    event === EVENT_TYPES.orderedNewProduct ||
    event === EVENT_TYPES.directedToCheckoutPage
  ) {
    value = getValueAttribute(data);
  }

  window.rudderanalytics.track(event, {
    ...data,
    ...(value && {
      value,
    }),
  });
};

const sendGAEvent = (event, data) => {
  const whitelistedEvents = [
    EVENT_TYPES.viewedJoinPage,
    EVENT_TYPES.interactedWithJoinPage,
    EVENT_TYPES.directedToCheckoutPage,
    EVENT_TYPES.orderedNewProduct,
    EVENT_TYPES.viewedRegistrationPage,
    EVENT_TYPES.interactedWithRegistrationPage,
    EVENT_TYPES.registrationCompleted,
    EVENT_TYPES.landedOnCheckoutPage,
    EVENT_TYPES.interactedWithCheckoutPage,
  ];

  if (!whitelistedEvents.includes(event)) return;

  if (event === EVENT_TYPES.orderedNewProduct) {
    data.value = getValueAttribute(data);
  }

  const gaEventPayload = {
    ...data,
    user_id: Sme.data.user?.hash,
    send_to: gaMeasurementId,
  };
  console.log("Sending event to gtag with data...", gaEventPayload);

  return window.gtag("event", event, gaEventPayload);
};

export const reset = () => {
  return window.rudderanalytics.reset(true);
};
