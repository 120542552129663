import PropTypes from "prop-types";

import { useUsersnapContext } from "src/context/usersnap";

import Button from "src/components/Button";

const MissingCourseButton = ({ variant, className }) => {
  const usersnapApi = useUsersnapContext();
  return (
    <Button
      variant={variant}
      className={className}
      onClick={() => usersnapApi?.logEvent("missing_something_button_clicked")}
    >
      Missing something? Tell us
    </Button>
  );
};

export default MissingCourseButton;

MissingCourseButton.propTypes = {
  variant: PropTypes.oneOf(["gradient", "link-primary"]),
  className: PropTypes.string,
};
