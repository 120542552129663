const REVISION_NOTES = "revision-notes";
const TOPIC_QUESTIONS = "topic-questions";
const PAST_PAPER_QUESTIONS = "past-paper-questions";
const PRACTICE_PAPER_QUESTIONS = "practice-paper-questions";
const PAST_PAPERS = "past-papers";
const PRACTICE_PAPERS = "practice-papers";
const PAGES = "pages";
const ARTICLES = "articles";
const AUTHORS = "authors";
const FLASHCARDS = "flashcards";

const RESOURCE_TYPES = {
  revisionNotes: REVISION_NOTES,
  topicQuestions: TOPIC_QUESTIONS,
  pastPaperQuestions: PAST_PAPER_QUESTIONS,
  practicePaperQuestions: PRACTICE_PAPER_QUESTIONS,
  pastPapers: PAST_PAPERS,
  practicePapers: PRACTICE_PAPERS,
  pages: PAGES,
  articles: ARTICLES,
  authors: AUTHORS,
  flashcards: FLASHCARDS,
};

const RESOURCE_TITLES = {
  [REVISION_NOTES]: "Revision Notes",
  [TOPIC_QUESTIONS]: "Topic Questions",
  [PAST_PAPER_QUESTIONS]: "Past Paper Topic Questions",
  [PRACTICE_PAPER_QUESTIONS]: "Practice Paper Questions",
  [PAGES]: "Pages",
  [ARTICLES]: "Articles",
  [AUTHORS]: "Authors",
  [FLASHCARDS]: "Flashcards",
};

const PARAMS_IN_ORDER = [
  "level",
  "subject",
  "board",
  "subjectAlias",
  "year",
  "module",
  "resource",
  "section",
  "topic",
  "subtopic",
  "area",
  "subarea",
];

export { PARAMS_IN_ORDER, RESOURCE_TITLES, RESOURCE_TYPES };
