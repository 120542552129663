export const EVENT_TYPES = {
  loggedIn: "Logged in",
  downloadedPdf: "Downloaded PDF",
  viewedPdfOverviewPage: "Viewed PDF overview page",
  viewedRevisionNotePage: "Viewed revision note page",
  viewedTopicQuestionsPage: "Viewed topic questions page",
  viewedPastPapersPage: "Viewed past papers page",
  viewedOverviewPage: "Viewed overview page",
  viewedRegistrationPage: "Viewed registration page",
  viewedArticlePage: "Viewed article page",
  viewedAiMarkingModal: "Viewed AI marking modal",
  viewedAiMarkingExplanationModal: "Viewed AI marking explanation modal",
  watchedVideo: "Watched video",
  viewedSolution: "Viewed solution",
  shownPopup: "Shown popup",
  interactedWithPopup: "Interacted with popup",
  viewedJoinPage: "Viewed join page",
  interactedWithJoinPage: "Interacted with join page",
  assessedAnswer: "Assessed answer",
  loggedOut: "Logged out",
  pageNotFound: "Page not found",
  interactedWithHomePage: "Interacted with home page",
  interactedWithRevisionNotePage: "Interacted with revision note page",
  interactedWithTopicQuestionsPage: "Interacted with topic questions page",
  interactedWithPastPapersPage: "Interacted with past papers page",
  interactedWithOverviewPage: "Interacted with overview page",
  interactedWithPdfOverviewPage: "Interacted with PDF overview page",
  interactedWithRegistrationPage: "Interacted with registration page",
  interactedWithFlashcardCollectionPage:
    "Interacted with flashcard collection page",
  interactedWithFlashcardsOverviewPage:
    "Interacted with flashcards overview page",
  interactedWithFlashcardsReviewPage: "Interacted with flashcards review page",
  interactedWithNavigationBar: "Interacted with Navigation Bar",
  interactedWithAiMarkingModal: "Interacted with AI marking modal",
  interactedWithAiMarkingExplanationModal:
    "Interacted with AI marking explanation modal",
  shownAiMarkingResults: "Shown AI marking results",
  canceledSubscription: "Canceled subscription",
  reactivatedSubscription: "Reactivated subscription",
  registrationCompleted: "Completed registration",
  directedToCheckoutPage: "Directed to checkout page",
  viewedExperimentPage: "Viewed experiment page",
  viewedOtherPage: "Viewed other page",
  changedPageStyle: "Changed page style",
  viewedSyllabusModal: "Viewed syllabus change modal",
  interactedWithSyllabusModal: "Interacted with syllabus change modal",
  viewedOrderConfirmedPage: "Viewed order confirmed page",
  orderedNewProduct: "Ordered new product",
  shownBanner: "Shown Banner",
  interactedWithBanner: "Interacted with Banner",
  shownPaywall: "Shown Paywall",
  interactedWithPaywall: "Interacted with Paywall",
  shownFreewall: "Shown freewall",
  interactedWithFreewall: "Interacted with freewall",
  interactedWithCampaign: "Interacted with Campaign",
  viewedOnboardingPage: "Viewed onboarding page",
  finishedOnboardingPage: "Finished onboarding page",
  finishedOnboarding: "Finished onboarding",
  submittedQuickFeedback: "Submitted quick feedback",
  viewedLaunchpadPage: "Viewed launchpad page",
  interactedWithLaunchpadPage: "Interacted with launchpad page",
  interactedWithCheckoutPage: "Interacted with checkout page",
  landedOnCheckoutPage: "Landed on checkout page",
  clickedNavigationBarJoinButton: "Clicked navigation bar join button",
  viewedMemberDetailsPage: "Viewed member details page",
  viewedLoginPage: "Viewed login page",
  interactedWithLoginPage: "Interacted with login page",
  viewedAiEssayMarkingPage: "Viewed AI essay marking page",
  interactedWithAiEssayMarkingPage: "Interacted with AI essay marking page",
  shownAiEssayMarkingResults: "Shown AI essay marking results",
  viewedPurchaseWall: "Viewed purchase wall",
  interactedWithPurchaseWall: "Interacted with purchase wall",
  interactedWithOnboardingPage: "Interacted with onboarding page",
  viewedBlogHomepage: "Viewed blog homepage",
  viewedBlogCategoryPage: "Viewed blog category page",
  interactedWithBlog: "Interacted with blog",
  startedFreeTrial: "Started free trial",
  initiatedFreeTrial: "Initiated free trial",
  cancelledFreeTrial: "Cancelled free trial",
  reactivatedFreeTrial: "Reactivated free trial",
  viewedFlashcardCollectionPage: "Viewed flashcard collection page",
  finishedFlashcardCollection: "Finished flashcard collection",
  viewedFlashcardsReviewPage: "Viewed flashcards review page",
  shownMarketingPopup: "Shown marketing popup",
  interactedWithMarketingPopup: "Interacted with marketing popup",
  viewedInternalAdvert: "Viewed internal advert",
  interactedWithInternalAdvert: "Interacted with internal advert",
  interactedWithCarousel: "Interacted with carousel",
};

export const EVENT_VALUES = {
  outcome: {
    successful: "Successful",
    unsuccessful: "Unsuccessful",
  },
  errorType: {
    e403: "403",
    e404: "404",
    e500: "500",
  },
  contentAccess: {
    free: "Free",
    paid: "Paid",
  },
  resourceType: {
    pastPaper: "Past Papers",
    revisionNote: "Revision Notes",
    topicQuestion: "Topic Questions",
  },
  overviewType: {
    level: "Level Overview",
    subject: "Subject Overview",
    levelSubject: "Level Subject Overview",
    course: "Course Overview",
    courseTopicQuestions: "Course Topic Questions Overview",
    courseRevisionNotes: "Course Revision Notes Overview",
    courseFlashcards: "Course Flashcards Overview",
  },
  topicQuestionFormat: {
    new: "v2",
    old: "v1",
  },
  topicQuestionType: {
    mcq: "Multiple Choice Questions",
    gotit: "Structured Questions",
  },
  topicQuestionSource: {
    "original-content": "Original",
    "past-paper": "Past Paper",
  },
  topicQuestionMarkingMethod: {
    selfMarked: "Self Marked",
    autoMarked: "Auto Marked",
    aiMarked: "AI Marked",
  },
  userRelationship: {
    parent: "Parent of a Student",
    student: "Student",
    teacher_tutor: "Teacher / Tutor",
    teacher: "Teacher",
    tutor: "Tutor",
  },
  downloadPdfInteraction: {
    downloadPdf: "Download PDF",
    downloadPdfBanner: "Download PDF Banner",
    downloadHomepageSample: "Download Homepage Sample",
    downloadFreeSample: "Download Free Sample",
    viewExamplePdf: "View Example PDF",
    downloadPdfOverview: "Download PDF Overview",
  },
  navigationBarInteraction: {
    hovered: "Hovered",
    clicked: "Clicked",
    openedStartStudyingMenu: "Opened start studying menu",
    clickedBackButton: "Clicked back button",
  },
  viewJoinPageTriggers: {
    countdownJoinLink: "Countdown Banner Link",
    launchpadCountdownJoinLink: "Launchpad Countdown Banner Link",
    paywallJoinLink: "Paywall Link",
    loginModal: "Login Modal Link",
    revisionNotesPopupLink: "Popup Link",
    revisionNotesPremiumPaywall:
      "Paywall (Premium Content - Revision Notes) Link",
    topicQuestionsPremiumPaywall:
      "Paywall (Premium Content - Topic Questions) Link",
    pastPapersPremiumPaywall: "Paywall (Premium Content - Past Papers) Link",
    websiteFooter: "Website Footer Link",
    navbarUpgrade: "Navigation Bar Upgrade Button",
    navbarJoin: "Navigation Bar Join Button",
    pastPaperRightHandMenu: "Past Paper Right-hand Menu",
    topicQuestionsRightHandMenu: "Topic Questions Right-hand Menu",
    accountMembershipPageJoin: "Account Membership Page Join Button",
    accountMembershipPageUpgrade: "Account Membership Page Upgrade Button",
    topicQuestionsPdfList: "PDF Overview Page Right-hand Menu",
    prCampaignLandingPage: "PR Campaign Landing Page",
    revisionNotesFooterCTA: "Revision Notes Footer CTA",
    dropdownMobileNavbarJoin: "Dropdown Navigation Bar Join Button",
    homepageHeroSection: "Home Page Hero Section",
  },
  engageJoinPageInteraction: {
    getInTouch: "Get In Touch",
    joinNow: "Join Now",
    trustpilot: "Trustpilot",
    commonQuestions: "Common Questions",
    resubscribe: "Resubscribe",
    tellParentSubmission: "Tell Your Parent Submission",
    tellParentExpansion: "Tell Your Parent Expansion",
    selectedPlan: "Selected plan",
  },
  landsCheckoutPageOrigin: {
    joinPage: "Join Page",
    paywall: "Paywall",
    signup: "Sign Up Page",
    pay: "Pay Page",
    onboarding: "Onboarding",
  },
  engageHomePageInteraction: {
    joinNow: "Join Now",
    heroSectionJoinNow: "Hero Section Join Now",
  },
  engageRevisionNoteInteraction: {
    author: "Author",
    breadcrumb: "Breadcrumb",
    downloadPdf: "Download PDF",
    nextTopic: "Next Topic",
    previousTopic: "Previous Topic",
    rightHandMenu: "Right-hand Menu",
    progressTrackingRead: "Progress tracking (read)",
    progressTrackingUnread: "Progress tracking (unread)",
    testYourselfBottom: "Test Yourself Bottom",
    testYourselfTop: "Test Yourself Top",
    testYourselfRightHandMenu: "Topic Questions Start Right-hand Menu",
    watchVideo: "Watch Video",
    topicQuestionsTab: "Topic Questions tab",
    viewedFooterCTA: "Footer CTA scrolled into view",
    clickedFooterCTA: "Footer CTA: Learn more and see pricing Button",
    flashcardsTop: "Clicked flashcards link - top",
    flashcardsCoachmark: "Clicked flashcards link - coachmark",
    flashcardsBottom: "Clicked flashcards link - bottom",
    altRevisionNoteDropdownOpen: "Opened exam board navigation",
    altRevisionNoteDropdownClick: "Clicked exam board navigation link",
  },
  engageTopicQuestionInteraction: {
    answer: "Submit Answer",
    removeAnswer: "Remove Answer",
    breadcrumb: "Breadcrumb",
    difficulty: "Difficulty",
    downloadPdf: "Download PDF",
    nextQuestion: "Next Question",
    pdfLink: "PDF Link",
    previousQuestion: "Previous Question",
    questionPart: "Question Part",
    quickAnswers: "Quick Answers",
    rightHandMenu: "Right-hand Menu",
    viewAnswer: "View Answer",
    viewAiMarking: "View AI Marking",
    watchSolution: "Watch Solution",
    keyConcept: "Key Concept",
    revisionNotesTab: "Revision Notes tab",
  },
  engagePastPaperInteraction: {
    downloadPdf: "Download PDF",
    pdfLink: "PDF Link",
    rightHandMenu: "Right-hand Menu",
  },
  engageOverviewPageInteraction: {
    commonQuestions: "Common Questions",
  },
  engagePdfOverviewPageInteraction: {
    downloadPdf: "Download PDF",
    viewAnswer: "View Answer",
    topicMenu: "Topic Menu",
  },
  engageRegistrationInteraction: {
    login: "Login",
    loginMenu: "Login Menu",
    checkout: "Continue to Payment",
    freeRegUser: "Continue",
    clickedGoogleSSOButton: "Clicked Google SSO button",
    clickedSignUpWithEmailButton: "Clicked sign up with email button",
    clickedContinueButton: "Clicked continue button",
    closedModal: "Closed modal",
  },
  viewSolutionInteraction: {
    quickAnswers: "Quick Answers",
    viewAnswer: "View Answer",
    watchSolution: "Watch Solution",
  },
  viewsJoinModuleTrigger: {
    assessScore: "Assess Score",
    downloadPdf: "Download PDF",
    keyConcepts: "Key Concepts",
    pdfLink: "PDF Link",
    quickAnswers: "Quick Answers",
    viewAnswer: "View Answer",
    watchSolution: "Watch Solution",
    watchVideo: "Watch Video",
  },
  watchVideoInteraction: {
    watchVideo: "Watch Video",
    watchSolution: "Watch Solution",
  },
  bannerType: {
    meteredPaywallCountdown: "Metered Paywall countdown",
    flashcards: "Flashcards",
    aiMarking: "AI Marking",
  },
  popupType: {
    meteredContentNotification: "Metered content notification",
  },
  paywallType: {
    meteredContent: "Metered content",
    premiumContent: "Premium content",
    premiumFeature: "Premium feature",
  },
  freewallType: {
    meteredContent: "Metered content",
  },
  limitingTrigger: {
    approachedMeteredLimit: "Approached metered limit",
    reachedMeteredLimit: "Reached metered limit",
    accessedPremiumContent: "Accessed premium content",
  },
  limitingInteraction: {
    joinNow: "Join Now",
    joinPage: "Join Page",
    logIn: "Log in",
    signUp: "Sign up",
    closedPopup: "Closed popup",
    closedPaywall: "Closed paywall",
  },
  campaignInteraction: {
    mainCTA: "Hero CTA",
    mainVideo: "Hero video",
    sectionNavigation: "Section navigation",
    joinPage: "Join Page",
    joinNow: "Join Now",
    article: "Article",
    expert: "Expert",
    slideCarousel: "Slide carousel",
    trustpilot: "Trustpilot",
    aboutUs: "About Us",
  },
  onboardingPage: {
    aboutYou: "About You",
    aboutYouUserType: "About You - User Type",
    aboutYouCountry: "About You - Country",
    aboutYouYearGroup: "About You - Year Group",
    marketingPreferences: "Marketing Preferences",
    yourExams: "Your Exams",
    yourSubjects: "Your Subjects",
    studyTools: "Study Tools",
  },
  onboardingPageAccessMethod: {
    membersPage: "Members page",
    redirectOnLogin: "Redirect on login",
    onboardingFlowPurchase: "Onboarding flow - Purchase",
    onboardingFlowFree: "Onboarding flow - Free user registration",
  },
  onboardingPageFinishingMethod: {
    completed: "Completed",
    skipped: "Skipped",
  },
  registrationPageAccessMethod: {
    freeUserRegistration: "Free user registration",
    purchase: "Purchase",
  },
  registrationMethod: {
    emailPassword: "Email/password",
  },
  submittedQuickFeedbackMethod: {
    thumbs: "Thumbs",
  },
  submittedQuickFeedbackType: {
    thumbsUp: "Thumbs up",
    thumbsDown: "Thumbs down",
  },
  submittedQuickFeedbackResourceType: {
    revisionNotePage: "Revision note page",
    revisionNoteVideo: "Revision note video",
    topicQuestionPage: "Topic question page",
    topicQuestionVideo: "Topic question video",
  },
  pageStyle: {
    light: "Switch to light mode",
    dark: "Switch to dark mode",
  },
  marketingOptIn: {
    optedIn: "Marketing opt in",
    optedOut: "Marketing opt out",
  },
  launchpadInteraction: {
    jumpBackInToLastPageSeen: "Jump back in to last page seen",
    updateSubjects: "Update subjects",
    viewSubject: "View subject",
    yourProfile: "Your profile",
  },
  registrationPageViewTrigger: {
    freewallLink: "Freewall Link",
    joinPageLink: "Join Page Link",
    softFreewallLink: "Soft Freewall Link",
  },
  viewedSyllabusModalTrigger: {
    learnMore: "Learn more",
    notYourSyllabus: "Not your syllabus?",
  },
  syllabusModalInteraction: {
    stayHere: "Stay Here",
    switch: "Switch",
  },
  checkoutPageTypes: {
    stripe: "Stripe",
    sme: "SME",
  },
  checkoutPageInteractionTypes: {
    clickedToPay: "Clicked to pay",
    openedPlanDetails: "Opened plan description drop down",
    closedPlanDetails: "Closed plan description drop down",
    clickedApplyPromotionCode: "Clicked to apply promotion code",
    removedPromotionCode: "Removed promotion code",
    openedPromotionCodeDropdown: "Opened promotion code drop down",
    closedPromotionCodeDropdown: "Closed promotion code drop down",
  },
  checkoutPagePaymentOutcomes: {
    successful: "Successful",
    unsuccessful: "Unsuccessful",
  },
  revisionNotePageView: {
    contentBehindPaywall: "Content behind paywall",
    contentBehindFreewall: "Content behind freewall",
  },
  buttonLocation: {
    navigationBar: "navigation bar",
    dropdown: "drop down",
  },
  viewedMemberDetailsPageProvider: {
    google: "Google",
  },
  registrationStage: {
    providerOptions: "Provider options",
    emailPasswordForm: "Email password form",
    userDetailsForm: "User details form",
  },
  loginPageInteraction: {
    clickedSignInWithGoogleButton: "Clicked sign in with Google button",
  },
  aiEssayMarkingPageInteraction: {
    submitAnswer: "Submit answer",
    selectTopic: "Select topic",
    selectQuestionNumber: "Select question number",
    toggleAoVisibility: "Toggle AO visibility",
  },
  aiMarkingModalInteraction: {
    submittedAnswer: "Submitted answer",
    closedModal: "Closed modal",
    expandedSkill: "Expanded skill",
    clickedToViewMarkingExplanation: "Clicked to view marking explanation",
  },
  aiMarkingExplanationModalInteraction: {
    clickedToViewPreviousStep: "Clicked to view previous step",
    clickedToViewNextStep: "Clicked to view next step",
    clickedToViewAiMarkingModal: "Clicked to view ai marking modal",
    closedModal: "Closed modal",
  },
  shownAiMarkingErrorStates: {
    harmfulContent: "Harmful content",
    serverError: "Server error",
  },
  purchaseWall: {
    selectedPlan: "Selected plan",
    clickedBack: "Clicked back",
    clickedSkip: "Clicked to skip",
    clickedContinue: "Clicked to continue to pay",
    faqs: "Common questions",
    clickedClose: "Clicked to close",
    clickedJoinNow: "Clicked join now",
    expandedTellParent: "Expanded tell your parent",
    submittedTellParent: "Submitted tell your parent",
    clickedReviews: "Clicked read reviews",
    clickedContact: "Clicked contact us",
    clickedStartFreeTrial: "Clicked start free trial",
    plansStep: "Plans",
    payStep: "Pay",
  },
  shownRegistrationFormTrigger: {
    signUpPage: "Sign up page",
    logInPage: "Log in page",
    joinPagePlanCta: "Join page join now plan CTA",
    homePageJoinNowCta: "Home page join now CTA",
    navBarCta: "Navigation Bar Join Button",
    dropdownMobileNavbarCta: "Dropdown Navigation Bar Join Button",
    rnPaywall: "Revision notes paywall",
    rnCountdownBannerLink: "Revision notes countdown banner link",
    tqCountdownBannerLink: "Topic questions countdown banner link",
    pdfOverviewJoinBanner: "PDF overview join banner",
    tqAiMarking: "Topic questions AI marking",
    tqAiMarkingNewAnswer: "Topic questions AI marking new answer",
    tqQuickAnswers: "Topic questions quick answers",
    tqDownloadPdf: "Topic questions download PDF",
    tqAssessScore: "Topic questions assess score",
    tqViewAnswer: "Topic questions view answer",
    tqWatchSolution: "Topic questions watch solution",
    tqKeyConcepts: "Topic questions key concepts",
    fcOverviewJoinNowCta: "Flashcards overview join now CTA",
    fcEndOfCollectionJoinNowCta: "Flashcard end of collection join now CTA",
    rnApproachedMeteredLimit: "Revision notes approached metered limit",
    rnReachedLimit: "Revision notes reached limit",
    rnDownloadPdf: "Revision notes download PDF",
    rnFooterCTA: "Revision notes footer CTA",
    pastPapersPdfLink: "Past papers PDF link",
    pastPapersJoinNowCta: "Past papers join now CTA",
    flashcardCollectionPopUp: "Flashcard collection pop-up",
    flashcardSaveProgressCta: "Flashcard collection sign up to save progress",
    footerCTARnOverivew: "Common footer CTA - revision notes overview",
    footerCTATqOverview: "Common footer CTA - topic questions overview",
    footerCTARnPage: "Common footer CTA - revision notes page",
    footerCTATqPage: "Common footer CTA - topic questions page",
    footerCTAAllSubjects: "Common footer CTA - all subjects page",
    footerCTASubjectOverview: "Common footer CTA - subject overview",
    footerCTALevelSubjectOverview: "Common footer CTA - level subject overview",
    footerCTAPastPapersBoardOverview:
      "Common footer CTA - past papers board overview",
    footerCTAPastPapersLevelOverview:
      "Common footer CTA - past papers level overview",
    footerCTASolutionBanksOverview:
      "Common footer CTA - solution banks overview",
    footerCTASolutionBanksCourseOverview:
      "Common footer CTA - solution banks level overview",
    footerCTAPastPapersPage: "Common footer CTA - past papers page",
  },
  blogInteraction: {
    clickedCategoryNavigationLink: "Clicked category navigation link",
    clickedBackButton: "Clicked back button",
    clickedAuthor: "Clicked author",
    clickedJoinNow: "Clicked join now",
    submittedEmailForm: "Submitted email form",
    clickedSocialShare: "Clicked social share button",
  },
  blogPageType: {
    homepage: "Home page",
    categoryPage: "Category page",
    articlePage: "Article page",
  },
  appliedPromotionCodeOutcomes: {
    successful: "Successful",
    unsuccessful: "Unsuccessful",
  },
  flashcardsExperienceFormat: {
    v1: "v1",
    v2: "v2",
  },
  flashcardReviewInteraction: {
    expandedTopic: "Expanded topic",
    expandedFlashcard: "Expanded flashcard",
    clickedToViewSpecPoint: "Clicked to view spec point",
    clickedToViewRevisionNote: "Clicked to view revision note",
    clickedToViewCard: "Clicked to view card",
    clickedBackButton: "Clicked back button",
  },
  flashcardOverviewInteraction: {
    clickedToReviewNotKnownCards: "Clicked to review not known cards",
    clickedToReviewNotSureCards: "Clicked to review not sure cards",
    clickedToReviewKnownCards: "Clicked to review known cards",
    clickedToReviewDoNotForgetCards: "Clicked to review do not forget cards",
    clickedToViewCollection: "Clicked to view flashcard collection",
    clickedPracticeNow: "Clicked practice now button",
    clickedToSignUp: "Clicked to sign up",
    clickedToLogIn: "Clicked to log in",
  },
  flashcardCollectionInteraction: {
    enteredFullScreenMode: "Entered full screen mode",
    exitedFullScreenMode: "Exited full screen mode",
    navigatedToPreviousCard: "Navigated to previous card",
    navigatedToNextCard: "Navigated to next card",
    flippedCardToFront: "Flipped card to front",
    flippedCardToBack: "Flipped card to back",
    shuffledCards: "Shuffled cards",
    unshuffledCards: "Unshuffled cards",
    hidSummary: "Hid cards in the collection",
    revealedSummary: "Revealed cards in the collection",
    clickedSummaryShowMore: "Clicked to view more cards in the collection",
    openedCourseNavigation: "Opened course navigation",
    closedCourseNavigation: "Closed course navigation",
    clickedCourseNavigationLink: "Clicked on course navigation link",
    clickedToViewRelatedResources: "Clicked to view related resources",
    clickedToViewRelatedRevisionNote:
      "Clicked to view related revision note - help section",
    clickedToViewRelatedSpecPoint:
      "Clicked to view related spec point - help section",
    clickedToViewCardRevisionNote:
      "Clicked to view related revision note - corner of card",
    clickedToViewEndOfCollectionRevisionNote:
      "Clicked to view related revision note - end of collection",
    clickedToViewRelatedTopicQuestions:
      "Clicked to view related topic questions",
    clickedToRestartCollection: "Clicked to restart the collection",
    navigatedToNextCollection: "Navigated to next collection",
    viewedEndOfCollectionSignUpModal:
      "Viewed sign up modal - end of collection",
    viewedEndOfCollectionLogInModal: "Viewed log in modal - end of collection",
    clickedToReviewYourCards: "Clicked to review your cards",
    clickedToReviewKnownCards: "Clicked to review known cards",
    clickedToReviewNotSureCards: "Clicked to review not sure cards",
    clickedToReviewNotKnownCards: "Clicked to review not known cards",
  },
  marketingPopupTrigger: {
    onboarding: "Onboarding",
  },
  marketingPopupInteraction: {
    optIn: "Opt in",
    closedPopup: "Closed popup",
  },
  dismissibleCtaInteraction: {
    dismissed: "Dismissed",
    clicked: "Clicked CTA Button",
  },
  advertType: {
    flashcards: "Flashcards",
  },
  advertInteraction: {
    clickedLearnMore: "Clicked learn more",
  },
  onboardingCarousel: {
    carouselType: "Onboarding introduction",
    revisionNotesSlide: "Revision Notes",
    topicQuestionsSlide: "Topic Questions",
    pastPapersSlide: "Past Papers",
    clickedNext: "Clicked next",
    clickedPrevious: "Clicked previous",
  },
} as const;
