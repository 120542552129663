var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"site-f7b69d2655b2ee0621031a787e34c00404d141c3"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/production";

import getConfig from "next/config";
import * as Sentry from "@sentry/nextjs";
import { beforeSend, ignoreClientErrors } from "@/lib/errors/filters";

const {
  publicRuntimeConfig: {
    sentryEnvironment,
    sentryDSN,
    sentryFrontendSampling,
    sentryTraceSampling,
  },
} = getConfig();

Sentry.init({
  dsn: sentryDSN,
  environment: sentryEnvironment,
  sampleRate: sentryFrontendSampling || 1,
  tracesSampleRate: sentryTraceSampling,
  ignoreErrors: ignoreClientErrors,
  beforeSend,
});
