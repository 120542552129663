import cn from "classnames";
import Link from "next/link";

import RocketIcon from "@material-design-icons/svg/outlined/rocket_launch.svg";
import SettingsIcon from "@material-design-icons/svg/outlined/settings.svg";
import EditIcon from "@material-design-icons/svg/outlined/edit.svg";
import LogoutIcon from "@material-design-icons/svg/outlined/logout.svg";

import * as tracking from "@/lib/tracking";
import { useUser } from "@/lib/hooks/user";
import ROUTES from "@/config/data/routes";
import { EVENT_TYPES, EVENT_VALUES } from "@/config/data/tracking";
import Icon from "@/components/Icon";
import LogoutLink from "@/components/LogoutLink";
import MissingCourseButton from "@/components/MissingCourseButton";

import LoginModal from "../LoginModal";
import JoinModal from "../JoinModal";
import styles from "./index.module.scss";

const LoggedInLinks = () => (
  <ul className={cn("list-unstyled d-lg-none", styles.list, styles.account)}>
    <li>
      <Link
        href={ROUTES.PAGES.MEMBERS}
        className="link-body-emphasis d-flex align-items-center"
      >
        <Icon svg={RocketIcon} className="me-1" size="S" />
        Launchpad
      </Link>
    </li>
    <li>
      <Link
        href={ROUTES.PAGES.ONBOARDING.ABOUT_YOU}
        className="link-body-emphasis d-flex align-items-center"
      >
        <Icon svg={EditIcon} className="me-1" size="S" />
        My learner profile
      </Link>
    </li>
    <li>
      <Link
        href={ROUTES.PAGES.ACCOUNT}
        className="link-body-emphasis d-flex align-items-center"
      >
        <Icon svg={SettingsIcon} className="me-1" size="S" />
        Account settings
      </Link>
    </li>
    <li className="d-flex align-items-center">
      <Icon svg={LogoutIcon} className="me-1" size="S" />
      <LogoutLink className="link-body-emphasis" />
    </li>
  </ul>
);

const LoggedOutLinks = () => (
  <ul className={cn("list-unstyled d-lg-none", styles.list)}>
    <li>
      <JoinModal
        className="w-100 mb-2"
        text="Join now for free"
        variant="primary"
        onClick={() =>
          tracking.event(EVENT_TYPES.clickedNavigationBarJoinButton, {
            buttonLocation: EVENT_VALUES.buttonLocation.dropdown,
          })
        }
        trigger={
          EVENT_VALUES.shownRegistrationFormTrigger.dropdownMobileNavbarCta
        }
      />
    </li>
    <li>
      <LoginModal buttonVariant="light" buttonClassName="w-100" />
    </li>
  </ul>
);

const AdditionalLinks = () => {
  const { user } = useUser();
  const isLoggedIn = !!user;

  return (
    <div className={styles.wrapper}>
      {isLoggedIn ? <LoggedInLinks /> : <LoggedOutLinks />}
      <ul className={cn("list-unstyled", styles.list, styles.core)}>
        <li>
          <Link href={ROUTES.PAGES.BLOG} className="link-body-emphasis">
            Learning hub
          </Link>
        </li>
        <li>
          <Link href={ROUTES.PAGES.ABOUT} className="link-body-emphasis">
            About us
          </Link>
        </li>
        <li>
          <Link href={ROUTES.PAGES.CONTACT} className="link-body-emphasis">
            Help and support
          </Link>
        </li>
      </ul>
      <MissingCourseButton variant="link-primary" className={styles.missing} />
    </div>
  );
};

export default AdditionalLinks;
