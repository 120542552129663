import {
  Experiment,
  ExperimentClient,
  Variant,
  Variants,
} from "@amplitude/experiment-js-client";
import * as Sentry from "@sentry/nextjs";
import { useEffect, useState } from "react";

import * as rudderstack from "src/lib/rudderstack";
import * as tracking from "src/lib/tracking";
import getConfig from "next/config";

const {
  publicRuntimeConfig: { experimentClientKey },
} = getConfig();

import { EXPERIMENTS, VariantKey } from "./variants";

interface UseExperimentClient {
  client: ExperimentClient | null;
  isReady: boolean;
  setIsReady: (args: boolean) => void;
}

let hasShownWarning = false;

const useExperimentClient = (): UseExperimentClient => {
  const [client, setClient] = useState<ExperimentClient>(null);
  const [isReady, setIsReady] = useState(false);

  if (!hasShownWarning && !experimentClientKey) {
    console.warn("[ExperimentClient] Missing client API key.");
    hasShownWarning = true;
  }

  if (typeof window === "undefined" || !experimentClientKey || client) {
    return { client, isReady, setIsReady };
  }

  const amplitudeExperimentClient = Experiment.initialize(experimentClientKey, {
    debug: process.env.NODE_ENV === "development",
    fetchOnStart: true,
    exposureTrackingProvider: {
      track: (exposure) => {
        tracking.event("$exposure", exposure);
      },
    },
  });

  setClient(amplitudeExperimentClient);
  return { client: amplitudeExperimentClient, isReady, setIsReady };
};

/**
 * Initialise the Experiment Client
 *
 * @remarks
 * We have to wait for rudderstack to initialise before we can initialise
 * Amplitude experiment. This is because the experiment platform is
 * dependent on the "userId" and "anonId" (deviceId).
 *
 * @param userId - The userId of the logged in user, which is used to re-fetch variant data when logging in/logging out
 */
export const useExperimentClientInit = (
  userId: string | undefined,
): boolean => {
  const { client, isReady, setIsReady } = useExperimentClient();
  const [, setVariants] = useState<Variants>({});
  const [deviceId, setDeviceId] = useState("");

  useEffect(() => {
    if (client) {
      rudderstack.ready(async () => {
        const userId = rudderstack.getUserId();
        const anonId = rudderstack.getAnonymousId() as string;

        try {
          await client.start({
            user_id: userId,
            device_id: anonId,
          });

          setIsReady(true);
          setDeviceId(anonId);
        } catch (error) {
          console.warn(error);
        }
      });
    }
  }, [client, setIsReady]);

  useEffect(() => {
    if (client && isReady) {
      const fetchExperimentUser = async () =>
        client.fetch({
          user_id: userId,
          device_id: deviceId,
        });

      fetchExperimentUser()
        .then(() => {
          setVariants(client.all());
        })
        .catch((e) => {
          Sentry.captureException(e);
        });
    }
  }, [client, deviceId, userId, isReady, setVariants]);

  return isReady;
};

/**
 * Returns the "variant" that the user is in for the given "experiment" or "flag" key
 *
 * @param key - The "experiment" or "flag" key
 * @returns A "Variant" object or null if it cannot find a variant for the provided key
 */
export const useVariant = (key: VariantKey): Variant | null => {
  const { client } = useExperimentClient();

  if (!client) {
    return null;
  }

  if (!key) {
    console.warn('[ExperimentClient] Missing "experiment" or "flag" key.');
    return null;
  }

  const variant = client.variant(key);

  return variant;
};

/**
 * Returns true/false if the user is in the variant for the given "experiment" or "flag" key
 *
 * @param key - The "experiment" or "flag" key
 * @param value - The variant value
 * @returns A boolean result of if the user is in the variant
 */
export const useIsInVariant = <
  K extends VariantKey,
  V extends keyof (typeof EXPERIMENTS)[K]["variations"],
>(
  key: K,
  value: V,
): boolean => {
  const variant = useVariant(key);

  if (!variant) {
    return false;
  }

  return variant.value === value;
};
