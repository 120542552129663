export const getUserId = () => {
  return window.rudderanalytics.getUserId();
};

export const getAnonymousId = () => {
  return window.rudderanalytics.getAnonymousId();
};

export const getSessionId = () => {
  return window.rudderanalytics.getSessionId();
};

export const reset = () => {
  return window.rudderanalytics.reset(true);
};

export const ready = (callback, attempt = 1) => {
  if (window.rudderanalytics?.ready) {
    return window.rudderanalytics.ready(callback);
  }

  if (attempt <= 6) {
    // Retry for 1.5 seconds.
    // 'Viewed other page' event fires after 2 seconds if no other events are fired.
    return setTimeout(() => {
      ready(callback, attempt + 1);
    }, 250);
  }
};
