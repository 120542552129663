import Image from "@/components/Image";
import { useDarkMode } from "@/lib/hooks/darkMode";

import styles from "./index.module.scss";

export const SIGN_UP_NOW_TITLE = "Sign up now. It’s free!";
export const ACE_YOUR_EXAMS_TITLE = "Ace your exams with ease";

const SignUpTitle = ({ heading }: { heading?: string }) => {
  const { isDarkMode } = useDarkMode();
  const iconVariant = isDarkMode ? "dark" : "light";

  return (
    <>
      <Image
        width={125}
        height={90}
        className={styles.image}
        src={`/images/illustrations/get-started-${iconVariant}.svg`}
        alt="An illustration of students holding their exam results"
      />
      <span className={styles.title}>{heading || ACE_YOUR_EXAMS_TITLE}</span>
    </>
  );
};

export default SignUpTitle;
