import PropTypes from "prop-types";
import cn from "classnames";
import LightModeIcon from "@material-design-icons/svg/filled/light_mode.svg";
import DarkModeIcon from "@material-design-icons/svg/filled/dark_mode.svg";
import { useGlobalContext } from "src/context/global";
import ButtonIcon from "src/components/ButtonIcon";
import { EVENT_VALUES, EVENT_TYPES } from "src/config/data/tracking";
import * as tracking from "src/lib/tracking";
import Icon from "src/components/Icon";

import styles from "./index.module.scss";

const THEMES = {
  LIGHT: "light",
  DARK: "dark",
};

const ThemeMenu = ({ className }) => {
  const { state, actions } = useGlobalContext();

  const unselectedTheme =
    state.theme === THEMES.LIGHT ? THEMES.DARK : THEMES.LIGHT;
  const ariaLabel = `Switch to ${unselectedTheme} mode`;

  const onClick = () => {
    tracking.event(EVENT_TYPES.changedPageStyle, {
      styleChange: EVENT_VALUES.pageStyle[unselectedTheme],
    });
    actions.setTheme(unselectedTheme);
    tracking.identify();
  };

  return (
    <ButtonIcon
      ariaLabel={ariaLabel}
      className={cn(className, styles.button)}
      icon={
        <>
          <Icon
            svg={DarkModeIcon}
            className={cn(styles.icon, styles.darkModeIcon)}
          />
          <Icon
            svg={LightModeIcon}
            className={cn(styles.icon, styles.lightModeIcon)}
          />
        </>
      }
      onClick={onClick}
      role="switch"
      type="button"
      variant="floating"
    />
  );
};

export default ThemeMenu;

ThemeMenu.propTypes = {
  className: PropTypes.string,
};
