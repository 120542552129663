/* eslint-disable @next/next/no-before-interactive-script-outside-document */

import Script from "next/script";
import { KEYS } from "src/config/data/localStorage";

const ThemeScript = () => {
  return (
    <Script
      id="theme"
      strategy="beforeInteractive"
      dangerouslySetInnerHTML={{
        __html: `
          (function () {
            try {
              const lsTheme = localStorage.getItem('${KEYS.theme}')
              const supportDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches === true
              const theme = lsTheme || (supportDarkMode ? 'dark' : 'light')

              document.querySelector('html').dataset.bsTheme = theme
            } catch {}
          })();
        `,
      }}
    />
  );
};

export default ThemeScript;
