import { getNavigationLevels } from "src/lib/helpers";
import { SOLUTION_BANKS_CONFIG } from "@/pages/solution-banks/config";
import { PAST_PAPERS_PAGE_CONFIG } from "@/config/data/past-papers-overview";

const SAILS_API = {
  LOGIN: "/api/v0/user/authenticate/",
  USER: "/api/v0/user/",
  SIGNOUT: "/api/v0/signout/",
  PING: "/api/v0/user/ping/",
  ABOUT: "/api/v0/about/",
  CONTACT: "/api/v0/contact/",
  NAV: "/api/v0/nav/",
  AUTHORS: "/api/v0/authors/",
  CHECKOUT_SESSIONS: "/api/v0/checkout-sessions/",
  SEND_BENEFITS: "/api/v0/send-benefits/",
  RESEND_VALIDATION: "/api/v0/user/resendValidationEmail/",
  MEMBERSHIP_DETAILS: "/api/v0/membership-details/",
  USER_PASSWORD_RESET_REQUEST: "/api/v0/user/passwordReset/",
  USER_PASSWORD: "/api/v0/user/password/",
  USER_NOTE_STATUS: "/api/v0/user-note-status/",
  REVISION_NOTE_READ_MARKERS: "/api/v0/user-note-read-markers/",
  COURSES: "/api/v0/courses/",
  COUNTRIES_YEAR_GROUPS: "/api/v0/countries-year-groups/",
  BILLING_DETAILS: "/api/v0/user/billing/",
};

const BACKEND_API = {
  ACCOUNTS: {
    COURSE_SUBSCRIPTIONS: "/accounts/v1/course-subscriptions/",
    OPERATIONS: "/accounts/v1/operations/",
  },
  BILLING_ANALYTICS_OVERVIEWS: "/billing/v2/analytics-overviews/",
  BILLING_UPDATE_FLOWS: "/api/billing/v2/billing-update-flows/",
  CHECKOUT_FLOWS: "/api/billing/v2/checkout-flows/",
  COMMUNICATION_PREFERENCES: "/accounts/v1/user-communication-preferences/",
  COURSE_SUBSCRIPTION_OPTIONS: "/accounts/v1/course-subscription-options/",
  PROMOTION_CODES: "/api/billing/v2/promotion-codes/",
  PROGRESS: {
    REVISION_NOTE_READ_MARKERS: "/progress/v1/revision-note-read-markers/",
    FLASHCARD_REVIEWS: "/progress/v1/flashcard-reviews/",
    QUESTION_PART_RESPONSES: "/api/progress/v1/question-part-responses/",
  },
  SUBSCRIPTIONS: "/api/billing/v2/subscriptions/",
  USER_SETTINGS: "/accounts/v1/users/self/",
  MARKING: "/api/marking/v1/",
  AUTHOR_BIO_PAGES: "/content/v1/authors/",
};

const NEXT_API = {
  SPEC_POINT_CONTENT_ITEMS: "/api/site/content/spec-point-content-items/",
};

const ROUTES = {
  PAGES: {
    HOME: "/",
    CHECKOUT_SUCCESS: "/checkout/success/",
    MEMBERS: "/members/",
    MEMBER_DETAILS: "/members/details/",
    ACCOUNT: "/members/account/",
    MEMBERSHIP: "/members/account/membership/",
    NOTIFICATION_PREFERENCES: "/members/account/notification-preferences/",
    LOGOUT: "/logout/",
    LOGIN: "/login/",
    JOIN: "/join/",
    FORGOT_PASSWORD: "/forgot-password/",
    PAST_PAPERS: "/past-papers/",
    CONTACT: "/contact/",
    ABOUT: "/about/",
    JOBS: "/jobs/",
    TERMS: "/terms/",
    PRIVACY: "/privacy/",
    NEXUS: "/nexus/",
    BLOG: "/learning-hub/",
    SOLUTION_BANKS: "/solution-banks/",
    ALL_SUBJECTS: "/subjects/",
    MARKETING: {
      PARENTS: "/parents/",
      MASTER: "/master-what-matters/",
    },
    ONBOARDING: {
      HOME: "/welcome/",
      ABOUT_YOU: "/welcome/about-you/",
      ABOUT_YOU_COUNTRY: "/welcome/about-you/?page=country",
      ABOUT_YOU_YEAR: "/welcome/about-you/?page=year",
      MARKETING_PREFERENCES: "/welcome/marketing-preferences/",
      YOUR_EXAMS: "/welcome/your-exams/",
      YOUR_SUBJECTS: "/welcome/your-subjects/",
      STUDY_TOOLS: "/welcome/study-tools/",
      START: "/welcome/start",
      YOUR_PLAN: "/welcome/your-plan/",
      PAY: "/welcome/pay/",
    },
    SIGNUP: "/signup/",
    ARCHIVE: "/archive/",
    PAY: "/pay/",
    STRIPE_CHECKOUT: "/checkout/",
    SCHOLARSHIP: "/scholarship/",
    CONTENT_QUALITY: "/about/content-integrity/content-creation/",
  },
  API: {
    ...SAILS_API,
    ...BACKEND_API,
    ...NEXT_API,
  },
};

export const SITEMAP_ROUTES = [
  { loc: ROUTES.PAGES.ABOUT, lastmod: new Date("2023-08-01").toISOString() },
  { loc: ROUTES.PAGES.CONTACT, lastmod: new Date("2023-08-01").toISOString() },
  { loc: ROUTES.PAGES.JOBS, lastmod: new Date("2023-08-01").toISOString() },
  { loc: ROUTES.PAGES.JOIN, lastmod: new Date("2023-08-01").toISOString() },
  { loc: ROUTES.PAGES.LOGIN, lastmod: new Date("2023-08-01").toISOString() },
  { loc: ROUTES.PAGES.PRIVACY, lastmod: new Date("2023-08-01").toISOString() },
  { loc: ROUTES.PAGES.TERMS, lastmod: new Date("2023-08-01").toISOString() },
  {
    loc: ROUTES.PAGES.ALL_SUBJECTS,
    lastmod: new Date("2024-05-08").toISOString(),
  },
  {
    loc: ROUTES.PAGES.PAST_PAPERS,
    lastmod: new Date("2023-12-14").toISOString(),
  },
  ...getNavigationLevels().map((level) => ({
    loc: `${level.url}past-papers/`,
    lastmod: new Date("2023-12-14").toISOString(),
  })),
  ...PAST_PAPERS_PAGE_CONFIG.boards.map((board) => ({
    loc: `/past-papers${board.url}`,
    lastmod: new Date("2024-05-02").toISOString(),
  })),
  {
    loc: ROUTES.PAGES.SOLUTION_BANKS,
    lastmod: new Date("2024-04-11").toISOString(),
  },
  ...Object.keys(SOLUTION_BANKS_CONFIG.courses).map((course) => ({
    loc: `/${course}/solution-banks/`,
    lastmod: new Date("2024-04-11").toISOString(),
  })),
];

export default ROUTES;

export { NEXT_API };
