import type { Navigation } from ".";

const CONFIG: Navigation = {
  tops: [
    {
      url: "/gcse/",
      lefts: [],
      title: "GCSE",
    },
    {
      url: "/igcse/",
      lefts: [
        {
          url: "/igcse/maths/",
          title: "Maths",
          rights: [
            {
              url: "/igcse/maths/edexcel/",
              links: [
                {
                  url: "/igcse/maths/edexcel/19/topic-questions/",
                  title: "Topic questions",
                },
                {
                  url: "/igcse/maths/edexcel/19/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/igcse/maths/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
                {
                  url: "/igcse/maths/edexcel/19/pages/practice-papers/",
                  title: "Practice Papers",
                },
                {
                  url: "/igcse/maths/edexcel/19/pages/topic-questions/",
                  title: "Topic Questions (old)",
                },
                {
                  url: "/igcse/maths/edexcel/-/past-paper-questions/",
                  title: "Past Paper Questions",
                },
                {
                  url: "/igcse/maths/edexcel/19/practice-paper-questions/",
                  title: "Practice Paper Questions",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/igcse/maths/aqa/",
              links: [
                {
                  url: "/igcse/maths/aqa/19/topic-questions/",
                  title: "Topic questions",
                },
                {
                  url: "/igcse/maths/aqa/19/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/igcse/maths/aqa/19/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
            },
            {
              url: "/igcse/maths/ocr",
              links: [
                {
                  url: "/igcse/maths/ocr/19/topic-questions/",
                  title: "Topic questions",
                },
                {
                  url: "/igcse/maths/ocr/19/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/igcse/maths/ocr/19/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
            },
          ],
        },
        {
          url: "/igcse/biology/",
          title: "Biology",
          rights: [
            {
              url: "/igcse/biology_combined-science/aqa/",
              links: [
                {
                  url: "/igcse/biology_combined-science/aqa/19/revision-notes/",
                  title: "Revision Notes",
                },
              ],
              title: "AQA Combined: Biology",
            },
          ],
        },
        {
          url: "/igcse/chemistry/",
          title: "Chemistry",
          rights: [],
        },
        {
          url: "/igcse/physics/",
          title: "Physics",
          rights: [
            {
              url: "/igcse/physics/aqa/",
              links: [
                {
                  url: "/igcse/physics/aqa/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA Physics",
            },
          ],
        },
        {
          url: "",
          title: "Combined Science",
          rights: [],
        },
        {
          url: "/igcse/english-language/",
          title: "English Language",
          rights: [],
        },
      ],
      title: "IGCSE",
    },
    {
      url: "/as/",
      lefts: [],
      title: "AS",
    },
    {
      url: "/a-level/",
      lefts: [
        {
          url: "/a-level/maths/",
          title: "Maths",
          rights: [
            {
              url: "/a-level/maths/edexcel/",
              links: [
                {
                  url: "/a-level/maths/edexcel/19/topic-questions/",
                  title: "Topic questions",
                },
                {
                  url: "/a-level/maths/edexcel/19/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/a-level/maths/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
                {
                  url: "/a-level/maths/edexcel/19/pages/practice-papers/",
                  title: "Practice Papers",
                },
                {
                  url: "/a-level/maths/edexcel/19/pages/topic-questions/",
                  title: "Topic Questions (old)",
                },
                {
                  url: "/a-level/maths/edexcel/-/past-paper-questions/",
                  title: "Past Paper Questions",
                },
                {
                  url: "/a-level/maths/edexcel/19/practice-paper-questions/",
                  title: "Practice Paper Questions",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/a-level/maths/aqa/",
              links: [
                {
                  url: "/a-level/maths/aqa/19/topic-questions/",
                  title: "Topic questions",
                },
                {
                  url: "/a-level/maths/aqa/19/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/a-level/maths/aqa/19/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
            },
          ],
        },
      ],
      title: "A Level",
    },
    {
      url: "/o-level/",
      lefts: [],
      title: "O Level",
    },
    {
      url: "/pre-u/",
      lefts: [],
      title: "Pre U",
    },
    {
      url: "/ib/",
      lefts: [],
      title: "IB",
    },
  ],
};

export default CONFIG;
