/**
 * Global styles must be imported first so that they can be overwritten easily,
 * else problems arise with .module.scss styles not taking presedence.
 */
import "src/styles/global/index.scss";
import { useRouter } from "next/router";
import PageWrapper from "src/components/PageWrapper";
import { DefaultLayout } from "src/components/layouts";
import Links from "src/components/head/Links";
import Metadata from "src/components/head/Metadata";
import Font from "src/components/head/Font";

import getConfig from "next/config";
import { AppProps } from "next/app";
import { User } from "@/config/types/user";

const {
  publicRuntimeConfig: { baseUrl },
} = getConfig();

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

export type GlobalPageProps = {
  customCanonical?: string;
  metaTitle?: string;
  metaDescription?: string;
  metaImage?: string;
  title?: string;
  noIndex?: boolean;
  layout?: "pdf";
  user?: User;
};

export default function NextApp({
  Component,
  pageProps,
}: AppProps<GlobalPageProps>) {
  const router = useRouter();
  const path = router.asPath.split("?")[0];
  const canonical = baseUrl + (pageProps?.customCanonical || path);
  const getLayout =
    // @ts-expect-error don't know how to type Component to have an optional getLayout
    Component.getLayout || ((page) => <DefaultLayout>{page}</DefaultLayout>);

  return (
    <>
      {/* Metadata, Links and Font components are used across all pages, including PDF pages */}
      <Metadata
        canonical={canonical}
        metaTitle={pageProps?.metaTitle}
        metaDescription={pageProps?.metaDescription}
        metaImage={pageProps?.metaImage}
        title={pageProps?.title}
        noIndex={pageProps?.noIndex}
      />
      <Links canonical={canonical} />
      <Font />

      <ConditionalWrapper
        condition={pageProps.layout !== "pdf"}
        wrapper={(children) => (
          <PageWrapper pageProps={pageProps}>{children}</PageWrapper>
        )}
      >
        {getLayout(<Component {...pageProps} />)}
      </ConditionalWrapper>
    </>
  );
}
