import { PlanProps } from "@/config/data/plans";
import { EVENT_TYPES, EVENT_VALUES } from "@/config/data/tracking";
import { Currency } from "@/config/types/billing";
import {
  RegistrationInteraction,
  RegistrationMethod,
  RegistrationOutcome,
  RegistrationStage,
} from "@/config/types/tracking";
import * as tracking from "src/lib/tracking";

interface ViewedRegistrationPageProps {
  registrationStage: RegistrationStage;
  pageReferralMethod?: string;
  plan?: PlanProps;
}

interface InteractedWithRegistrationProps {
  interaction: RegistrationInteraction;
  outcome?: RegistrationOutcome;
  plan?: PlanProps;
  pageReferralMethod?: string;
}

interface CompletedRegistrationProps {
  registrationMethod: RegistrationMethod;
  plan?: PlanProps;
}

interface DirectedToCheckoutPageProps {
  plan: PlanProps;
  currency: Currency;
}

export const trackViewedRegistrationPage = ({
  registrationStage,
  pageReferralMethod,
  plan,
}: ViewedRegistrationPageProps) => {
  tracking.event(EVENT_TYPES.viewedRegistrationPage, {
    registrationStage,
    registrationPageAccessMethod: plan?.membershipType
      ? EVENT_VALUES.registrationPageAccessMethod.purchase
      : EVENT_VALUES.registrationPageAccessMethod.freeUserRegistration,
    ...(pageReferralMethod && { pageReferralMethod }),
  });
};

export const trackInteractedWithRegistrationPage = ({
  interaction,
  outcome,
  plan,
  pageReferralMethod,
}: InteractedWithRegistrationProps) => {
  tracking.event(EVENT_TYPES.interactedWithRegistrationPage, {
    interaction,
    registrationPageAccessMethod: plan?.membershipType
      ? EVENT_VALUES.registrationPageAccessMethod.purchase
      : EVENT_VALUES.registrationPageAccessMethod.freeUserRegistration,
    ...(outcome && { outcome }),
    pageReferralMethod,
  });
};

export const trackCompletedRegistration = ({
  registrationMethod,
  plan,
}: CompletedRegistrationProps) => {
  tracking.event(EVENT_TYPES.registrationCompleted, {
    registrationMethod,
    registrationPageAccessMethod: plan?.membershipType
      ? EVENT_VALUES.registrationPageAccessMethod.purchase
      : EVENT_VALUES.registrationPageAccessMethod.freeUserRegistration,
  });
};

export const trackDirectedToCheckoutPage = ({
  plan,
  currency,
}: DirectedToCheckoutPageProps) => {
  tracking.event(EVENT_TYPES.directedToCheckoutPage, {
    origin: EVENT_VALUES.landsCheckoutPageOrigin.signup,
    membershipType: plan.membershipType ?? undefined,
    checkoutPageType: EVENT_VALUES.checkoutPageTypes.sme,
    planId: plan.id,
    currency,
  });
};
