import Link from "next/link";
import ROUTES from "src/config/data/routes";

const LoginLink = (props) => (
  <Link {...props} href={ROUTES.PAGES.LOGIN}>
    Log in
  </Link>
);

export default LoginLink;
