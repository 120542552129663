import { Fragment, useEffect, useRef, useState } from "react";
import Link from "next/link";
import ChevronRight from "@material-design-icons/svg/filled/chevron_right.svg";
import ArrowBackIcon from "@material-design-icons/svg/filled/arrow_back.svg";
import ExpandMoreIcon from "@material-design-icons/svg/filled/expand_more.svg";
import CloseIcon from "@material-design-icons/svg/filled/close.svg";
import ButtonIcon from "@/components/ButtonIcon";
import Icon from "@/components/Icon";
import { useRouter } from "next/router";
import sortBy from "lodash/sortBy";

import AdditionalLinks from "../AdditionalLinks";
import { useMediaQuery } from "@/lib/hooks/useMediaQuery";
import styles from "./index.module.scss";
import cn from "classnames";
import navigation from "@/config/data/navigation";
import isEqual from "lodash/isEqual";
import groupBy from "lodash/groupBy";
import useIsScrollable from "@/lib/hooks/useIsScrollable";
import useHideThemeToggle from "@/lib/hooks/useHideThemeToggle";
import Theme from "@/components/Theme";
import { trackInteraction } from "../..";
import { EVENT_VALUES } from "@/config/data/tracking";

type Item = {
  title: string;
  url: string;
  context?: {
    hasSingleBoard: boolean;
    subject: string;
    subtitle?: string;
    module?: { color?: string; title: string };
    resources: { title: string; url: string }[];
  };
  nested?: {
    template: string;
    expand: boolean;
    items: Item[];
    alphabetize?: boolean;
  };
};

const ITEMS = navigation.tops.map((top) => ({
  title: top.title,
  url: top.url,
  nested: {
    template: top.title === "Other" ? "OTHER" : "SUBJECTS",
    expand: false,
    items: top.lefts.map((left) => ({
      title: left.title,
      url: left.url,
      nested: {
        alphabetize: left.alphabetize,
        template: "BOARDS", // not used
        expand: true,
        items: left.rights.map((right) => ({
          title: right.title,
          url: right.url,
          context: {
            hasSingleBoard: top.hasSingleBoard,
            resources: right.links,
            subject: right.subjectAlias || left.title,
            subtitle: right.subtitle,
            module: right.module,
          },
        })),
      },
    })),
  },
}));

export const MenuOverlay = ({
  show,
  onClick,
  children,
}: {
  show: boolean;
  onClick: () => void;
  children: React.ReactNode;
}) => {
  useEffect(() => {
    document.body.style.overflow = show ? "hidden" : "initial";

    return () => {
      document.body.style.overflow = "initial";
    };
  }, [show]);
  return (
    <div className={cn({ [styles.menuOverlay]: show })} onClick={onClick}>
      {children}
    </div>
  );
};

export const MenuWrapper = ({
  children,
  showMenu,
}: {
  children: React.ReactNode;
  showMenu: boolean;
}) => {
  return (
    <div
      id="navigation-dropdown"
      className={cn("container h-100 d-flex flex-column", styles.container, {
        [styles.hidden]: !showMenu,
      })}
    >
      {children}
    </div>
  );
};

const ExpandedListItems = ({
  item,
  history,
}: {
  item: Item;
  history: Item[];
}) => {
  const groupedItems = groupBy(item.nested.items, (item) => item.title);
  const grid = Object.values(groupedItems).every((items) => items.length === 1);
  const hasSingleBoard = item.nested.items[0]?.context.hasSingleBoard;

  const trackCourseClick = (titles: string[]) => {
    trackInteraction(EVENT_VALUES.navigationBarInteraction.clicked, [
      ...history.map((item) => item.title),
      ...titles,
    ]);
  };

  return (
    <div className={cn(styles.expand, "col-lg-9 col-12")}>
      <ul className="list-unstyled row">
        {Object.entries(groupedItems).map(([title, items], index) => (
          <li
            key={index}
            className={cn(
              styles.board,
              { [styles.boardGrid]: grid },
              grid ? "col-lg-6" : "col-12",
            )}
          >
            {!hasSingleBoard && (
              <span className="h4 mb-3 d-block ms-2">{title}</span>
            )}
            <ul className="row list-unstyled gy-3">
              {sortBy(
                items,
                item.nested?.alphabetize ? "context.subject" : undefined,
              ).map((item, index) => (
                <li
                  key={index}
                  className={grid ? "col-12" : "col-md-6 col-lg-4"}
                >
                  <Link
                    href={item.url}
                    onClick={() => trackCourseClick([item.title])}
                    className={cn("focus-ring", styles.courseLink)}
                  >
                    <span>
                      <span className={styles.courseTitle}>
                        {item.context.subject}
                      </span>
                      <span className={styles.courseSubtitle}>
                        {item.context.subtitle}
                      </span>
                    </span>
                    {item.context.module && (
                      <span
                        className={cn(
                          styles.pill,
                          styles[item.context.module.color],
                        )}
                      >
                        {item.context.module.title}
                      </span>
                    )}
                  </Link>
                  <ul className="list-unstyled">
                    {item.context?.resources.map((resource) => (
                      <li key={resource.title}>
                        <Link
                          href={resource.url}
                          className={cn("focus-ring", styles.resourceLink)}
                          onClick={() =>
                            trackCourseClick([item.title, resource.title])
                          }
                        >
                          {resource.title}
                          <Icon
                            svg={ChevronRight}
                            className={styles.linkChevron}
                          />
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
};

const ListItems = ({
  item,
  history,
  onClick,
  parents = [],
}: {
  item: Item;
  history: Item[];
  onClick: (items: Item[]) => void;
  parents?: Item[];
}) => {
  return (
    <>
      {item.nested.template === "SUBJECTS" && (
        <Link href={item.url} className={styles.allSubjectsLink}>
          All subjects
        </Link>
      )}
      {item.nested.template === "OTHER" && (
        <span className="h4 ms-2 mt-2 d-block">Other</span>
      )}
      <ul
        className={cn("list-unstyled", {
          [styles.expand]: item.nested.expand,
          "col-lg-9 col-12": item.nested.expand,
        })}
      >
        {item.nested.items.map((leftItem, index) => (
          <MenuItem
            history={history}
            key={index}
            item={leftItem}
            onClick={onClick}
            parents={[...parents, item]}
          />
        ))}
      </ul>
    </>
  );
};

const MenuItem = ({
  history,
  item,
  onClick,
  parents = [],
}: {
  history: Item[];
  item: Item;
  onClick: (items: Item[]) => void;
  parents?: Item[];
}) => {
  // an item is "current" if it and its parents are in the history
  // parents still need to be "current" so we know to display nested items
  const isCurrent = [...parents, item].every((item, index) =>
    isEqual(item, history[index]),
  );
  const isShowingNestedItems =
    history.length > parents.length + (item.nested?.expand ? 1 : 0);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onClick(isCurrent ? parents : [...parents, item]);
    }
  };

  return (
    <li className={styles.innerMenuItem}>
      <a
        href={item.url || "#"}
        onClick={(e) => {
          if (!item.nested) return;
          e.preventDefault();
          onClick([...parents, item]);
        }}
        onKeyDown={handleKeyDown}
        className={cn(styles.innerMenuItemLink, {
          [styles.isCurrent]: isCurrent,
          [styles.hidden]: isShowingNestedItems,
        })}
      >
        {item.title}
        <Icon svg={ChevronRight} className={styles.linkChevron} />
      </a>
      {item.nested && (
        <div className={cn({ [styles.hidden]: !isCurrent })}>
          {item.nested.expand ? (
            <ExpandedListItems item={item} history={history} />
          ) : (
            <ListItems
              item={item}
              history={history}
              onClick={onClick}
              parents={parents}
            />
          )}
        </div>
      )}
    </li>
  );
};

export const MainMenu = ({
  showMainMenu,
  handleCloseMenu,
}: {
  showMainMenu: boolean;
  handleCloseMenu: () => void;
}) => {
  const scrollableRef = useRef<HTMLDivElement>(null);
  const [history, setHistory] = useState<Item[]>([]);
  const currentItem = history[history.length - 1];
  const router = useRouter();
  const { isScrollable, isAtBottom, handleScroll } =
    useIsScrollable(scrollableRef);
  const hideThemeToggle = useHideThemeToggle();

  const isDesktop = useMediaQuery("(min-width: 992px)", {
    initializeWithValue: false,
  });

  useEffect(() => {
    /**
     * Ensure nav is closed as user navigates around site.
     */

    const handleRouteChangeStart = () => handleCloseMenu();

    router.events.on("routeChangeStart", handleRouteChangeStart);

    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
    };
  }, []);

  useEffect(() => {
    // Call handleScroll manually to trigger rerender when currentItem changes
    handleScroll();
  }, [currentItem]);

  useEffect(() => {
    if (!showMainMenu) {
      setHistory([]);
    }
  }, [showMainMenu]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (["Escape", "Esc"].includes(event.key)) handleCloseMenu();
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const isOnFirstLevel = history.length === 0;

  const handleItemClick = (items: Item[]) => {
    trackInteraction(
      EVENT_VALUES.navigationBarInteraction.clicked,
      items.map((item) => item.title),
    );
    setHistory(items);
  };

  const handleBackClick = () => {
    const totalItemsToRemove = isDesktop && currentItem.nested.expand ? 2 : 1;
    const newHistory = [...history].slice(0, -totalItemsToRemove);

    setHistory(newHistory);
    trackInteraction(EVENT_VALUES.navigationBarInteraction.clickedBackButton, [
      ...newHistory.map((item) => item.title),
    ]);
  };

  return (
    <>
      <MenuWrapper showMenu={showMainMenu}>
        {history.length > 0 && (
          <div
            className={cn("row", styles.topRow)}
            onClick={(e) => e.stopPropagation()}
          >
            <div
              className={cn(
                currentItem.nested.expand ? "col-12" : "col-lg-3 col-md-6",
                styles.topSection,
              )}
            >
              <ButtonIcon
                onClick={handleBackClick}
                size="S"
                ariaLabel="Back"
                variant="neutral"
                icon={<Icon svg={ArrowBackIcon} size="S" />}
                className={cn("me-3", styles.menuNavigationButton)}
              />
              <div className={styles.breadcrumbs}>
                {history.map((item, index) => (
                  <Fragment key={`breadcrumb=${index}`}>
                    {item.url ? (
                      <Link href={item.url}>{item.title}</Link>
                    ) : (
                      <span>{item.title}</span>
                    )}
                    {index < history.length - 1 && (
                      <Icon
                        svg={ChevronRight}
                        size="S"
                        className={styles.breadcrumbChevron}
                      />
                    )}
                  </Fragment>
                ))}
              </div>

              {currentItem.nested.expand && (
                <ButtonIcon
                  onClick={handleCloseMenu}
                  size="S"
                  ariaLabel="Close site navigation"
                  variant="neutral"
                  icon={<Icon svg={CloseIcon} size="S" />}
                  className={styles.closeButton}
                />
              )}
            </div>
          </div>
        )}
        <div className={cn("row", styles.row)}>
          <div
            ref={scrollableRef}
            className={cn("col-lg-3 col-md-6", styles.sideMenu)}
            onClick={(e) => e.stopPropagation()}
          >
            <ul className={styles.menuList}>
              {ITEMS.map((item, index) => (
                <MenuItem
                  history={history}
                  key={index}
                  item={item}
                  onClick={handleItemClick}
                />
              ))}
            </ul>
            {isOnFirstLevel && <AdditionalLinks />}
            {!isOnFirstLevel && (
              <div
                className={cn(
                  "col-12",
                  "col-lg-3 col-md-6",
                  styles.scrollNotice,
                  {
                    [styles.visible]: isScrollable && !isAtBottom,
                  },
                )}
              >
                Scroll for more
                <Icon
                  svg={ExpandMoreIcon}
                  size="S"
                  className={styles.expandChevron}
                />
              </div>
            )}
            {!hideThemeToggle && !isDesktop && (
              <Theme className={styles.themeButton} />
            )}
          </div>
        </div>
      </MenuWrapper>
    </>
  );
};

type DesktopNavV2Props = {
  showMainMenu: boolean;
  handleCloseMenu: () => void;
};

const DesktopNavV2 = ({ showMainMenu, handleCloseMenu }: DesktopNavV2Props) => {
  return (
    <MenuOverlay show={showMainMenu} onClick={handleCloseMenu}>
      <MainMenu showMainMenu={showMainMenu} handleCloseMenu={handleCloseMenu} />
    </MenuOverlay>
  );
};

export default DesktopNavV2;
