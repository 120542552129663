/* eslint-disable @next/next/no-before-interactive-script-outside-document */

import Script from "next/script";
import getConfig from "next/config";

const {
  publicRuntimeConfig: { appEnv },
} = getConfig();

const InitGlobals = () => {
  return (
    <Script
      id="globals"
      strategy="beforeInteractive"
      dangerouslySetInnerHTML={{
        __html: `
                    window.Sme = {
											track: { pageTriggered: false, eventsTriggered: 0 }, 
											data: { user: null, page: null }, environment: "${appEnv}"
										};
                `,
      }}
    />
  );
};

export default InitGlobals;
