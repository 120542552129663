import { useRouter } from "next/router";

const EXCLUDED_PAGES = ["/contact", "/pay"];

function useHideThemeToggle() {
  const router = useRouter();

  return EXCLUDED_PAGES.includes(router.pathname);
}

export default useHideThemeToggle;
