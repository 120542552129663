import cn from "classnames";
import {
  forwardRef,
  type ElementRef,
  type ElementType,
  type ForwardedRef,
  type PropsWithChildren,
} from "react";
import styles from "./index.module.scss";

type ValidTags = keyof JSX.IntrinsicElements;

export type WrapperProps<T extends ValidTags> = PropsWithChildren<
  { tag?: T } & JSX.IntrinsicElements[T]
>;

// type DyntamicComponentProps<T extends ValidTags> = T extends keyof HTMLElementTagNameMap // Used if the tag is part of HTML
// 	? React.HTMLProps<HTMLElementTagNameMap[T]> // if it's not HTML, let's check if it's an SVG instead
// 	: T extends keyof SVGElementTagNameMap
// 	? React.SVGProps<SVGElementTagNameMap[T]> // If it's not an SVG, we don't really have any other options
// 	: {}

/**

---

### Usage

```js
import Wrapper from 'src/components/Wrapper'
```

### Notes

A `<Wrapper>` is a plain div, but it will remove the top margin from its first child and the bottom margin from its last child.
Inside a `<Wrapper>` we can use components that have a top or bottom margin by default without worrying about explicitly removing it.

---

 */
const Wrapper = <T extends ValidTags>(
  { children, className, tag = "div" as T, ...rest }: WrapperProps<T>,
  ref: ForwardedRef<ElementRef<T>>,
) => {
  const Tag = tag as ElementType;

  return (
    <Tag className={cn(styles.wrapper, className)} {...rest} ref={ref}>
      {children}
    </Tag>
  );
};

export default forwardRef(Wrapper) as <T extends ValidTags>(
  props: WrapperProps<T>,
) => ReturnType<typeof Wrapper>;
