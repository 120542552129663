import { useEffect, useState, type RefObject } from "react";

const TOLERANCE = 5;

const useIsScrollable = <T extends HTMLElement>(
  ref: RefObject<T>,
  tolerance = TOLERANCE,
) => {
  const [{ isScrollable, isAtBottom }, setScroll] = useState({
    isScrollable: false,
    isAtBottom: true,
  });

  const handleScroll = () => {
    const element = ref.current;
    if (!element) {
      return;
    }

    const isScrollable = element.scrollHeight > element.clientHeight;
    const isAtBottom =
      element.scrollHeight - element.clientHeight <=
      element.scrollTop + tolerance;
    setScroll({ isScrollable, isAtBottom });
  };

  useEffect(() => {
    const element = ref.current;
    if (element) {
      element.addEventListener("scroll", handleScroll);
      handleScroll();
    }

    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return { isScrollable, isAtBottom, handleScroll };
};

export default useIsScrollable;
