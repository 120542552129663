import cn from "classnames";
import PropTypes from "prop-types";

import styles from "./index.module.scss";

const Heading = ({ children, className, logo, tag, size, ...rest } = {}) => {
  const Component = tag;
  return (
    <Component
      className={cn(
        size !== tag ? size : undefined,
        { [styles.logo]: logo },
        className,
      )}
      {...rest}
    >
      {children}
    </Component>
  );
};

export default Heading;

Heading.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  logo: PropTypes.bool,
  tag: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6"]).isRequired,
  size: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6"]),
};
