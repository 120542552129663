/* eslint-disable @next/next/no-before-interactive-script-outside-document */

import Script from "next/script";

// https://developer.chrome.com/blog/using-requestidlecallback#checking_for_requestidlecallback
const RequestIdleCallbackShimScript = () => (
  <Script
    id="request-idle-callback-shim"
    strategy="beforeInteractive"
    dangerouslySetInnerHTML={{
      __html: `
					window.requestIdleCallback =
            window.requestIdleCallback ||
            function (cb) {
              const start = Date.now();
              return setTimeout(function () {
                cb({
                  didTimeout: false,
                  timeRemaining: function () {
                    return Math.max(0, 50 - (Date.now() - start));
                  },
                });
              }, 1);
            };

          window.cancelIdleCallback =
            window.cancelIdleCallback ||
            function (id) {
              clearTimeout(id);
            };
				`,
    }}
  />
);

export default RequestIdleCallbackShimScript;
