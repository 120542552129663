export const STUDENT = "student";
export const PARENT = "parent";
export const TEACHER = "teacher";
export const TUTOR = "tutor";
export const LEGACY_TEACHER_TUTOR = "teacher_tutor";

const RELATIONSHIPS = [
  {
    value: STUDENT,
    label: "Student",
  },
  {
    value: TEACHER,
    label: "Teacher",
  },
  {
    value: TUTOR,
    label: "Tutor",
  },
  {
    value: PARENT,
    label: "Parent/Guardian of a student",
  },
];

export default RELATIONSHIPS;
