import DefaultPill from "@/components/Pill";
import styles from "./index.module.scss";

type Props = { text: string };

const Pill = ({ text }: Props) => {
  return <DefaultPill text={text} classNames={styles.pill} />;
};

export default Pill;
