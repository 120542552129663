export const KEYS = {
  queue: "sme-queue",
  theme: "Sme.theme",
  revisionNoteViews: "SME.revision-note-views",
  topicQuestionPartSolutionViews: "SME.topic-question-part-solution-views",
  revisionNotePdfDownloadViews: "SME.revision-note-pdf-download-views",
  revisionNoteRegwallView: "SME.revision-note-view-regwall",
  joinPageViewTrigger: "SME.join-page-view-trigger",
  onboardingAccessMethod: "SME.onboarding-access-method",
  userBilling: "SME.user-billing",
  loggedInWithWpUsername: "SME.logged-in-with-wp-username",
  lastViewedCourse: "SME.last-viewed-course",
  registrationPageViewTrigger: "SME.registration-page-view-trigger",
  archiveBannerDismissed: "SME.archive-banner-dismissed",
  selectedPlanToPurchase: "SME.selected-plan-to-purchase",
  marketingOptIn: "SME.marketing-opt-in",
  aiMarkingEssay: "SME.ai-marking-essay",
  aiMarkingEssayDisclaimerDismissed:
    "SME.ai-marking-essay-disclaimer-dismissed",
  planPageViewTrigger: "SME.plan-page-view-trigger",
  aiMarkingExplanationModalSeen: "SME.ai-marking-explanation-modal-seen",
  aiMarkingSpotlightSeen: "SME.ai-marking-spotlight-seen",
  flashcardFlipInstructionsSeen: "SME.flashcard-flip-instructions-seen",
  flashcardNavigationCoachmarkSeen: "SME.flashcard-navigation-coachmark-seen",
  revisionNoteFlashcardsCoachmarkSeen:
    "SME.revision-note-flashcards-coachmark-seen",
  freeTrialEligibility: "SME.free-trial-eligibility",
  ssoFromLogInForm: "SME.sso-from-log-in-form",
  onboardingMarketingSeen: "SME.seen-onboarding-marketing-step",
} as const;

export const QUEUE_KEYS = {
  importQueue: "import-queue",
};
