import { PlanProps } from "src/config/data/plans";
import { useIsInVariant, useVariant } from "../useExperimentClient";
import { freeTrial } from "../useExperimentClient/variants";
import useFreeTrialEligibility from "../useFreeTrialEligibility";
import useIsClient from "../useIsClient";
import { useGlobalContext } from "@/context/global";

interface UseFreeTrialExperimentProps {
  plans?: PlanProps[];
  isEligible?: boolean;
}

interface UseFreeTrialExperimentReturnProps {
  isFreeTrialEnabled: boolean;
  trialPlan?: PlanProps;
}

const useFreeTrialExperiment = (
  props?: UseFreeTrialExperimentProps,
): UseFreeTrialExperimentReturnProps => {
  const { plans, isEligible } = props ?? {};
  const { state } = useGlobalContext();
  const isClient = useIsClient();
  const freeTrialVariant = useVariant(freeTrial.key);
  const isInFreeTrialExperiment =
    useIsInVariant(freeTrial.key, "treatment") && isClient;
  const isFreeTrialFlagEnabled = freeTrialVariant?.value
    ? isInFreeTrialExperiment
    : state.isExperimentClientReady && isInFreeTrialExperiment;
  const isEligibleClient = useFreeTrialEligibility(
    isEligible === undefined && isFreeTrialFlagEnabled,
  );
  const canPurchaseTrial = isEligible ?? isEligibleClient;

  return {
    isFreeTrialEnabled: isFreeTrialFlagEnabled && canPurchaseTrial,
    trialPlan: plans?.find((plan) => plan.hasTrial),
  };
};

export default useFreeTrialExperiment;
