import { useEffect } from "react";
import Cookies from "js-cookie";
import Link from "next/link";
import ArrowForwardIcon from "@material-design-icons/svg/filled/arrow_forward.svg";
import CloseIcon from "@material-design-icons/svg/filled/close.svg";
import ConstructionIcon from "@material-design-icons/svg/filled/construction.svg";
import Offcanvas from "react-bootstrap/Offcanvas";

import ROUTES from "src/config/data/routes";
import Button from "src/components/Button";
import ButtonIcon from "src/components/ButtonIcon";
import Switch from "src/components/form/Switch";
import { useGlobalContext } from "src/context/global";
import { useUser } from "src/lib/hooks/user";
import * as COOKIES from "src/config/data/cookies";
import { useOffcanvas } from "src/lib/hooks/offcanvas";
import { P } from "src/components/text";
import ImportQueueItem from "../ImportQueueItem";
import Icon from "src/components/Icon";

import styles from "./index.module.scss";

const NexusPanel = () => {
  const globalContext = useGlobalContext();
  const { isStaff } = useUser();
  const { show, handleShow, handleClose } = useOffcanvas();

  const handleEditModeOnChange = () => {
    globalContext.actions.setEditMode(!globalContext.state.editMode);
  };

  useEffect(() => {
    if (isStaff) {
      const showNexusPanel = Cookies.get(COOKIES.STAFF_SIDEBAR) === "yes";
      if (showNexusPanel) {
        // Looks like if the sidebar is shown immediately after page load then a backdrop is applied which doesn't handle
        // closing the sidebar properly and breaking it meaning the page has to be refreshed.
        setTimeout(() => {
          handleShow();
        }, 100);
      }
    }
  }, [isStaff, handleShow]);

  useEffect(() => {
    if (isStaff) {
      if (show) {
        Cookies.set(COOKIES.STAFF_SIDEBAR, "yes", { expires: 30 });
      } else {
        Cookies.remove(COOKIES.STAFF_SIDEBAR);
      }
    }
  }, [isStaff, show]);

  if (!isStaff) return null;

  return (
    <>
      <ButtonIcon
        aria-controls="nexus-panel"
        ariaLabel="Nexus"
        className="ms-2"
        onClick={handleShow}
        icon={<Icon svg={ConstructionIcon} />}
        type="button"
        variant="primary"
      />
      <Offcanvas
        scroll
        show={show}
        onHide={handleClose}
        className={styles.offcanvas}
        backdrop={false}
        placement="right"
        id="nexus-panel"
      >
        <Offcanvas.Header>
          <Button
            tag={Link}
            variant="primary"
            href={ROUTES.PAGES.NEXUS}
            icon={<Icon svg={ArrowForwardIcon} size="S" />}
          >
            Nexus
          </Button>
          <ButtonIcon
            ariaLabel="Close"
            onClick={handleClose}
            icon={<Icon svg={CloseIcon} />}
            type="button"
            variant="clear"
          />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Switch
            label="Edit mode"
            id="editMode"
            onChange={handleEditModeOnChange}
            checked={globalContext.state.editMode}
          />
          <div className="mt-4">
            <P className="mb-2" strong>
              Import queue
            </P>
            {globalContext.state.importQueueItems.length === 0 ? (
              <P size="S" className="fst-italic">
                No items in the queue
              </P>
            ) : (
              <ul className="list-unstyled">
                {globalContext.state.importQueueItems.map((item, index) => (
                  <ImportQueueItem item={item} key={index} index={index} />
                ))}
              </ul>
            )}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default NexusPanel;
