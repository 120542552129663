import getConfig from "next/config";

import { SUPPORTED_COUNTRY_CODES } from "./country-codes";

export const BASIC_PLAN_NAME = "Basic";
export const PROMOTED_PLAN_NAME = "Nerd Out";

const {
  publicRuntimeConfig: {
    gbpAnnualPriceId,
    gbpQuarterlyPriceId,
    gbpMonthlyPriceId,
    usdAnnualPriceId,
    usdQuarterlyPriceId,
    usdMonthlyPriceId,
    gbpUkAnnualPriceId,
    ukPricingExperimentMonthlyPriceV6,
  },
} = getConfig();

const ALL_FEATURES = [
  "Unlimited access",
  "Past Papers",
  "Revision Notes",
  "Topic Questions",
  "Model Answers",
  "Videos (Maths and Science)",
  "Downloadable PDFs",
];

interface PriceProps {
  stripeId: string;
  monthly: number;
  billedAt: number;
}

export interface PlanProps {
  id: string;
  name: string;
  copy: {
    subheading: string;
    description: string;
    renewalFrequency: string;
    feeFrequency: string;
    callToAction?: string | undefined;
    includes: string[];
    excludes?: string[];
  };
  prices?: {
    gbp: PriceProps;
    usd: PriceProps;
  };
  membershipType?: "1months" | "3months" | "12months";
  isPromoted?: boolean;
  hasTrial?: boolean;
}

export const BASIC_PLAN: PlanProps = {
  id: "basic",
  name: BASIC_PLAN_NAME,
  copy: {
    subheading: "free forever",
    description: "Free forever",
    renewalFrequency: "",
    feeFrequency: "annual fee",
    includes: [
      "Past Papers",
      "10 Revision Notes",
      "Videos (Maths and Science)",
    ],
    excludes: ["Topic Questions", "Model Answers", "Downloadable PDFs"],
  },
};

export const NERD_OUT_PLAN: PlanProps = {
  id: "nerd-out",
  name: PROMOTED_PLAN_NAME,
  copy: {
    subheading: "for 12 months",
    description: "12 month subscription",
    renewalFrequency: "every 12 months",
    feeFrequency: "annual fee",
    callToAction: "Join Now",
    includes: ALL_FEATURES,
  },
  prices: {
    gbp: {
      stripeId: gbpAnnualPriceId ?? "",
      monthly: 5,
      billedAt: 60,
    },
    usd: {
      stripeId: usdAnnualPriceId ?? "",
      monthly: 6,
      billedAt: 72,
    },
  },
  membershipType: "12months",
  isPromoted: true,
  hasTrial: true,
};

export const GEEK_OUT_PLAN: PlanProps = {
  id: "geek-out",
  name: "Geek Out",
  copy: {
    subheading: "for 3 months",
    description: "3 month subscription",
    renewalFrequency: "every 3 months",
    feeFrequency: "fee every 3 months",
    callToAction: "Join Now",
    includes: ALL_FEATURES,
  },
  prices: {
    gbp: {
      stripeId: gbpQuarterlyPriceId ?? "",
      monthly: 10,
      billedAt: 30,
    },
    usd: {
      stripeId: usdQuarterlyPriceId ?? "",
      monthly: 12,
      billedAt: 36,
    },
  },
  membershipType: "3months",
};

export const FREAK_OUT_PLAN: PlanProps = {
  id: "freak-out",
  name: "Freak Out",
  copy: {
    subheading: "for 1 month",
    description: "1 month subscription",
    renewalFrequency: "every month",
    feeFrequency: "monthly fee",
    callToAction: "Join Now",
    includes: ALL_FEATURES,
  },
  prices: {
    gbp: {
      stripeId: gbpMonthlyPriceId ?? "",
      monthly: 25,
      billedAt: 25,
    },
    usd: {
      stripeId: usdMonthlyPriceId ?? "",
      monthly: 30,
      billedAt: 30,
    },
  },
  membershipType: "1months",
};

export const UK_ANNUAL_PLAN: PlanProps = {
  ...NERD_OUT_PLAN,
  id: "e09dfcf1-b1b9-4b20-9aba-f8fe4b64213b",
  prices: {
    gbp: {
      stripeId: gbpUkAnnualPriceId ?? "",
      monthly: 4,
      billedAt: 48,
    },
    usd: {
      ...NERD_OUT_PLAN.prices.usd,
    },
  },
};

export const UK_MONTHLY_EXPERIMENTAL_PLAN: PlanProps = {
  ...FREAK_OUT_PLAN,
  id: "2a4c4130-66fc-459a-9c6a-e3f85f502978",
  prices: {
    gbp: {
      stripeId: ukPricingExperimentMonthlyPriceV6 ?? "",
      monthly: 12,
      billedAt: 12,
    },
    usd: {
      ...FREAK_OUT_PLAN.prices.usd,
    },
  },
};

export const PLANS = [BASIC_PLAN, NERD_OUT_PLAN, GEEK_OUT_PLAN, FREAK_OUT_PLAN];
export const UK_PLANS = [
  BASIC_PLAN,
  UK_ANNUAL_PLAN,
  GEEK_OUT_PLAN,
  FREAK_OUT_PLAN,
];
export const UK_EXPERIMENTAL_PLANS = [
  BASIC_PLAN,
  UK_ANNUAL_PLAN,
  UK_MONTHLY_EXPERIMENTAL_PLAN,
];

export const getPlansForCountryCode = (
  countryCode: string,
  bucket?: string,
) => {
  switch (countryCode) {
    case SUPPORTED_COUNTRY_CODES.GB:
      return bucket === "treatment" ? UK_EXPERIMENTAL_PLANS : UK_PLANS;
    default:
      return PLANS;
  }
};

export const ALL_PLANS: PlanProps[] = [
  ...PLANS,
  UK_ANNUAL_PLAN,
  UK_MONTHLY_EXPERIMENTAL_PLAN,
];
