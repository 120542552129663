import { ExperimentConfig } from "./types";

export const freeRegistration = {
  key: "free-registration" as const,
  variations: {
    on: "on",
    off: "off",
  },
};

export const freeTrial = {
  key: "free-trial-flag" as const,
  variations: {
    control: "control",
    treatment: "treatment",
  },
};

export const flashcardsExperienceV2 = {
  key: "flashcards-experience-v2" as const,
  variations: {
    on: "on",
    off: "off",
  },
};

export const regwallAllowanceV4 = {
  key: "regwall-allowance-v4" as const,
  variations: {
    control: "control",
    treatment: "treatment",
  },
};

export const regwallAllowanceV5 = {
  key: "regwall-allowance-v5" as const,
  variations: {
    control: "control",
    treatment: "treatment",
  },
};

export const onboardingV3 = {
  key: "onboarding-v3-august-2024" as const,
  variations: {
    control: "control",
    treatment: "treatment",
  },
};

export const topicQuestionsEmbed = {
  key: "topic-questions-embed" as const,
  variations: {
    control: "control",
    treatment: "treatment",
  },
};

export const EXPERIMENTS = {
  [freeRegistration.key]: freeRegistration,
  [freeTrial.key]: freeTrial,
  [flashcardsExperienceV2.key]: flashcardsExperienceV2,
  [regwallAllowanceV4.key]: regwallAllowanceV4,
  [onboardingV3.key]: onboardingV3,
  [regwallAllowanceV5.key]: regwallAllowanceV5,
  [topicQuestionsEmbed.key]: topicQuestionsEmbed,
} satisfies Record<string, ExperimentConfig>;

export type VariantKey = keyof typeof EXPERIMENTS;
