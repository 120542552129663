import { SITE_LANGUAGE } from "src/config/data/constants";
import { KEYS } from "src/config/data/localStorage";
import { SYLLABUS_VERSIONS } from "src/config/data/syllabus-versions";
import * as ls from "src/lib/localStorage";
import NAVIGATION from "src/config/data/navigation";
import { RESOURCE_TYPES } from "src/config/data/paths";
import type { Breadcrumbs, Subject } from "./types";
import { BillingAnalyticsOverview } from "../hooks/accounts/analyticsOverview";

const MAIN_RESOURCE_TYPES = [
  RESOURCE_TYPES.revisionNotes,
  RESOURCE_TYPES.topicQuestions,
  RESOURCE_TYPES.practicePaperQuestions,
  RESOURCE_TYPES.pastPaperQuestions,
];
const EXTRA_RESOURCE_TYPES = [
  RESOURCE_TYPES.pastPapers,
  RESOURCE_TYPES.practicePapers,
  RESOURCE_TYPES.topicQuestions,
  RESOURCE_TYPES.flashcards,
];

export const getExtraResourceType = (extraResource: string[]): string => {
  return EXTRA_RESOURCE_TYPES.find((resourceType) => {
    const extraResourcePath = extraResource[1];
    return extraResourcePath.includes(resourceType);
  });
};

export const getResourceLinksToShow = (
  subject: Subject,
): { resourceLinksTotal: number; resourceLinksToShow: string[] } => {
  const resourceLinksToShow = MAIN_RESOURCE_TYPES.filter((resourceType) => {
    /**
     * If the `paths.edition` has been set to "upcoming" or "old" then `year` won't be set, and we should not show links
     * to resources that do not have a year - unless the `resourceType` is "past-paper-questions".
     */
    if (resourceType !== RESOURCE_TYPES.pastPaperQuestions && !subject.year)
      return false;
    return subject[`count_${resourceType}`] > 0;
  });

  const resourceLinksTotal =
    resourceLinksToShow.length + subject.extraResources.length;

  return { resourceLinksTotal, resourceLinksToShow };
};

export const upperFirst = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const startCase = (string: string): string => {
  return string
    .split(/[\s-_]+/)
    .map(upperFirst)
    .join(" ")
    .trim();
};

export const constructCourseString = (breadcrumbs: Breadcrumbs): string => {
  const { titles, slugs } = breadcrumbs;
  return `${titles.board || slugs.board.toUpperCase()} ${titles.level} ${
    titles.subjectAlias ?? titles.subject
  }${titles.module ? `: ${titles.module}` : ""}`;
};

export const constructCoursePath = (breadcrumbs: Breadcrumbs) => {
  const { level, subject, module, board, year } = breadcrumbs.slugs;
  const subjectPath = module ? `${subject}_${module}/` : `${subject}/`;
  const boardPath = board ? `${board}/` : "";
  const yearPath = year ? `${year}/` : "";
  return `/${level}/${subjectPath}${boardPath}${yearPath}`;
};

export const constructCobaltCoursePath = (breadcrumbs: Breadcrumbs) => {
  const { level, subject, module, board, year, subjectAlias } =
    breadcrumbs.slugs;
  const subjectPath = subject ? `${subject}/` : "";
  const modulePath = module ? `${module}/` : "";
  const boardPath = board ? `${board}/` : "";
  const yearPath = year ? `${year}/` : "";
  const aliasPath = subjectAlias ? `${subjectAlias}/` : "";

  return `/${level}/${subjectPath}${boardPath}${aliasPath}${yearPath}${modulePath}`;
};

export const constructCobaltPastPapersPagePath = (breadcrumbs: Breadcrumbs) => {
  const { level, subject, board, subjectAlias } = breadcrumbs.slugs;
  const subjectPath = subject ? `${subject}/` : "";
  const boardPath = board ? `${board}/` : "";
  const aliasPath = subjectAlias ? `${subjectAlias}/` : "";

  return `/${level}/${subjectPath}${boardPath}${aliasPath}past-papers/`;
};

export const convertStringToSafeId = (string: string): string => {
  return string.replace(/[^A-Za-z0-9]/g, "-");
};

export const stripLeadingAndTrailingSlashes = (string: string): string =>
  string.replace(/^\/+|\/+$/g, "");

/**
 * Function to be used for redirect query strings from someone either logging out or logging in
 *
 * @param {*} path
 * @returns empty string if there should be no redirect or query string with a redirect key/value
 */
export const generatePostAuthenticationRedirect = (path: string): string => {
  const skipRedirectPaths = ["/checkout", "/members", "/login"];
  const isSkippedPath = !!skipRedirectPaths.find((pathStart) =>
    path.startsWith(pathStart),
  );
  const isHomepage = path === "/";

  return isHomepage || isSkippedPath ? "" : `?redirect=${encodeURI(path)}`;
};

export const getRelatedTopicQuestionsPath = ({
  breadcrumbs,
}: {
  breadcrumbs: Breadcrumbs;
}): string => {
  const { section, topic } = breadcrumbs.slugs;
  return `${constructCobaltCoursePath(breadcrumbs)}topic-questions/${section}/${topic}/`;
};

export const getRelatedRevisionNotePath = ({
  breadcrumbs,
  subtopicSlug,
  topicSlug,
  sectionSlug,
}: {
  breadcrumbs: Breadcrumbs;
  subtopicSlug: string;
  topicSlug: string;
  sectionSlug: string;
}): string => {
  return `${constructCobaltCoursePath(breadcrumbs)}revision-notes/${sectionSlug}/${topicSlug}/${subtopicSlug}`;
};

export const findCustomJsonLdTag = (string: string): string => {
  return string?.match(/<jsonld>(.*?)<\/jsonld>/)?.[1];
};

export const removeCustomJsonLdTag = (string: string): string => {
  return string?.replace(/<jsonld>.*<\/jsonld>/, "");
};

export const msToTime = (duration: number) => {
  let seconds: string | number = Math.floor((duration / 1000) % 60);
  let minutes: string | number = Math.floor((duration / (1000 * 60)) % 60);
  let hours: string | number = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  return hours + ":" + minutes + ":" + seconds;
};

export const getUserAnalyticsBilling = (): unknown => {
  return ls.getItem(KEYS.userBilling) || {};
};

export const setUserAnalyticsBilling = (data: BillingAnalyticsOverview) => {
  ls.setItem(KEYS.userBilling, JSON.stringify(data || {}));
};

/**
 * Function to get the current syllabus version for a given course.
 * To be deprecated:
 * We are slowly moving towards using the syllabus versions as known
 * in the database, but for now we are using the hardcoded versions.
 */

export type SyllabusVersionForSwitcher = {
  basePath: string;
  year: string;
  firstTeachingYear?: string;
  examYear?: string;
  newest?: boolean;
};

export const getStaticSyllabusVersions = (
  slugs: Breadcrumbs["slugs"],
): SyllabusVersionForSwitcher[] | null => {
  const key = `${slugs.level}/${slugs.subject}/${slugs.board}/${slugs?.year}`;
  const syllabusVersions = SYLLABUS_VERSIONS.find((course) =>
    course.find((syllabus) => syllabus.basePath === key),
  );
  return syllabusVersions || null;
};

export type CobaltSyllabusVersion = {
  id: string;
  type: "syllabus_version";
  attributes: {
    name: string;
    status: string;
    year_first_taught: number;
    year_first_examined: number;
    year_last_examined: number;
    slug: string;
    has_published_revision_notes: boolean;
    has_published_flashcard_sets: boolean;
    created_at: string;
    updated_at: string;
  };
  relationships: {
    course?: {
      data: {
        id: string;
        type: "course";
      };
    };
  };
};

export const transformCobaltSyllabusVersions = ({
  syllabus_versions,
  slugs,
}: {
  syllabus_versions:
    | CobaltSyllabusVersion[]
    | Partial<CobaltSyllabusVersion>[] // Nexus, partially transformed, syllabusVersions are being passed in too
    | null;
  slugs: Breadcrumbs["slugs"];
}): SyllabusVersionForSwitcher[] | null => {
  if (syllabus_versions === null || syllabus_versions.length === 0) return null;

  const publishedSyllabuses = syllabus_versions.filter(
    (syllabus) => syllabus.attributes.status !== "unpublished",
  );

  if (publishedSyllabuses.length === 0) return null;

  const transformedSyllabuses = publishedSyllabuses.map((syllabus) => {
    const firstTeachingYear = syllabus.attributes.year_first_taught?.toString();
    const year = syllabus.attributes.slug;
    const firstExamYear = syllabus.attributes.year_first_examined?.toString();
    const lastExamYear = syllabus.attributes.year_last_examined?.toString();

    // TODO: CON-719 use new module path structure
    const basePath =
      `${slugs.level}/${slugs.subject}/${slugs.board}` +
      (slugs.subjectAlias ? `/${slugs.subjectAlias}` : "") +
      `/${syllabus.attributes.slug}`;

    const isNewest = publishedSyllabuses.every((syll) => {
      return (
        syllabus.attributes.year_first_examined >=
        syll.attributes.year_first_examined
      );
    });

    const syllabusVersionForSwitcher: SyllabusVersionForSwitcher = {
      basePath,
      year,
    };

    if (firstTeachingYear) {
      syllabusVersionForSwitcher.firstTeachingYear = firstTeachingYear;
    }

    if (isNewest) {
      syllabusVersionForSwitcher.newest = true;
      syllabusVersionForSwitcher.examYear = firstExamYear;
    } else {
      syllabusVersionForSwitcher.newest = false;
      lastExamYear && (syllabusVersionForSwitcher.examYear = lastExamYear);
    }

    return syllabusVersionForSwitcher;
  });

  return transformedSyllabuses;
};

export const getNavigationLevels = () =>
  NAVIGATION.tops.filter((top) => !!top.url && top.url !== "/learning-hub/");

export const formatSitemapDate = (isoDate: string): string => {
  const date = new Date(isoDate);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const formatMonth = (month: number) => {
  const monthDate = new Date(0, month, 0);
  const formatter = new Intl.DateTimeFormat(SITE_LANGUAGE, { month: "long" });

  return formatter.format(monthDate);
};

export const removeNumberPrefix = (string: string): string => {
  return string.replace(/^\b\d+(\.\d+)+\b/g, "");
};
