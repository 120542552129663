import MissingCourseButton from "@/components/MissingCourseButton";
import ProgressiveHydration from "@/components/ProgressiveHydration";
import type { Board, Level, Subject } from "@/config/data/navigation";
import NAVIGATION from "@/config/data/navigation";
import { EVENT_VALUES } from "@/config/data/tracking";
import useIsScrollable from "@/lib/hooks/useIsScrollable";
import ChevronRightIcon from "@material-design-icons/svg/filled/chevron_right.svg";
import ExpandMore from "@material-design-icons/svg/filled/expand_more.svg";
import cn from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useRef, useState, type KeyboardEvent } from "react";
import Icon from "src/components/Icon";
import { trackInteraction, type TrackInteractionFn } from "../..";
import Pill from "../Pill";
import styles from "./index.module.scss";

const CourseTitle = ({
  level,
  subject,
  board,
}: {
  level: Level;
  subject: Subject;
  board: Board;
}) => {
  if (board.legacyTitle) return board.legacyTitle;

  // e.g. "Physics: SL (Last Exams 2024)" - an IB Physics course
  if (level.hasSingleBoard) {
    return [
      board.subjectAlias || subject.title,
      board.module?.title ? `: ${board.module.title}` : null,
      board.subtitle ? ` (${board.subtitle})` : null,
    ].join("");
  }

  const brackets = [board.subjectAlias, board.module?.title]
    .filter(Boolean)
    .join(" ");

  // e.g. "CIE (Core) Last Exams 2024" - an IGCSE Maths course
  return [board.title, brackets ? `(${brackets})` : null, board.subtitle]
    .filter(Boolean)
    .join(" ");
};

type BoardProps = {
  board: Board;
  isLevelSelected: boolean;
  level: Level;
  subject: Subject;
  trackInteraction: TrackInteractionFn;
};

const Board = ({
  board,
  isLevelSelected,
  level,
  subject,
  trackInteraction,
}: BoardProps) => {
  const Element = board.url ? Link : "span";

  /* We are testing the impact of *not* appending the O Level resource links to the DOM on page load (only loading on demand) */
  const isInNavExperiment = level.title === "O Level";
  const shouldRenderResourceLinks =
    !isInNavExperiment || (isInNavExperiment && isLevelSelected);

  return (
    <li className={styles.board}>
      <Element
        className={cn(styles.link, styles.bold, "mb-3")}
        href={board.url}
        data-cy={`top-menu-subject-expanded-${board.title}`}
        onClick={() =>
          trackInteraction(EVENT_VALUES.navigationBarInteraction.clicked, [
            board.title,
          ])
        }
      >
        <CourseTitle level={level} subject={subject} board={board} />
      </Element>
      {shouldRenderResourceLinks && (
        <ul className={styles.links}>
          {board.links.map((link, index) => (
            <li key={index} className="d-flex flex-row align-items-center mb-3">
              <Link
                href={link.url}
                className={styles.link}
                data-cy={`top-menu-subject-expanded-${board.title}-${link.title}`}
                onClick={() =>
                  trackInteraction(
                    EVENT_VALUES.navigationBarInteraction.clicked,
                    [board.title, link.title],
                  )
                }
              >
                {link.title}
              </Link>
              {!!link?.label && <Pill text={link.label} />}
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

type SubjectProps = {
  isLevelSelected: boolean;
  isSubjectSelected: boolean;
  level: Level;
  subject: Subject;
  trackInteraction: TrackInteractionFn;
};
const Subject = ({
  isLevelSelected,
  isSubjectSelected,
  level,
  subject,
  trackInteraction,
}: SubjectProps) => {
  const LinkOrButton = subject.url ? Link : "span";
  const subjectContentRef = useRef<HTMLDivElement>(null);
  const { isScrollable, isAtBottom, handleScroll } =
    useIsScrollable(subjectContentRef);

  useEffect(() => {
    if (isLevelSelected || isSubjectSelected) {
      // Call handleScroll manually to trigger rerender when element becomes visible
      handleScroll();
    }
  }, [isLevelSelected, isSubjectSelected]);

  return (
    <div
      className={cn(styles.subjectContainer, {
        "d-none": !isSubjectSelected,
        [styles.scrollable]: isScrollable && !isAtBottom,
      })}
    >
      <div
        className={cn("h-100 d-flex flex-column", styles.subjectContent)}
        ref={subjectContentRef}
      >
        <LinkOrButton
          href={subject.url}
          className={cn(styles.link, styles.linkHeading)}
          data-cy={`top-menu-subject-${subject.title}`}
          onClick={() =>
            trackInteraction(EVENT_VALUES.navigationBarInteraction.clicked, [
              subject.title,
            ])
          }
        >
          {level.title} <Icon svg={ChevronRightIcon} size="S" /> {subject.title}
        </LinkOrButton>

        <ul className={styles.boards}>
          {subject?.rights.map((board, index) => (
            <Board
              board={board}
              isLevelSelected={isLevelSelected}
              key={index}
              level={level}
              subject={subject}
              trackInteraction={(interaction, item) =>
                trackInteraction(interaction, [subject.title, ...item])
              }
            />
          ))}
        </ul>

        <MissingCourseButton
          className="align-self-end mb-4"
          variant="link-primary"
        />
      </div>
    </div>
  );
};

type LevelContentProps = {
  isSelected: boolean;
  level: Level;
  onCollapse: () => void;
  trackInteraction: TrackInteractionFn;
};
const LevelContent = ({
  isSelected,
  level,
  trackInteraction,
  onCollapse,
}: LevelContentProps) => {
  const subjectsRef = useRef<HTMLUListElement>(null);
  const [selectedSubject, setSelectedSubject] = useState(level.lefts[0]);
  const { isScrollable, isAtBottom, handleScroll } =
    useIsScrollable(subjectsRef);

  useEffect(() => {
    if (isSelected) {
      // Call handleScroll manually to trigger rerender when element becomes visible
      handleScroll();
    }
  }, [isSelected]);

  const handleSubjectKeyDown = (subject: Subject) => (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const newSubject = selectedSubject === subject ? null : subject;
      if (newSubject) {
        trackInteraction(EVENT_VALUES.navigationBarInteraction.hovered, [
          newSubject.title,
        ]);
      }
      setSelectedSubject(newSubject);
    }
  };

  return (
    <div className={cn(styles.wrapper, "d-none", { "d-lg-block": isSelected })}>
      <div className={cn(styles.nav)}>
        <div className="container h-100 position-relative">
          <ul ref={subjectsRef} className={styles.subjects}>
            {level.lefts.map((subject, index) => {
              const Element = subject.url ? Link : "button";
              return (
                <li
                  key={index}
                  className={styles.subject}
                  data-cy={`menu-subject-${subject.title}`}
                >
                  <Element
                    className={cn(styles.item, {
                      [styles.itemActive]:
                        subject.title === selectedSubject?.title,
                    })}
                    href={subject.url}
                    onMouseEnter={() => {
                      trackInteraction(
                        EVENT_VALUES.navigationBarInteraction.hovered,
                        [subject.title],
                      );
                      setSelectedSubject(subject);
                    }}
                    onKeyDown={handleSubjectKeyDown(subject)}
                    onClick={() =>
                      trackInteraction(
                        EVENT_VALUES.navigationBarInteraction.clicked,
                        [subject.title],
                      )
                    }
                  >
                    {subject.title}{" "}
                    <Icon svg={ChevronRightIcon} className={styles.chevron} />
                  </Element>
                  <Subject
                    isLevelSelected={isSelected}
                    isSubjectSelected={subject === selectedSubject}
                    level={level}
                    subject={subject}
                    trackInteraction={trackInteraction}
                  />
                </li>
              );
            })}
          </ul>

          <div
            className={cn(styles.subject, styles.scrollNotice, {
              [styles.visible]: isScrollable && !isAtBottom,
            })}
          >
            Scroll for more
            <Icon svg={ExpandMore} size="S" className={styles.expandChevron} />
          </div>
        </div>
      </div>

      <div className={styles.backdrop} onMouseEnter={onCollapse} />
    </div>
  );
};

type LevelProps = {
  level: Level;
  selectedLevel: Level;
  onExpand: (level: Level) => void;
  onCollapse: () => void;
};
const Level = ({ level, selectedLevel, onExpand, onCollapse }: LevelProps) => {
  const levelTimer = useRef<NodeJS.Timeout>();

  const handleLevelKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      selectedLevel === level ? onCollapse() : expandLevel();
    }
  };

  const expandLevel = () => {
    trackInteraction(EVENT_VALUES.navigationBarInteraction.hovered, [
      level.title,
    ]);
    onExpand(level);
  };

  const handleMouseEnter = () => {
    if (selectedLevel) {
      expandLevel();
    } else {
      levelTimer.current = setTimeout(expandLevel, 500);
    }
  };

  const handleMouseLeave = () => clearTimeout(levelTimer.current);

  const Element = level.url ? Link : "button";

  return (
    <li className="nav-item dropdown">
      <Element
        href={level.url || undefined}
        className={cn(styles.navLink, "nav-link d-flex align-items-center")}
        role="button"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onKeyDown={handleLevelKeyDown}
        onClick={() =>
          trackInteraction(EVENT_VALUES.navigationBarInteraction.clicked, [
            level.title,
          ])
        }
      >
        {level.title}{" "}
        <Icon svg={ExpandMore} size="S" className={styles.expandChevron} />
      </Element>
      <ProgressiveHydration force={!!selectedLevel}>
        <LevelContent
          isSelected={level === selectedLevel}
          level={level}
          onCollapse={onCollapse}
          trackInteraction={(interaction, item) =>
            trackInteraction(interaction, [level.title, ...item])
          }
        />
      </ProgressiveHydration>
    </li>
  );
};

const DesktopNav = () => {
  const router = useRouter();
  const [selectedLevel, setSelectedLevel] = useState<Level | null>(null);

  useEffect(() => {
    /**
     * Ensure nav is closed as user navigates around site.
     */

    const handleRouteChangeStart = () => handleCollapse();

    router.events.on("routeChangeStart", handleRouteChangeStart);

    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: globalThis.KeyboardEvent) => {
      if (["Escape", "Esc"].includes(event.key)) handleCollapse();
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    return () => {
      enableBodyScroll();
    };
  }, []);

  const handleExpand = (level: Level) => {
    if (!selectedLevel) {
      disableBodyScroll();
    }
    setSelectedLevel(level);
  };

  const handleCollapse = () => {
    enableBodyScroll();
    setSelectedLevel(null);
  };

  const enableBodyScroll = () => {
    document.body.style.overflow = "initial";
  };

  const disableBodyScroll = () => {
    document.body.style.overflow = "hidden";
  };

  return (
    <ul className="navbar-nav mx-auto">
      {NAVIGATION.tops.map((level, index) => (
        <Level
          key={index}
          level={level}
          selectedLevel={selectedLevel}
          onExpand={handleExpand}
          onCollapse={handleCollapse}
        />
      ))}
    </ul>
  );
};

export default DesktopNav;
