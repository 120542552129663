import { useEffect } from "react";
import { useRouter } from "next/router";
import { useSWRConfig } from "swr";
import ROUTES from "src/config/data/routes";

const useTokenRefresh = (fromSupertokens) => {
  const router = useRouter();
  const { mutate } = useSWRConfig();
  const needsTokenRefresh = fromSupertokens === "needs-refresh";

  useEffect(() => {
    const refreshSession = async () => {
      if (fromSupertokens === "needs-refresh") {
        try {
          const { attemptRefreshingSession } = await import(
            "src/lib/supertokens"
          );
          if (await attemptRefreshingSession()) {
            window.location.reload();
          } else {
            throw new Error("Session refresh failed");
          }
        } catch {
          await mutate(ROUTES.API.USER);
          router.push(ROUTES.PAGES.LOGIN);
        }
      }
    };

    refreshSession();
  }, [fromSupertokens, mutate, router]);

  return needsTokenRefresh;
};

export default useTokenRefresh;
