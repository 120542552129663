import { ErrorEvent } from "@sentry/types";

const ignoreClientErrors = ["Request timeout"];
const ignoreServerErrors = [];

const removeUserEmail = (event: ErrorEvent): ErrorEvent => {
  if (event.user) {
    delete event.user.email;
  }

  return event;
};

const filterUnwantedErrors = (event: ErrorEvent): ErrorEvent => {
  if (event.exception?.values && event.exception.values.length === 1) {
    const exceptionValue = event.exception.values[0];
    const exceptionType = exceptionValue.type;
    const stackTraceFrameFunctions = exceptionValue.stacktrace?.frames?.map(
      (frame) => frame.function,
    );

    if (
      exceptionType === "TypeError" &&
      exceptionValue.mechanism?.handled === false &&
      exceptionValue.mechanism?.data?.function === "setInterval" &&
      stackTraceFrameFunctions.includes("scan_iframes")
    ) {
      return null;
    }
  }

  return event;
};

const beforeSend = (event: ErrorEvent): ErrorEvent | null => {
  let errorEvent = event;
  try {
    errorEvent = removeUserEmail(errorEvent);
    errorEvent = filterUnwantedErrors(errorEvent);
  } catch (error) {
    console.error("Error filtering unwanted errors", error);
  }

  return errorEvent;
};

export { beforeSend, ignoreClientErrors, ignoreServerErrors };
