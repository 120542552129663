import cn from "classnames";
import PropTypes from "prop-types";

import styles from "./index.module.scss";

const BASE_CLASSES = {
  long: "long",
  medium: "medium",
  "medium-green": "medium-green",
  short: "short",
  circle: "circle",
  "circle-yellow": "circle-yellow",

  double: "double",
  "double-green": "double-green",
  "underline-blue": "underline-blue",
  "double-stroke-blue": "double-stroke-blue",
};

const Highlight = ({ children, className, variant, tag, ...rest } = {}) => {
  const Component = tag;
  const size = BASE_CLASSES[variant];
  return (
    <Component className={cn(styles[size], className)} {...rest}>
      {children}
    </Component>
  );
};

export default Highlight;

Highlight.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  variant: PropTypes.oneOf(Object.keys(BASE_CLASSES)).isRequired,
  tag: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6", "p", "span"])
    .isRequired,
};

Highlight.defaultProps = {
  tag: "span",
};
