import type { Navigation } from ".";

const IB_HIGHER = {
  title: "HL",
  color: "red",
} as const;

const IB_STANDARD = {
  title: "SL",
  color: "green",
} as const;

const FOUNDATION = {
  title: "Foundation",
  color: "green",
} as const;

const HIGHER = {
  title: "Higher",
  color: "red",
} as const;

const CORE = {
  title: "Core",
  color: "green",
} as const;

const EXTENDED = {
  title: "Extended",
  color: "red",
} as const;

const CONFIG: Navigation = {
  tops: [
    {
      url: "/gcse/",
      lefts: [
        {
          url: "/gcse/maths/",
          title: "Maths",
          rights: [
            {
              url: "/gcse/maths/aqa/22/",
              links: [
                {
                  url: "/gcse/maths/aqa/22/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/gcse/maths/aqa/22/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/maths/aqa/22/higher/flashcards/",
                  title: "Flashcards",
                },
                {
                  url: "/gcse/maths/aqa/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
            },
            {
              url: "/gcse/maths_foundation/edexcel/17/",
              links: [
                {
                  url: "/gcse/maths_foundation/edexcel/17/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/gcse/maths_foundation/edexcel/17/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/maths/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
              module: FOUNDATION,
            },
            {
              url: "/gcse/maths/edexcel/22/",
              links: [
                {
                  url: "/gcse/maths/edexcel/22/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/gcse/maths/edexcel/22/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/maths/edexcel/22/higher/flashcards/",
                  title: "Flashcards",
                },
                {
                  url: "/gcse/maths/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
              module: HIGHER,
            },
            {
              url: "/gcse/maths/ocr/22/",
              links: [
                {
                  url: "/gcse/maths/ocr/22/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/gcse/maths/ocr/22/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/maths/ocr/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
            },
            {
              url: "/gcse/statistics/aqa/",
              links: [
                {
                  url: "/gcse/statistics/aqa/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
              subjectAlias: "Statistics",
            },
            {
              url: "/gcse/further-maths/aqa/20/",
              links: [
                {
                  url: "/gcse/further-maths/aqa/20/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/gcse/further-maths/aqa/20/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/further-maths/aqa/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
              subjectAlias: "Further Maths",
            },
            {
              url: "/gcse/statistics/edexcel/17/foundation/",
              links: [
                {
                  url: "/gcse/statistics/edexcel/17/foundation/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/statistics/edexcel/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
              subjectAlias: "Statistics",
              module: FOUNDATION,
            },
            {
              url: "/gcse/statistics/edexcel/17/higher/",
              links: [
                {
                  url: "/gcse/statistics/edexcel/17/higher/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/statistics/edexcel/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
              subjectAlias: "Statistics",
              module: HIGHER,
            },
            {
              url: "/gcse/maths/wjec/",
              links: [
                {
                  url: "/gcse/maths/wjec/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "WJEC",
            },
            {
              url: "/gcse/maths/wjec/numeracy/",
              links: [
                {
                  url: "/gcse/maths/wjec/numeracy/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "WJEC",
              subjectAlias: "Maths Numeracy",
            },
            {
              url: "/gcse/maths/wjec-eduqas/",
              links: [
                {
                  url: "/gcse/maths/wjec-eduqas/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "WJEC Eduqas",
            },
          ],
        },
        {
          url: "/gcse/biology/",
          title: "Biology",
          rights: [
            {
              url: "/gcse/biology/aqa/18/",
              links: [
                {
                  url: "/gcse/biology/aqa/18/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/gcse/biology/aqa/18/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/biology/aqa/18/flashcards/",
                  title: "Flashcards",
                },
                {
                  url: "/gcse/biology/aqa/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
            },
            {
              url: "/gcse/biology/edexcel/18/",
              links: [
                {
                  url: "/gcse/biology/edexcel/18/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/gcse/biology/edexcel/18/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/biology/edexcel/18/flashcards/",
                  title: "Flashcards",
                },
                {
                  url: "/gcse/biology/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/gcse/biology/ocr-gateway/18/",
              links: [
                {
                  url: "/gcse/biology/ocr-gateway/18/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/gcse/biology/ocr-gateway/18/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/biology/ocr-gateway/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
              subjectAlias: "Biology A (Gateway)",
            },
            {
              url: "/gcse/biology/wjec/18/",
              links: [
                {
                  url: "/gcse/biology/wjec/18/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/biology/wjec/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "WJEC",
            },
          ],
        },
        {
          url: "/gcse/chemistry/",
          title: "Chemistry",
          rights: [
            {
              url: "/gcse/chemistry/aqa/18/",
              links: [
                {
                  url: "/gcse/chemistry/aqa/18/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/gcse/chemistry/aqa/18/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/chemistry/aqa/18/flashcards/",
                  title: "Flashcards",
                },
                {
                  url: "/gcse/chemistry/aqa/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
            },
            {
              url: "/gcse/chemistry/edexcel/18/",
              links: [
                {
                  url: "/gcse/chemistry/edexcel/18/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/gcse/chemistry/edexcel/18/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/chemistry/edexcel/18/flashcards/",
                  title: "Flashcards",
                },
                {
                  url: "/gcse/chemistry/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/gcse/chemistry/ocr-gateway/18/",
              links: [
                {
                  url: "/gcse/chemistry/ocr-gateway/18/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/gcse/chemistry/ocr-gateway/18/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/chemistry/ocr-gateway/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
              subjectAlias: "Chemistry A (Gateway)",
            },
            {
              url: "/gcse/chemistry/wjec/18/",
              links: [
                {
                  url: "/gcse/chemistry/wjec/18/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/gcse/chemistry/wjec/18/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/chemistry/wjec/past-papers",
                  title: "Past Papers",
                },
              ],
              title: "WJEC",
            },
          ],
        },
        {
          url: "/gcse/physics/",
          title: "Physics",
          rights: [
            {
              url: "/gcse/physics/aqa/18/",
              links: [
                {
                  url: "/gcse/physics/aqa/18/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/gcse/physics/aqa/18/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/physics/aqa/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
            },
            {
              url: "/gcse/physics/edexcel/18/",
              links: [
                {
                  url: "/gcse/physics/edexcel/18/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/gcse/physics/edexcel/18/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/physics/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/gcse/physics/ocr-gateway/18/",
              links: [
                {
                  url: "/gcse/physics/ocr-gateway/18/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/gcse/physics/ocr-gateway/18/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/physics/ocr-gateway/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
              subjectAlias: "Physics A (Gateway)",
            },
            {
              url: "/gcse/physics/wjec/18/",
              links: [
                {
                  url: "/gcse/physics/wjec/18/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/physics/wjec/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "WJEC",
            },
          ],
        },
        {
          title: "Combined Science",
          rights: [
            {
              url: "/gcse/biology_combined-science/aqa/18/",
              links: [
                {
                  url: "/gcse/biology_combined-science/aqa/18/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/gcse/biology_combined-science/aqa/18/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/biology_combined-science/aqa/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              legacyTitle: "AQA Combined: Biology",
              title: "AQA",
              subjectAlias: "Biology",
              subtitle: "Combined Science: Trilogy",
            },
            {
              url: "/gcse/chemistry_combined-science/aqa/18/",
              links: [
                {
                  url: "/gcse/chemistry_combined-science/aqa/18/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/gcse/chemistry_combined-science/aqa/18/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/chemistry_combined-science/aqa/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              legacyTitle: "AQA Combined: Chemistry",
              title: "AQA",
              subjectAlias: "Chemistry",
              subtitle: "Combined Science: Trilogy",
            },
            {
              url: "/gcse/physics_combined-science/aqa/18/",
              links: [
                {
                  url: "/gcse/physics_combined-science/aqa/18/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/gcse/physics_combined-science/aqa/18/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/physics_combined-science/aqa/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              legacyTitle: "AQA Combined: Physics",
              title: "AQA",
              subjectAlias: "Physics",
              subtitle: "Combined Science: Trilogy",
            },
            {
              url: "/gcse/biology_combined-science/edexcel/18/",
              links: [
                {
                  url: "/gcse/biology_combined-science/edexcel/18/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/gcse/biology_combined-science/edexcel/18/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/biology_combined-science/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              legacyTitle: "Edexcel Combined: Biology",
              title: "Edexcel",
              subjectAlias: "Biology",
              subtitle: "Combined Science",
            },
            {
              url: "/gcse/chemistry_combined-science/edexcel/18/",
              links: [
                {
                  url: "/gcse/chemistry_combined-science/edexcel/18/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/gcse/chemistry_combined-science/edexcel/18/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/chemistry_combined-science/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              legacyTitle: "Edexcel Combined: Chemistry",
              title: "Edexcel",
              subjectAlias: "Chemistry",
              subtitle: "Combined Science",
            },
            {
              url: "/gcse/physics_combined-science/edexcel/18/",
              links: [
                {
                  url: "/gcse/physics_combined-science/edexcel/18/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/gcse/physics_combined-science/edexcel/18/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/physics_combined-science/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              legacyTitle: "Edexcel Combined: Physics",
              title: "Edexcel",
              subjectAlias: "Physics",
              subtitle: "Combined Science",
            },
            {
              url: "/gcse/biology_combined-science/ocr-gateway/18/",
              links: [
                {
                  url: "/gcse/biology_combined-science/ocr-gateway/18/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/gcse/biology_combined-science/ocr-gateway/18/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/biology_combined-science/ocr-gateway/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              legacyTitle: "OCR Gateway Combined: Biology",
              title: "OCR",
              subjectAlias: "Biology",
              subtitle: "Combined Science A (Gateway)",
            },
            {
              url: "/gcse/chemistry_combined-science/ocr-gateway/18/",
              links: [
                {
                  url: "/gcse/chemistry_combined-science/ocr-gateway/18/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/gcse/chemistry_combined-science/ocr-gateway/18/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/chemistry_combined-science/ocr-gateway/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              legacyTitle: "OCR Gateway Combined: Chemistry",
              title: "OCR",
              subjectAlias: "Chemistry",
              subtitle: "Combined Science A (Gateway)",
            },
            {
              url: "/gcse/physics_combined-science/ocr-gateway/18/",
              links: [
                {
                  url: "/gcse/physics_combined-science/ocr-gateway/18/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/gcse/physics_combined-science/ocr-gateway/18/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/physics_combined-science/ocr-gateway/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              legacyTitle: "OCR Gateway Combined: Physics",
              title: "OCR",
              subjectAlias: "Physics",
              subtitle: "Combined Science A (Gateway)",
            },
            {
              url: "/gcse/biology_combined-science/wjec/18/",
              links: [
                {
                  url: "/gcse/biology_combined-science/wjec/18/revision-notes/",
                  title: "Revision Notes",
                },
              ],
              legacyTitle: "WJEC Combined: Biology",
              title: "WJEC",
              subjectAlias: "Biology",
              subtitle: "Science (Double Award)",
            },
            {
              url: "/gcse/chemistry_combined-science/wjec/18/",
              links: [
                {
                  url: "/gcse/chemistry_combined-science/wjec/18/revision-notes/",
                  title: "Revision Notes",
                },
              ],
              legacyTitle: "WJEC Combined: Chemistry",
              title: "WJEC",
              subjectAlias: "Chemistry",
              subtitle: "Science (Double Award)",
            },
            {
              url: "/gcse/physics_combined-science/wjec/18/",
              links: [
                {
                  url: "/gcse/physics_combined-science/wjec/18/revision-notes/",
                  title: "Revision Notes",
                },
              ],
              legacyTitle: "WJEC Combined: Physics",
              title: "WJEC",
              subjectAlias: "Physics",
              subtitle: "Science (Double Award)",
            },
          ],
        },
        {
          url: "/gcse/business/",
          title: "Business",
          rights: [
            {
              url: "/gcse/business/aqa/17/",
              links: [
                {
                  url: "/gcse/business/aqa/17/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/business/aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
            },
            {
              url: "/gcse/business/edexcel/19/",
              links: [
                {
                  url: "/gcse/business/edexcel/19/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/gcse/business/edexcel/19/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/business/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/gcse/business/ocr/17/",
              links: [
                {
                  url: "/gcse/business/ocr/17/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/business/ocr/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
            },
            {
              url: "/gcse/business/wjec-eduqas/",
              links: [
                {
                  url: "/gcse/business/wjec-eduqas/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "WJEC Eduqas",
            },
          ],
        },
        {
          url: "/gcse/computer-science/",
          title: "Computer Science",
          rights: [
            {
              url: "/gcse/computer-science/aqa/20/",
              title: "AQA",
              links: [
                {
                  title: "Revision Notes",
                  url: "/gcse/computer-science/aqa/20/revision-notes/",
                },
                {
                  title: "Past Papers",
                  url: "/gcse/computer-science/aqa/past-papers/",
                },
              ],
            },
            {
              url: "/gcse/computer-science/edexcel/20/",
              title: "Edexcel",
              links: [
                {
                  title: "Revision Notes",
                  url: "/gcse/computer-science/edexcel/20/revision-notes/",
                },
                {
                  title: "Past Papers",
                  url: "/gcse/computer-science/edexcel/past-papers/",
                },
              ],
            },
            {
              url: "/gcse/computer-science/ocr/22/",
              title: "OCR",
              links: [
                {
                  title: "Revision Notes",
                  url: "/gcse/computer-science/ocr/22/revision-notes/",
                },
                {
                  title: "Flashcards",
                  url: "/gcse/computer-science/ocr/22/flashcards/",
                },
                {
                  title: "Past Papers",
                  url: "/gcse/computer-science/ocr/-/pages/past-papers/",
                },
              ],
            },
          ],
        },
        {
          url: "/gcse/economics/",
          title: "Economics",
          rights: [
            {
              url: "/gcse/economics/aqa/",
              title: "AQA",
              links: [
                {
                  title: "Past Papers",
                  url: "/gcse/economics/aqa/-/pages/past-papers/",
                },
              ],
            },
            {
              url: "/gcse/economics/ocr/",
              title: "OCR",
              links: [
                {
                  title: "Past Papers",
                  url: "/gcse/economics/ocr/-/pages/past-papers/",
                },
              ],
            },
          ],
        },
        {
          url: "/gcse/english-language/",
          title: "English Language",
          rights: [
            {
              url: "/gcse/english-language/aqa/17/",
              links: [
                {
                  url: "/gcse/english-language/aqa/17/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/english-language/aqa/17/practice-paper-questions/",
                  title: "Practice Papers",
                },
                {
                  url: "/gcse/english-language/aqa/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
            },
            {
              url: "/gcse/english-language/edexcel/",
              links: [
                {
                  url: "/gcse/english-language/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/gcse/english-language/edexcel/2-0/",
              links: [
                {
                  url: "/gcse/english-language/edexcel/2-0/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
              subjectAlias: "English Language 2.0",
            },
            {
              url: "/gcse/english-language/ocr/",
              links: [
                {
                  url: "/gcse/english-language/ocr/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
            },
            {
              url: "/gcse/english-language/wjec/",
              links: [
                {
                  url: "/gcse/english-language/wjec/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "WJEC",
            },
            {
              url: "/gcse/english-language/wjec-eduqas/15/",
              links: [
                {
                  url: "/gcse/english-language/wjec-eduqas/15/eduqas-gcse-english-language-paper-1/",
                  title: "Paper 1",
                },
                {
                  url: "/gcse/english-language/wjec-eduqas/15/eduqas-gcse-english-language-paper-2/",
                  title: "Paper 2",
                },
              ],
              title: "WJEC Eduqas",
              module: { title: "Modular" },
            },
          ],
        },
        {
          url: "/gcse/english-literature/",
          title: "English Literature",
          rights: [
            {
              url: "/gcse/english-literature/aqa/17/",
              links: [
                {
                  url: "/gcse/english-literature/aqa/17/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/gcse/english-literature/aqa/17/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/english-literature/aqa/17/flashcards/",
                  title: "Flashcards",
                },
                {
                  url: "/gcse/english-literature/aqa/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
            },
            {
              url: "/gcse/english-literature/edexcel/17/",
              links: [
                {
                  url: "/gcse/english-literature/edexcel/17/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/english-literature/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/gcse/english-literature/ocr/16/",
              links: [
                {
                  url: "/gcse/english-literature/ocr/16/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/english-literature/ocr/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
            },
            {
              url: "/gcse/english-literature/wjec/",
              links: [
                {
                  url: "/gcse/english-literature/wjec/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "WJEC",
            },
            {
              url: "/gcse/english-literature/wjec-eduqas/15/",
              links: [
                {
                  url: "/gcse/english-literature/wjec-eduqas/15/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/english-literature/wjec-eduqas/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "WJEC Eduqas",
            },
          ],
        },
        {
          url: "/gcse/french/",
          title: "French",
          rights: [
            {
              url: "/gcse/french/aqa/",
              title: "AQA",
              links: [
                {
                  title: "Past Papers",
                  url: "/gcse/french/aqa/-/pages/past-papers/",
                },
              ],
            },
            {
              url: "/gcse/french/edexcel/",
              title: "Edexcel",
              links: [
                {
                  title: "Past Papers",
                  url: "/gcse/french/edexcel/past-papers/",
                },
              ],
            },
          ],
        },
        {
          url: "/gcse/geography/",
          title: "Geography",
          rights: [
            {
              url: "/gcse/geography/aqa/18/",
              links: [
                {
                  url: "/gcse/geography/aqa/18/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/gcse/geography/aqa/18/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/geography/aqa/18/flashcards/",
                  title: "Flashcards",
                },
                {
                  url: "/gcse/geography/aqa/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
            },
            {
              url: "/gcse/geography/edexcel/a/18/",
              links: [
                {
                  url: "/gcse/geography/edexcel/a/18/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/geography/edexcel/a/18/flashcards/",
                  title: "Flashcards",
                },
                {
                  url: "/gcse/geography/edexcel/a/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
              subjectAlias: "Geography A",
            },
            {
              url: "/gcse/geography_b-1gb0/edexcel/18/",
              links: [
                {
                  url: "/gcse/geography_b-1gb0/edexcel/18/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/gcse/geography_b-1gb0/edexcel/18/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/geography/edexcel/b/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
              subjectAlias: "Geography B",
            },
            {
              url: "/gcse/geography/ocr/a/",
              links: [
                {
                  url: "/gcse/geography/ocr/a/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
              subjectAlias: "Geography A",
            },
            {
              url: "/gcse/geography/ocr/18/",
              links: [
                {
                  url: "/gcse/geography/ocr/18/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/gcse/geography/ocr/18/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/geography/ocr/b/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
              subjectAlias: "Geography B",
            },
            {
              url: "/gcse/geography/wjec/",
              links: [
                {
                  url: "/gcse/geography/wjec/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "WJEC",
            },
            {
              url: "/gcse/geography/wjec-eduqas/a/",
              links: [
                {
                  url: "/gcse/geography/wjec-eduqas/a/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "WJEC Eduqas",
              subjectAlias: "Geography A",
            },
            {
              url: "/gcse/geography/wjec-eduqas/b/",
              links: [
                {
                  url: "/gcse/geography/wjec-eduqas/b/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "WJEC Eduqas",
              subjectAlias: "Geography B",
            },
          ],
        },
        {
          url: "/gcse/history/",
          title: "History",
          rights: [
            {
              url: "/gcse/history/aqa/16/",
              title: "AQA",
              links: [
                {
                  title: "The Period Study",
                  url: "/gcse/history/aqa/16/aqa-the-period-study/revision-notes/",
                },
                {
                  title: "Wider World Depth Study",
                  url: "/gcse/history/aqa/16/aqa-wider-world-depth-study/revision-notes/",
                },
                {
                  title: "Thematic Study",
                  url: "/gcse/history/aqa/16/aqa-thematic-study/revision-notes/",
                },
                {
                  title: "British Depth Study",
                  url: "/gcse/history/aqa/16/aqa-british-depth-study/revision-notes/",
                },
                {
                  title: "Past Papers",
                  url: "/gcse/history/aqa/past-papers/",
                },
              ],
            },
            {
              url: "/gcse/history/edexcel/18/",
              title: "Edexcel",
              subtitle: "Last exams 2025",
              links: [
                {
                  title: "Revision Notes",
                  url: "/gcse/history/edexcel/18/revision-notes/",
                },
                {
                  title: "Flashcards",
                  url: "/gcse/history/edexcel/18/flashcards/",
                },
                {
                  title: "Past Papers",
                  url: "/gcse/history/edexcel/past-papers/",
                },
              ],
            },
            {
              url: "/gcse/history/edexcel/24/",
              title: "Edexcel",
              subtitle: "First exams 2026",
              links: [
                {
                  title: "The Thematic & Historic Environment",
                  url: "/gcse/history/edexcel/24/the-thematic-historic-environment-paper-1/revision-notes/",
                },
                {
                  title: "Period Study",
                  url: "/gcse/history/edexcel/24/period-study-paper-2-booklet-p/revision-notes/",
                },
                {
                  title: "British Depth Study",
                  url: "/gcse/history/edexcel/24/british-depth-study-paper-2-booklet-b/revision-notes/",
                },
                {
                  title: "Modern Depth Study",
                  url: "/gcse/history/edexcel/24/modern-depth-study-paper-3/revision-notes/",
                },
                {
                  title: "Past Papers",
                  url: "/gcse/history/edexcel/past-papers/",
                },
              ],
            },
            {
              url: "/gcse/history/ocr-gateway/",
              title: "OCR",
              subjectAlias: "History A",
              links: [
                {
                  title: "Past Papers",
                  url: "/gcse/history/ocr-gateway/-/pages/past-papers/",
                },
              ],
              legacyTitle: "OCR Gateway",
            },
            {
              url: "/gcse/history/ocr/b-schools-history-project/",
              title: "OCR",
              subjectAlias: "History B (Schools History Project)",
              links: [
                {
                  title: "Past Papers",
                  url: "/gcse/history/ocr/b-schools-history-project/past-papers/",
                },
              ],
              legacyTitle: "OCR B Schools History Project",
            },
            {
              url: "/gcse/history/wjec/",
              title: "WJEC",
              links: [
                {
                  title: "Past Papers",
                  url: "/gcse/history/wjec/past-papers/",
                },
              ],
            },
          ],
        },
        {
          url: "/gcse/physical-education/",
          title: "Physical Education",
          rights: [
            {
              url: "/gcse/physical-education/aqa/16/",
              links: [
                {
                  url: "/gcse/physical-education/aqa/16/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/physical-education/aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
            },
            {
              url: "/gcse/physical-education/edexcel/",
              links: [
                {
                  url: "/gcse/physical-education/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/gcse/physical-education/ocr/",
              links: [
                {
                  url: "/gcse/physical-education/ocr/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
            },
            {
              url: "/gcse/physical-education/wjec/",
              links: [
                {
                  url: "/gcse/physical-education/wjec/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "WJEC",
            },
          ],
        },
        {
          url: "/gcse/psychology/",
          title: "Psychology",
          rights: [
            {
              url: "/gcse/psychology/aqa/19/",
              title: "AQA",
              links: [
                {
                  title: "Revision Notes",
                  url: "/gcse/psychology/aqa/19/revision-notes/",
                },
                {
                  title: "Past Papers",
                  url: "/gcse/psychology/aqa/-/pages/past-papers/",
                },
              ],
            },
            {
              url: "/gcse/psychology/edexcel/",
              title: "Edexcel",
              links: [
                {
                  title: "Past Papers",
                  url: "/gcse/psychology/edexcel/-/pages/past-papers/",
                },
              ],
            },
            {
              url: "/gcse/psychology/ocr/",
              title: "OCR",
              links: [
                {
                  title: "Past Papers",
                  url: "/gcse/psychology/ocr/-/pages/past-papers/",
                },
              ],
            },
          ],
        },
        {
          url: "/gcse/religious-studies/",
          title: "Religious Studies",
          rights: [
            {
              url: "/gcse/religious-studies/aqa/",
              title: "AQA",
              links: [
                {
                  title: "Revision Notes",
                  url: "/gcse/religious-studies/aqa/a/18/revision-notes/",
                },
                {
                  title: "Past Papers A (8062)",
                  url: "/gcse/religious-studies/aqa/a/past-papers/",
                },
                {
                  title: "Past Papers B (8063)",
                  url: "/gcse/religious-studies_b-8063/aqa/-/pages/past-papers/",
                },
              ],
            },
            {
              url: "/gcse/religious-studies/aqa/short-course/",
              title: "AQA",
              module: { title: "Short Course" },
              links: [
                {
                  title: "Past Papers",
                  url: "/gcse/religious-studies/aqa/short-course/past-papers/",
                },
              ],
            },
            {
              url: "/gcse/religious-studies/edexcel/a/",
              title: "Edexcel",
              subjectAlias: "Religious Studies A",
              links: [
                {
                  title: "Past Papers",
                  url: "/gcse/religious-studies/edexcel/a/past-papers/",
                },
              ],
            },
            {
              url: "/gcse/religious-studies/edexcel/b/16/",
              title: "Edexcel",
              subjectAlias: "Religious Studies B",
              links: [
                {
                  title: "Revision Notes",
                  url: "/gcse/religious-studies/edexcel/b/16/revision-notes/",
                },
                {
                  title: "Past Papers",
                  url: "/gcse/religious-studies/edexcel/b/past-papers/",
                },
              ],
            },
            {
              url: "/gcse/religious-studies/ocr/short-course/",
              title: "OCR",
              module: { title: "Short Course" },
              links: [
                {
                  title: "Past Papers",
                  url: "/gcse/religious-studies/ocr/short-course/past-papers/",
                },
              ],
            },
            {
              url: "/gcse/religious-studies/wjec/",
              title: "WJEC",
              links: [
                {
                  title: "Past Papers",
                  url: "/gcse/religious-studies/wjec/past-papers/",
                },
              ],
            },
            {
              url: "/gcse/religious-studies/wjec-eduqas/",
              title: "WJEC Eduqas",
              links: [
                {
                  title: "Past Papers",
                  url: "/gcse/religious-studies/wjec-eduqas/past-papers/",
                },
              ],
            },
          ],
        },
        {
          url: "/gcse/spanish/",
          title: "Spanish",
          rights: [
            {
              url: "/gcse/spanish/aqa/",
              title: "AQA",
              links: [
                {
                  title: "Past Papers",
                  url: "/gcse/spanish/aqa/-/pages/past-papers/",
                },
              ],
            },
            {
              url: "/gcse/spanish/edexcel/",
              title: "Edexcel",
              links: [
                {
                  title: "Past Papers",
                  url: "/gcse/spanish/edexcel/past-papers/",
                },
              ],
            },
          ],
        },
        {
          title: "Other Subjects",
          alphabetize: true,
          rights: [
            {
              url: "/gcse/citizenship-studies/aqa/",
              links: [
                {
                  url: "/gcse/citizenship-studies/aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
              subjectAlias: "Citizenship Studies",
            },
            {
              url: "/gcse/arabic/edexcel/",
              links: [
                {
                  url: "/gcse/arabic/edexcel/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
              subjectAlias: "Arabic",
            },
            {
              url: "/gcse/art-and-design/edexcel/art-craft-and-design/",
              links: [
                {
                  url: "/gcse/art-and-design/edexcel/art-craft-and-design/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
              subjectAlias: "Art, Craft & Design",
            },
            {
              url: "/gcse/art-and-design/ocr/fine-art/",
              links: [
                {
                  url: "/gcse/art-and-design/ocr/fine-art/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
              subjectAlias: "Art & Design: Fine Art",
            },
            {
              url: "/gcse/citizenship-studies/edexcel/",
              links: [
                {
                  url: "/gcse/citizenship-studies/edexcel/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
              subjectAlias: "Citizenship Studies",
            },
            {
              url: "/gcse/art-and-design/aqa/3d-design/",
              links: [
                {
                  url: "/gcse/art-and-design/aqa/3d-design/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
              subjectAlias: "Art & Design: 3D Design",
            },
            {
              url: "/gcse/art-and-design/aqa/art-craft-and-design/",
              links: [
                {
                  url: "/gcse/art-and-design/aqa/art-craft-and-design/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
              subjectAlias: "Art & Design: Art, Craft & Design",
            },
            {
              url: "/gcse/art-and-design/aqa/fine-art/",
              links: [
                {
                  url: "/gcse/art-and-design/aqa/fine-art/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
              subjectAlias: "Art & Design: Fine Art",
            },
            {
              url: "/gcse/art-and-design/aqa/graphic-communication/",
              links: [
                {
                  url: "/gcse/art-and-design/aqa/graphic-communication/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
              subjectAlias: "Art & Design: Graphic Communication",
            },
            {
              url: "/gcse/art-and-design/aqa/photography/",
              links: [
                {
                  url: "/gcse/art-and-design/aqa/photography/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
              subjectAlias: "Art & Design: Photography",
            },
            {
              url: "/gcse/art-and-design/aqa/textile-design/",
              links: [
                {
                  url: "/gcse/art-and-design/aqa/textile-design/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
              subjectAlias: "Art & Design: Textile Design",
            },
            {
              url: "/gcse/dance/aqa/",
              links: [
                {
                  url: "/gcse/dance/aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
              subjectAlias: "Dance",
            },
            {
              url: "/gcse/design-and-technology/aqa/",
              links: [
                {
                  url: "/gcse/design-and-technology/aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
              subjectAlias: "Design & Technology",
            },
            {
              url: "/gcse/design-and-technology/edexcel/",
              links: [
                {
                  url: "/gcse/design-and-technology/edexcel/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
              subjectAlias: "Design & Technology",
            },
            {
              url: "/gcse/design-and-technology/ocr/",
              links: [
                {
                  url: "/gcse/design-and-technology/ocr/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
              subjectAlias: "Design & Technology",
            },
            {
              url: "/gcse/drama/aqa/",
              links: [
                {
                  url: "/gcse/drama/aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
              subjectAlias: "Drama",
            },
            {
              url: "/gcse/drama/edexcel/",
              links: [
                {
                  url: "/gcse/drama/edexcel/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
              subjectAlias: "Drama",
            },
            {
              url: "/gcse/drama/ocr/",
              links: [
                {
                  url: "/gcse/drama/ocr/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
              subjectAlias: "Drama",
            },
            {
              url: "/gcse/food-and-nutrition/aqa/",
              links: [
                {
                  url: "/gcse/food-and-nutrition/aqa/food-preparation-and-nutrition/16/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/food-and-nutrition/aqa/food-preparation-and-nutrition/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
              subjectAlias: "Food Preparation & Nutrition",
            },
            {
              url: "/gcse/german/aqa/",
              links: [
                {
                  url: "/gcse/german/aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
              subjectAlias: "German",
            },
            {
              url: "/gcse/german/edexcel/",
              links: [
                {
                  url: "/gcse/german/edexcel/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
              subjectAlias: "German",
            },
            {
              url: "/gcse/latin/ocr/",
              links: [
                {
                  url: "/gcse/latin/ocr/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
              subjectAlias: "Latin",
            },
            {
              url: "/gcse/media-studies/aqa/",
              links: [
                {
                  url: "/gcse/media-studies/aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
              subjectAlias: "Media Studies",
            },
            {
              url: "/gcse/music/aqa/",
              links: [
                {
                  url: "/gcse/music/aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
              subjectAlias: "Music",
            },
            {
              url: "/gcse/music/edexcel/",
              links: [
                {
                  url: "/gcse/music/edexcel/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
              subjectAlias: "Music",
            },
            {
              url: "/gcse/music/ocr/",
              links: [
                {
                  url: "/gcse/music/ocr/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
              subjectAlias: "Music",
            },
            {
              url: "/gcse/polish/aqa/",
              links: [
                {
                  url: "/gcse/polish/aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
              subjectAlias: "Polish",
            },
            {
              url: "/gcse/sociology/aqa/",
              links: [
                {
                  url: "/gcse/sociology/aqa/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
              subjectAlias: "Sociology",
            },
            {
              url: "/gcse/ict/wjec/digital-technology/",
              links: [
                {
                  url: "/gcse/ict/wjec/digital-technology/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "WJEC",
              subjectAlias: "Digital Technology",
            },
            {
              url: "/gcse/welsh-language/wjec/",
              links: [
                {
                  url: "/gcse/welsh-language/wjec/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "WJEC",
              subjectAlias: "Welsh Language",
            },
            {
              url: "/gcse/welsh-language/wjec/welsh-second-langauge/",
              links: [
                {
                  url: "/gcse/welsh-language/wjec/welsh-second-langauge/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "WJEC",
              subjectAlias: "Welsh Second Language",
            },
          ],
        },
      ],
      title: "GCSE",
    },
    {
      url: "/igcse/",
      lefts: [
        {
          url: "/igcse/maths/",
          title: "Maths",
          rights: [
            {
              url: "/igcse/maths_core/cie/23/",
              links: [
                {
                  url: "/igcse/maths_core/cie/23/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/igcse/maths_core/cie/23/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/igcse/maths_core/cie/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
              subtitle: "Last Exams 2024",
              module: CORE,
            },
            {
              url: "/igcse/maths_extended/cie/23/",
              links: [
                {
                  url: "/igcse/maths_extended/cie/23/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/igcse/maths_extended/cie/23/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/igcse/maths_extended/cie/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
              subtitle: "Last Exams 2024",
              module: EXTENDED,
            },
            {
              url: "/igcse/maths_core/cie/25/",
              links: [
                {
                  url: "/igcse/maths_core/cie/25/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/igcse/maths_core/cie/25/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/igcse/maths_core/cie/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
              subtitle: "First Exams 2025",
              module: CORE,
            },
            {
              url: "/igcse/maths_extended/cie/25/",
              links: [
                {
                  url: "/igcse/maths_extended/cie/25/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/igcse/maths_extended/cie/25/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/igcse/maths/cie/25/extended/flashcards/",
                  title: "Flashcards",
                },
                {
                  url: "/igcse/maths_extended/cie/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
              module: EXTENDED,
              subtitle: "First Exams 2025",
            },
            {
              url: "/igcse/maths/cie/international-maths/23/core/",
              links: [
                {
                  url: "/igcse/maths/cie/international-maths/23/core/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/igcse/maths/cie/international-maths/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
              subjectAlias: "International Maths",
              module: CORE,
            },
            {
              url: "/igcse/maths/cie/international-maths/23/extended/",
              links: [
                {
                  url: "/igcse/maths/cie/international-maths/23/extended/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/igcse/maths/cie/international-maths/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
              subjectAlias: "International Maths",
              module: EXTENDED,
            },
            {
              url: "/igcse/additional-maths/cie/25/",
              links: [
                {
                  url: "/igcse/additional-maths/cie/25/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/igcse/additional-maths/cie/25/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/igcse/additional-maths/cie/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
              subjectAlias: "Additional Maths",
            },
            {
              url: "/igcse/maths/edexcel/22/",
              links: [
                {
                  url: "/igcse/maths/edexcel/22/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/igcse/maths/edexcel/22/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/igcse/maths/edexcel/a/18/higher/flashcards/",
                  title: "Flashcards",
                },
                {
                  url: "/igcse/maths/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
              subjectAlias: "Maths A",
              legacyTitle: "Edexcel",
            },
            {
              url: "/igcse/maths/edexcel/a-modular/24/",
              links: [
                {
                  url: "/igcse/maths/edexcel/a-modular/24/higher-unit-1/",
                  title: "Higher Unit 1",
                },
                {
                  url: "/igcse/maths/edexcel/a-modular/24/higher-unit-2/",
                  title: "Higher Unit 2",
                },
                {
                  url: "/igcse/maths/edexcel/a-modular/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
              subjectAlias: "Maths A",
              module: {
                title: "Modular",
              },
            },
            {
              url: "/igcse/maths/edexcel/b/",
              links: [
                {
                  url: "/igcse/maths/edexcel/b/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
              subjectAlias: "Maths B",
            },
            {
              url: "/igcse/further-maths/edexcel/19/",
              links: [
                {
                  url: "/igcse/further-maths/edexcel/19/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/igcse/further-maths/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
              subjectAlias: "Further Maths",
            },
            {
              url: "/igcse/maths/oxford-aqa/",
              links: [
                {
                  url: "/igcse/maths/oxford-aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Oxford AQA",
            },
          ],
        },
        {
          url: "/igcse/biology/",
          title: "Biology",
          rights: [
            {
              url: "/igcse/biology/cie/23/",
              links: [
                {
                  url: "/igcse/biology/cie/23/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/igcse/biology/cie/23/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/igcse/biology/cie/23/flashcards/",
                  title: "Flashcards",
                },
                {
                  url: "/igcse/biology/cie/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
            },
            {
              url: "/igcse/biology/edexcel/19/",
              links: [
                {
                  url: "/igcse/biology/edexcel/19/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/igcse/biology/edexcel/19/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/igcse/biology/edexcel/19/flashcards/",
                  title: "Flashcards",
                },
                {
                  url: "/igcse/biology/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/igcse/biology/oxford-aqa/",
              links: [
                {
                  url: "/igcse/biology/oxford-aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Oxford AQA",
            },
          ],
        },
        {
          url: "/igcse/chemistry/",
          title: "Chemistry",
          rights: [
            {
              url: "/igcse/chemistry/cie/23/",
              links: [
                {
                  url: "/igcse/chemistry/cie/23/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/igcse/chemistry/cie/23/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/igcse/chemistry/cie/23/flashcards/",
                  title: "Flashcards",
                },
                {
                  url: "/igcse/chemistry/cie/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
            },
            {
              url: "/igcse/chemistry/edexcel/19/",
              links: [
                {
                  url: "/igcse/chemistry/edexcel/19/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/igcse/chemistry/edexcel/19/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/igcse/chemistry/edexcel/19/flashcards/",
                  title: "Flashcards",
                },
                {
                  url: "/igcse/chemistry/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/igcse/chemistry/oxford-aqa/16/",
              links: [
                {
                  url: "/igcse/chemistry/oxford-aqa/16/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/igcse/chemistry/oxford-aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Oxford AQA",
            },
          ],
        },
        {
          url: "/igcse/physics/",
          title: "Physics",
          rights: [
            {
              url: "/igcse/physics/cie/23/",
              links: [
                {
                  url: "/igcse/physics/cie/23/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/igcse/physics/cie/23/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/igcse/physics/cie/23/flashcards/",
                  title: "Flashcards",
                },
                {
                  url: "/igcse/physics/cie/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
            },
            {
              url: "/igcse/physics/edexcel/19/",
              links: [
                {
                  url: "/igcse/physics/edexcel/19/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/igcse/physics/edexcel/19/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/igcse/physics/edexcel/19/flashcards/",
                  title: "Flashcards",
                },
                {
                  url: "/igcse/physics/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/igcse/physics/oxford-aqa/16/",
              links: [
                {
                  url: "/igcse/physics/oxford-aqa/16/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/igcse/physics/oxford-aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Oxford AQA",
            },
          ],
        },
        {
          title: "Double Science",
          rights: [
            {
              url: "/igcse/biology_co-ordinated-sciences-double-award/cie/23/",
              links: [
                {
                  url: "/igcse/biology_co-ordinated-sciences-double-award/cie/23/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/igcse/biology_co-ordinated-sciences-double-award/cie/23/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/igcse/science/cie/co-ordinated-sciences-double-award/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
              subjectAlias: "Biology",
              subtitle: "Co-ordinated Sciences (Double Award)",
              legacyTitle: "CIE Biology: Co-ordinated Sciences (Double Award)",
            },
            {
              url: "/igcse/chemistry_co-ordinated-sciences-double-award/cie/23/",
              links: [
                {
                  url: "/igcse/chemistry_co-ordinated-sciences-double-award/cie/23/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/igcse/chemistry_co-ordinated-sciences-double-award/cie/23/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/igcse/science/cie/co-ordinated-sciences-double-award/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
              subjectAlias: "Chemistry",
              subtitle: "Co-ordinated Sciences (Double Award)",
              legacyTitle:
                "CIE Chemistry: Co-ordinated Sciences (Double Award)",
            },
            {
              url: "/igcse/biology_double-science/edexcel/19/",
              links: [
                {
                  url: "/igcse/biology_double-science/edexcel/19/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/igcse/biology_double-science/edexcel/19/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/igcse/biology_double-science/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
              subjectAlias: "Biology",
              subtitle: "Science (Double Award)",
              legacyTitle: "Edexcel Double: Biology",
            },
            {
              url: "/igcse/chemistry_double-science/edexcel/19/",
              links: [
                {
                  url: "/igcse/chemistry_double-science/edexcel/19/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/igcse/chemistry_double-science/edexcel/19/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/igcse/chemistry_double-science/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
              subjectAlias: "Chemistry",
              subtitle: "Science (Double Award)",
              legacyTitle: "Edexcel Double: Chemistry",
            },
            {
              url: "/igcse/physics_double-science/edexcel/19/",
              links: [
                {
                  url: "/igcse/physics_double-science/edexcel/19/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/igcse/physics_double-science/edexcel/19/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/igcse/physics_double-science/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
              subjectAlias: "Physics",
              subtitle: "Science (Double Award)",
              legacyTitle: "Edexcel Double: Physics",
            },
            {
              url: "/igcse/science/oxford-aqa/combined-science-double-award/16/biology/",
              title: "Oxford AQA",
              subjectAlias: "Biology",
              subtitle: "Combined Science (Double Award)",
              legacyTitle:
                "Oxford AQA Biology: Combined Science (Double Award)",
              links: [
                {
                  url: "/igcse/science/oxford-aqa/combined-science-double-award/past-papers/",
                  title: "Past Papers",
                },
              ],
            },
            {
              url: "/igcse/science/oxford-aqa/combined-science-double-award/16/physics/",
              title: "Oxford AQA",
              subjectAlias: "Physics",
              subtitle: "Combined Science (Double Award)",
              legacyTitle:
                "Oxford AQA Physics: Combined Science (Double Award)",
              links: [
                {
                  url: "/igcse/science/oxford-aqa/combined-science-double-award/16/physics/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/igcse/science/oxford-aqa/combined-science-double-award/past-papers/",
                  title: "Past Papers",
                },
              ],
            },
            {
              url: "/igcse/science/oxford-aqa/combined-science-double-award/16/chemistry/",
              title: "Oxford AQA",
              subjectAlias: "Chemistry",
              subtitle: "Combined Science (Double Award)",
              legacyTitle:
                "Oxford AQA Chemistry: Combined Science (Double Award)",
              links: [
                {
                  url: "/igcse/science/oxford-aqa/combined-science-double-award/16/chemistry/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/igcse/science/oxford-aqa/combined-science-double-award/past-papers/",
                  title: "Past Papers",
                },
              ],
            },
          ],
        },
        {
          url: "/igcse/accounting/",
          title: "Accounting",
          rights: [
            {
              url: "/igcse/accounting/cie/21/",
              links: [
                {
                  url: "/igcse/accounting/cie/21/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/igcse/accounting/cie/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
            },
            {
              url: "/igcse/accounting/edexcel/17/",
              links: [
                {
                  url: "/igcse/accounting/edexcel/17/introduction-to-bookkeeping-and-accounting/revision-notes/",
                  title: "Introduction to Bookkeeping & Accounting",
                },
                {
                  url: "/igcse/accounting/edexcel/17/financial-statements/revision-notes/",
                  title: "Financial Statements",
                },
                {
                  url: "/igcse/accounting/edexcel/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
          ],
        },
        {
          url: "/igcse/business/",
          title: "Business",
          rights: [
            {
              url: "/igcse/business/cie/23/",
              links: [
                {
                  url: "/igcse/business/cie/23/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/igcse/business/cie/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
            },
            {
              url: "/igcse/business/edexcel/19/",
              links: [
                {
                  url: "/igcse/business/edexcel/19/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/igcse/business/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/igcse/business/oxford-aqa/",
              links: [
                {
                  url: "/igcse/business/oxford-aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Oxford AQA",
            },
          ],
        },
        {
          url: "/igcse/computer-science/",
          title: "Computer Science",
          rights: [
            {
              url: "/igcse/computer-science/cie/23/",
              links: [
                {
                  url: "/igcse/computer-science/cie/23/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/igcse/computer-science/cie/23/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/igcse/computer-science/cie/23/flashcards/",
                  title: "Flashcards",
                },
                {
                  url: "/igcse/computer-science/cie/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
            },
            {
              url: "/igcse/computer-science/edexcel/",
              links: [
                {
                  url: "/igcse/computer-science/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
          ],
        },
        {
          url: "/igcse/economics/",
          title: "Economics",
          rights: [
            {
              url: "/igcse/economics/cie/20/",
              links: [
                {
                  url: "/igcse/economics/cie/20/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/igcse/economics/cie/20/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/igcse/economics/cie/20/flashcards/",
                  title: "Flashcards",
                },
                {
                  url: "/igcse/economics/cie/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
            },
            {
              url: "/igcse/economics/edexcel/17/",
              links: [
                {
                  url: "/igcse/economics/edexcel/17/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/igcse/economics/edexcel/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
          ],
        },
        {
          url: "/igcse/english-language/",
          title: "English Language",
          rights: [
            {
              url: "/igcse/english-language/cie/20/",
              links: [
                {
                  url: "/igcse/english-language/cie/20/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/igcse/english-language/cie/20/practice-paper-questions/",
                  title: "Practice Papers",
                },
                {
                  url: "/igcse/english-language/cie/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
            },
            {
              url: "/igcse/english/cie/first-language-english-us/",
              links: [
                {
                  url: "/igcse/english/cie/first-language-english-us/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
              subjectAlias: "First Language English (US)",
            },
            {
              url: "/igcse/english/cie/as-a-second-language-speaking-endorsement/",
              links: [
                {
                  url: "/igcse/english/cie/as-a-second-language-speaking-endorsement/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
              subjectAlias:
                "English as a Second Language (Speaking Endorsement)",
            },
            {
              url: "/igcse/english-language/edexcel/a/16/",
              links: [
                {
                  url: "/igcse/english-language/edexcel/a/16/paper-1-non-fiction-texts-and-transactional-writing/",
                  title:
                    "Paper 1 (Non-fiction Texts and Transactional Writing)",
                },
                {
                  url: "/igcse/english-language/edexcel/a/16/paper-2-poetry-and-prose-texts-and-imaginative-writing/",
                  title:
                    "Paper 2 (Poetry and Prose Texts and Imaginative Writing)",
                },
                {
                  url: "/igcse/english-language/edexcel/a/16/paper-3-coursework/",
                  title: "Paper 3 (Coursework)",
                },
              ],
              title: "Edexcel",
              subjectAlias: "English Language A",
            },
            {
              url: "/igcse/english-language/edexcel/b/",
              links: [
                {
                  url: "/igcse/english-language/edexcel/b/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
              subjectAlias: "English Language B",
            },
            {
              url: "/igcse/english/edexcel/as-a-second-language/",
              links: [
                {
                  url: "/igcse/english/edexcel/as-a-second-language/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
              subjectAlias: "English as a Second Language",
            },
            {
              url: "/igcse/english-language/oxford-aqa/",
              links: [
                {
                  url: "/igcse/english-language/oxford-aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Oxford AQA",
            },
          ],
        },
        {
          url: "/igcse/english-literature/",
          title: "English Literature",
          rights: [
            {
              url: "/igcse/english-literature/cie/21/",
              links: [
                {
                  url: "/igcse/english-literature/cie/21/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/igcse/english-literature/cie/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
            },
            {
              url: "/igcse/english-literature/cie/us/",
              links: [
                {
                  url: "/igcse/english-literature/cie/us/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
              subjectAlias: "Literature (English) (US)",
            },
            {
              url: "/igcse/english-literature/edexcel/16/",
              links: [
                {
                  url: "/igcse/english-literature/edexcel/16/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/igcse/english-literature/edexcel/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/igcse/english-literature/oxford-aqa/",
              links: [
                {
                  url: "/igcse/english-literature/oxford-aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Oxford AQA",
            },
          ],
        },
        {
          url: "/igcse/french/",
          title: "French",
          rights: [
            {
              url: "/igcse/french/cie/first-language/",
              links: [
                {
                  url: "/igcse/french/cie/first-language/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
            },
            {
              url: "/igcse/french/edexcel/",
              links: [
                {
                  url: "/igcse/french/edexcel/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
          ],
        },
        {
          url: "/igcse/geography/",
          title: "Geography",
          rights: [
            {
              url: "/igcse/geography/cie/20/",
              links: [
                {
                  url: "/igcse/geography/cie/20/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/igcse/geography/cie/20/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/igcse/geography/cie/20/flashcards/",
                  title: "Flashcards",
                },
                {
                  url: "/igcse/geography/cie/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
            },
            {
              url: "/igcse/geography/edexcel/19/",
              links: [
                {
                  url: "/igcse/geography/edexcel/19/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/igcse/geography/edexcel/19/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/igcse/geography/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/igcse/geography/oxford-aqa/",
              links: [
                {
                  url: "/igcse/geography/oxford-aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Oxford AQA",
            },
          ],
        },
        {
          url: "/igcse/german/",
          title: "German",
          rights: [
            {
              url: "/igcse/german/cie/first-language/",
              links: [
                {
                  url: "/igcse/german/cie/first-language/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
            },
            {
              url: "/igcse/german/edexcel/",
              links: [
                {
                  url: "/igcse/german/edexcel/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
          ],
        },
        {
          url: "/igcse/history/",
          title: "History",
          rights: [
            {
              url: "/igcse/history/cie/18/",
              links: [
                {
                  url: "/igcse/history/cie/18/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/igcse/history/cie/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
            },
            {
              url: "/igcse/history/edexcel/",
              links: [
                {
                  url: "/igcse/history/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
          ],
        },
        {
          url: "/igcse/ict/",
          title: "ICT",
          rights: [
            {
              url: "/igcse/ict/cie/21/",
              links: [
                {
                  url: "/igcse/ict/cie/21/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/igcse/ict/cie/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
            },
            {
              url: "/igcse/ict/edexcel/17/",
              links: [
                {
                  url: "/igcse/ict/edexcel/17/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/igcse/ict/edexcel/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
          ],
        },
        {
          url: "/igcse/religious-studies/",
          title: "Religious Studies",
          rights: [
            {
              url: "/igcse/religious-studies/cie/",
              links: [
                {
                  url: "/igcse/religious-studies/cie/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
            },
            {
              url: "/igcse/religious-studies/edexcel/",
              links: [
                {
                  url: "/igcse/religious-studies/edexcel/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
          ],
        },
        {
          url: "/igcse/spanish/",
          title: "Spanish",
          rights: [
            {
              url: "/igcse/spanish/cie/first-language/",
              links: [
                {
                  url: "/igcse/spanish/cie/first-language/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
            },
            {
              url: "/igcse/spanish/edexcel/",
              links: [
                {
                  url: "/igcse/spanish/edexcel/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
          ],
        },
        {
          title: "Other Subjects",
          alphabetize: true,
          rights: [
            {
              url: "/igcse/chinese/edexcel/",
              links: [
                {
                  url: "/igcse/chinese/edexcel/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
              subjectAlias: "Chinese",
            },
            {
              url: "/igcse/marine-science/cie/",
              links: [
                {
                  url: "/igcse/marine-science/cie/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
              subjectAlias: "Marine Science",
            },
            {
              url: "/igcse/sociology/cie/",
              links: [
                {
                  url: "/igcse/sociology/cie/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
              subjectAlias: "Sociology",
            },
          ],
        },
      ],
      title: "IGCSE",
    },
    {
      url: "/as/",
      lefts: [
        {
          url: "/as/maths/",
          title: "Maths",
          rights: [
            {
              url: "/as/maths/aqa/",
              links: [
                {
                  url: "/as/maths_pure/aqa/18/",
                  title: "Pure Maths",
                },
                {
                  url: "/as/maths_mechanics/aqa/18/",
                  title: "Mechanics",
                },
                {
                  url: "/as/maths_statistics/aqa/18/",
                  title: "Statistics",
                },
              ],
              title: "AQA",
            },
            {
              url: "/as/maths/edexcel/",
              links: [
                {
                  url: "/as/maths_pure/edexcel/18/",
                  title: "Pure Maths",
                },
                {
                  url: "/as/maths_mechanics/edexcel/18/",
                  title: "Mechanics",
                },
                {
                  url: "/as/maths_statistics/edexcel/18/",
                  title: "Statistics",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/as/maths/ocr/",
              links: [
                {
                  url: "/as/maths_pure/ocr/18/",
                  title: "Pure Maths",
                },
                {
                  url: "/as/maths_mechanics/ocr/18/",
                  title: "Mechanics",
                },
                {
                  url: "/as/maths_statistics/ocr/18/",
                  title: "Statistics",
                },
              ],
              title: "OCR",
            },
            {
              url: "/as/maths/cie/",
              links: [
                {
                  url: "/as/maths_pure-1/cie/20/",
                  title: "Pure 1",
                },
                {
                  url: "/as/maths_pure-2/cie/20/",
                  title: "Pure 2",
                },
                {
                  url: "/as/maths_mechanics/cie/20/",
                  title: "Mechanics",
                },
                {
                  url: "/as/maths_probability--statistics-1/cie/20/",
                  title: "Probability & Statistics 1",
                },
                {
                  url: "/as/maths_pure-1/cie/20/practice-paper-questions/",
                  title: "Practice Papers",
                },
              ],
              title: "CIE",
            },
            {
              url: "/international-as/maths/edexcel/",
              links: [
                {
                  url: "/international-as/maths_pure-1/edexcel/19/",
                  title: "Pure 1",
                },
                {
                  url: "/international-as/maths_pure-2/edexcel/19/",
                  title: "Pure 2",
                },
                {
                  url: "/international-as/maths_mechanics-1/edexcel/19/",
                  title: "Mechanics 1",
                },
                {
                  url: "/international-as/maths_statistics-1/edexcel/19/",
                  title: "Statistics 1",
                },
                {
                  url: "/international-as/maths_decision-1/edexcel/19/",
                  title: "Decision 1",
                },
                {
                  url: "/international-as/maths_pure-1/edexcel/19/practice-paper-questions/",
                  title: "Practice Papers",
                },
              ],
              title: "Edexcel IAS",
            },
            {
              url: "/international-as/maths/oxford-aqa/",
              links: [
                {
                  url: "/international-as/maths/oxford-aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Oxford AQA IAS",
            },
          ],
        },
        {
          url: "/as/biology/",
          title: "Biology",
          rights: [
            {
              url: "/as/biology/aqa/16/",
              links: [
                {
                  url: "/as/biology/aqa/16/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/as/biology/aqa/16/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/as/biology/aqa/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
            },
            {
              url: "/as/biology/edexcel-a-snab/15/",
              links: [
                {
                  url: "/as/biology/edexcel-a-snab/15/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/as/biology/edexcel-a-snab/15/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/as/biology/edexcel-a-snab/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
              legacyTitle: "Edexcel A (SNAB)",
              subjectAlias: "Biology A (SNAB)",
            },
            {
              url: "/as/biology/ocr/16/",
              links: [
                {
                  url: "/as/biology/ocr/16/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/as/biology/ocr/16/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/as/biology/ocr/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
            },
            {
              url: "/as/biology/cie/22/",
              links: [
                {
                  url: "/as/biology/cie/22/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/as/biology/cie/22/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/as/biology/cie/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
              subtitle: "Last exams 2024",
            },
            {
              url: "/as/biology/cie/25/",
              links: [
                {
                  url: "/as/biology/cie/25/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/as/biology/cie/25/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/as/biology/cie/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
              subtitle: "First exams 2025",
            },
            {
              url: "/international-as/biology/edexcel/18/",
              links: [
                {
                  url: "/international-as/biology/edexcel/18/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/international-as/biology/edexcel/18/revision-notes/",
                  title: "Revision Notes",
                },
              ],
              title: "Edexcel IAS",
            },
            {
              url: "/international-as/biology/oxford-aqa/",
              links: [
                {
                  url: "/international-as/biology/oxford-aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Oxford AQA IAL",
            },
          ],
        },
        {
          url: "/as/chemistry/",
          title: "Chemistry",
          rights: [
            {
              url: "/as/chemistry/aqa/16/",
              links: [
                {
                  url: "/as/chemistry/aqa/16/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/as/chemistry/aqa/16/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/as/chemistry/aqa/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
            },
            {
              url: "/as/chemistry/edexcel/16/",
              links: [
                {
                  url: "/as/chemistry/edexcel/16/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/as/chemistry/edexcel/16/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/as/chemistry/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/as/chemistry/ocr/17/",
              links: [
                {
                  url: "/as/chemistry/ocr/17/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/as/chemistry/ocr/17/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/as/chemistry/ocr/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
            },
            {
              url: "/as/chemistry/cie/22/",
              links: [
                {
                  url: "/as/chemistry/cie/22/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/as/chemistry/cie/22/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/as/chemistry/cie/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
              subtitle: "Last exams 2024",
            },
            {
              url: "/as/chemistry/cie/25/",
              links: [
                {
                  url: "/as/chemistry/cie/25/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/as/chemistry/cie/25/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/as/chemistry/cie/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
              subtitle: "First exams 2025",
            },
            {
              url: "/international-as/chemistry/edexcel/17/",
              links: [
                {
                  url: "/international-as/chemistry/edexcel/17/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/international-as/chemistry/edexcel/17/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/international-as/chemistry/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel IAS",
            },
          ],
        },
        {
          url: "/as/physics/",
          title: "Physics",
          rights: [
            {
              url: "/as/physics/aqa/16/",
              links: [
                {
                  url: "/as/physics/aqa/16/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/as/physics/aqa/16/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/as/physics/aqa/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
            },
            {
              url: "/as/physics/edexcel/16/",
              links: [
                {
                  url: "/as/physics/edexcel/16/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/as/physics/edexcel/16/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/as/physics/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/as/physics/ocr/18/",
              links: [
                {
                  url: "/as/physics/ocr/18/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/as/physics/ocr/18/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/as/physics/ocr/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
            },
            {
              url: "/as/physics/cie/22/",
              links: [
                {
                  url: "/as/physics/cie/22/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/as/physics/cie/22/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/as/physics/cie/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
              subtitle: "Last exams 2024",
            },
            {
              url: "/as/physics/cie/25/",
              links: [
                {
                  url: "/as/physics/cie/25/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/as/physics/cie/25/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/as/physics/cie/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
              subtitle: "First exams 2025",
            },
            {
              url: "/international-as/physics/edexcel/19/",
              links: [
                {
                  url: "/international-as/physics/edexcel/19/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/international-as/physics/edexcel/19/revision-notes/",
                  title: "Revision Notes",
                },
              ],
              title: "Edexcel IAS",
            },
            {
              url: "/international-as/physics/oxford-aqa/",
              links: [
                {
                  url: "/international-as/physics/oxford-aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Oxford AQA IAS",
            },
          ],
        },
        {
          url: "/as/business/",
          title: "Business",
          rights: [
            {
              url: "/as/business/aqa/",
              links: [
                {
                  url: "/as/business/aqa/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
            },
            {
              url: "/as/business/edexcel/",
              links: [
                {
                  url: "/as/business/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/as/business/ocr/",
              links: [
                {
                  url: "/as/business/ocr/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
            },
            {
              url: "/international-as/business/oxford-aqa/",
              links: [
                {
                  url: "/international-as/business/oxford-aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Oxford AQA IAS",
            },
          ],
        },
        {
          url: "/as/computer-science/",
          title: "Computer Science",
          rights: [
            {
              url: "/as/computer-science/aqa/",
              links: [
                {
                  url: "/as/computer-science/aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
            },
            {
              url: "/as/computer-science/ocr/",
              links: [
                {
                  url: "/as/computer-science/ocr/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
            },
          ],
        },
        {
          url: "/as/economics/",
          title: "Economics",
          rights: [
            {
              url: "/as/economics/aqa/",
              links: [
                {
                  url: "/as/economics/aqa/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
            },
            {
              url: "/as/economics/edexcel/",
              links: [
                {
                  url: "/as/economics/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/as/economics/ocr/",
              links: [
                {
                  url: "/as/economics/ocr/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
            },
            {
              url: "/international-as/economics/oxford-aqa/",
              links: [
                {
                  url: "/international-as/economics/oxford-aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Oxford AQA IAS",
            },
          ],
        },
        {
          url: "/as/english/",
          title: "English",
          rights: [
            {
              url: "/as/english/cie/general-paper/",
              links: [
                {
                  url: "/as/english/cie/general-paper/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
              subjectAlias: "English General Paper",
            },
          ],
        },
        {
          url: "/as/english-language/",
          title: "English Language",
          rights: [
            {
              url: "/as/english-language/aqa/",
              links: [
                {
                  url: "/as/english-language/aqa/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
            },
            {
              url: "/as/english-language/edexcel/",
              links: [
                {
                  url: "/as/english-language/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/as/english-language/ocr/",
              links: [
                {
                  url: "/as/english-language/ocr/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
            },
            {
              url: "/international-as/english-language/oxford-aqa/",
              links: [
                {
                  url: "/international-as/english-language/oxford-aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Oxford AQA IAS",
            },
          ],
        },
        {
          url: "/as/english-literature/",
          title: "English Literature",
          rights: [
            {
              url: "/as/english-literature/aqa/",
              links: [
                {
                  url: "/as/english-literature/aqa/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
            },
            {
              url: "/as/english-literature/edexcel/",
              links: [
                {
                  url: "/as/english-literature/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/as/english-literature/ocr/",
              links: [
                {
                  url: "/as/english-literature/ocr/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
            },
            {
              url: "/international-as/english-literature/oxford-aqa/",
              links: [
                {
                  url: "/international-as/english-literature/oxford-aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Oxford AQA IAS",
            },
          ],
        },
        {
          url: "/as/french/",
          title: "French",
          rights: [
            {
              url: "/as/french/aqa/",
              links: [
                {
                  url: "/as/french/aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
            },
          ],
        },
        {
          url: "/as/further-maths/",
          title: "Further Maths",
          rights: [
            {
              url: "/as/further-maths/aqa/",
              links: [
                {
                  url: "/as/further-maths/aqa/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
            },
            {
              url: "/as/further-maths/edexcel/",
              links: [
                {
                  url: "/as/further-maths/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/as/further-maths/ocr/",
              links: [
                {
                  url: "/as/further-maths/ocr/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
            },
            {
              url: "/as/further-maths/cie/",
              links: [
                {
                  url: "/as/further-maths/cie/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
            },
            {
              url: "/international-as/further-maths/edexcel/",
              links: [
                {
                  url: "/international-as/further-maths/edexcel/18/further-pure-1/",
                  title: "Further Pure 1",
                },
                {
                  url: "/international-as/further-maths/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel IAS",
            },
            {
              url: "/international-as/further-maths/oxford-aqa/",
              links: [
                {
                  url: "/international-as/further-maths/oxford-aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Oxford AQA IAS",
            },
          ],
        },
        {
          url: "/as/geography/",
          title: "Geography",
          rights: [
            {
              url: "/as/geography/aqa/",
              links: [
                {
                  url: "/as/geography/aqa/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
            },
            {
              url: "/as/geography/edexcel/",
              links: [
                {
                  url: "/as/geography/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/as/geography/ocr/",
              links: [
                {
                  url: "/as/geography/ocr/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
            },
            {
              url: "/international-as/geography/oxford-aqa/",
              links: [
                {
                  url: "/international-as/geography/oxford-aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Oxford AQA IAS",
            },
          ],
        },
        {
          url: "/as/history/",
          title: "History",
          rights: [
            {
              url: "/as/history/aqa/",
              links: [
                {
                  url: "/as/history/aqa/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
            },
            {
              url: "/as/history/edexcel/",
              links: [
                {
                  url: "/as/history/edexcel/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/as/history/ocr/",
              links: [
                {
                  url: "/as/history/ocr/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
            },
          ],
        },
        {
          url: "/as/psychology/",
          title: "Psychology",
          rights: [
            {
              url: "/as/psychology/aqa/",
              links: [
                {
                  url: "/as/psychology/aqa/15/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/as/psychology/aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
            },
            {
              url: "/as/psychology/edexcel/",
              links: [
                {
                  url: "/as/psychology/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/as/psychology/ocr/",
              links: [
                {
                  url: "/as/psychology/ocr/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
            },
            {
              url: "/international-as/psychology/oxford-aqa/",
              links: [
                {
                  url: "/international-as/psychology/oxford-aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Oxford AQA IAS",
            },
          ],
        },
        {
          url: "/as/religious-studies/",
          title: "Religious Studies",
          rights: [
            {
              url: "/as/religious-studies/edexcel/",
              links: [
                {
                  url: "/as/religious-studies/edexcel/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/as/religious-studies/ocr/",
              links: [
                {
                  url: "/as/religious-studies/ocr/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
            },
          ],
        },
        {
          title: "Other Subjects",
          alphabetize: true,
          rights: [
            {
              url: "/as/german/aqa/",
              links: [
                {
                  url: "/as/german/aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
              subjectAlias: "German",
            },
            {
              url: "/as/sociology/aqa/",
              links: [
                {
                  url: "/as/sociology/aqa/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
              subjectAlias: "Sociology",
            },
            {
              url: "/as/environmental-management/cie/20/",
              links: [
                {
                  url: "/as/environmental-management/cie/20/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/as/environmental-management/cie/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
              subjectAlias: "Environmental Management",
            },
            {
              url: "/as/sociology/ocr/",
              links: [
                {
                  url: "/as/sociology/ocr/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
              subjectAlias: "Sociology",
            },
          ],
        },
      ],
      title: "AS",
    },
    {
      url: "/a-level/",
      lefts: [
        {
          url: "/a-level/maths/",
          title: "Maths",
          rights: [
            {
              url: "/a-level/maths/aqa/",
              links: [
                {
                  url: "/a-level/maths_pure/aqa/18/",
                  title: "Pure Maths",
                },
                {
                  url: "/a-level/maths_mechanics/aqa/18/",
                  title: "Mechanics",
                },
                {
                  url: "/a-level/maths_statistics/aqa/18/",
                  title: "Statistics",
                },
                {
                  url: "/a-level/maths_pure/aqa/18/practice-paper-questions/",
                  title: "Practice Papers",
                },
              ],
              title: "AQA",
            },
            {
              url: "/a-level/maths/edexcel/",
              links: [
                {
                  url: "/a-level/maths_pure/edexcel/18/",
                  title: "Pure Maths",
                },
                {
                  url: "/a-level/maths_mechanics/edexcel/18/",
                  title: "Mechanics",
                },
                {
                  url: "/a-level/maths_statistics/edexcel/18/",
                  title: "Statistics",
                },
                {
                  url: "/a-level/maths_pure/edexcel/18/practice-paper-questions/",
                  title: "Practice Papers",
                },
                {
                  url: "/a-level/maths/edexcel/solution-banks/",
                  title: "Solution Banks",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/a-level/maths/ocr/",
              links: [
                {
                  url: "/a-level/maths_pure/ocr/18/",
                  title: "Pure Maths",
                },
                {
                  url: "/a-level/maths_mechanics/ocr/18/",
                  title: "Mechanics",
                },
                {
                  url: "/a-level/maths_statistics/ocr/18/",
                  title: "Statistics",
                },
                {
                  url: "/a-level/maths_pure/ocr/18/practice-paper-questions/",
                  title: "Practice Papers",
                },
              ],
              legacyTitle: "OCR",
              title: "OCR",
              subjectAlias: "Maths A",
            },
            {
              url: "/a-level/maths/ocr/b-mei/",
              links: [
                {
                  url: "/a-level/maths/ocr/b-mei/past-papers/",
                  title: "Past Papers",
                },
              ],
              legacyTitle: "OCR (MEI)",
              title: "OCR",
              subjectAlias: "Maths B (MEI)",
            },
            {
              url: "/a-level/maths/cie/",
              links: [
                {
                  url: "/a-level/maths_pure-1/cie/20/",
                  title: "Pure 1",
                },
                {
                  url: "/a-level/maths_pure-3/cie/20/",
                  title: "Pure 3",
                },
                {
                  url: "/a-level/maths_mechanics/cie/20/",
                  title: "Mechanics",
                },
                {
                  url: "/a-level/maths_probability--statistics-1/cie/20/",
                  title: "Probability & Statistics 1",
                },
                {
                  url: "/a-level/maths_probability--statistics-2/cie/20/",
                  title: "Probability & Statistics 2",
                },
                {
                  url: "/a-level/maths_pure-1/cie/20/practice-paper-questions/",
                  title: "Practice Papers",
                },
              ],
              title: "CIE",
            },
            {
              url: "/international-a-level/maths/edexcel/",
              links: [
                {
                  url: "/international-a-level/maths_pure-1/edexcel/20/",
                  title: "Pure 1",
                },
                {
                  url: "/international-a-level/maths_pure-2/edexcel/20/",
                  title: "Pure 2",
                },
                {
                  url: "/international-a-level/maths_pure-3/edexcel/20/",
                  title: "Pure 3",
                },
                {
                  url: "/international-a-level/maths_pure-4/edexcel/20/",
                  title: "Pure 4",
                },
                {
                  url: "/international-a-level/maths_mechanics-1/edexcel/20/",
                  title: "Mechanics 1",
                },
                {
                  url: "/international-a-level/maths_mechanics-2/edexcel/20/",
                  title: "Mechanics 2",
                },
                {
                  url: "/international-a-level/maths_statistics-1/edexcel/20/",
                  title: "Statistics 1",
                },
                {
                  url: "/international-a-level/maths_statistics-2/edexcel/20/",
                  title: "Statistics 2",
                },
                {
                  url: "/international-a-level/maths_decision-1/edexcel/20/",
                  title: "Decision 1",
                },
                {
                  url: "/international-a-level/maths_pure-1/edexcel/20/practice-paper-questions/",
                  title: "Practice Papers",
                },
              ],
              title: "Edexcel IAL",
            },
            {
              url: "/international-a-level/maths/oxford-aqa/",
              links: [
                {
                  url: "/international-a-level/maths/oxford-aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Oxford AQA IAL",
            },
          ],
        },
        {
          url: "/a-level/biology/",
          title: "Biology",
          rights: [
            {
              url: "/a-level/biology/aqa/17/",
              links: [
                {
                  url: "/a-level/biology/aqa/17/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/a-level/biology/aqa/17/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/a-level/biology/aqa/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
            },
            {
              url: "/a-level/biology/edexcel-a-snab/15/",
              links: [
                {
                  url: "/a-level/biology/edexcel-a-snab/15/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/a-level/biology/edexcel-a-snab/15/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/a-level/biology/edexcel-a-snab/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              legacyTitle: "Edexcel A (SNAB)",
              title: "Edexcel",
              subjectAlias: "Biology A (SNAB)",
            },
            {
              url: "/a-level/biology/edexcel/2018/",
              links: [
                {
                  url: "/a-level/biology/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              legacyTitle: "Edexcel",
              title: "Edexcel",
              subjectAlias: "Biology B",
            },
            {
              url: "/a-level/biology/ocr/17/",
              links: [
                {
                  url: "/a-level/biology/ocr/17/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/a-level/biology/ocr/17/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/a-level/biology/ocr/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
            },
            {
              url: "/a-level/biology/cie/22/",
              links: [
                {
                  url: "/a-level/biology/cie/22/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/a-level/biology/cie/22/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/a-level/biology/cie/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
              subtitle: "Last exams 2024",
            },
            {
              url: "/a-level/biology/cie/25/",
              links: [
                {
                  url: "/a-level/biology/cie/25/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/a-level/biology/cie/25/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/a-level/biology/cie/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
              subtitle: "First exams 2025",
            },
            {
              url: "/international-a-level/biology/edexcel/18/",
              links: [
                {
                  url: "/international-a-level/biology/edexcel/18/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/international-a-level/biology/edexcel/18/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/international-a-level/biology/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel IAL",
            },
            {
              url: "/international-a-level/biology/oxford-aqa/",
              links: [
                {
                  url: "/international-a-level/biology/oxford-aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Oxford AQA IAL",
            },
          ],
        },
        {
          url: "/a-level/chemistry/",
          title: "Chemistry",
          rights: [
            {
              url: "/a-level/chemistry/aqa/17/",
              links: [
                {
                  url: "/a-level/chemistry/aqa/17/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/a-level/chemistry/aqa/17/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/a-level/chemistry/aqa/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
            },
            {
              url: "/a-level/chemistry/edexcel/17/",
              links: [
                {
                  url: "/a-level/chemistry/edexcel/17/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/a-level/chemistry/edexcel/17/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/a-level/chemistry/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/a-level/chemistry/ocr/17/",
              links: [
                {
                  url: "/a-level/chemistry/ocr/17/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/a-level/chemistry/ocr/17/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/a-level/chemistry/ocr/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
            },
            {
              url: "/a-level/chemistry/cie/22/",
              links: [
                {
                  url: "/a-level/chemistry/cie/22/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/a-level/chemistry/cie/22/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/a-level/chemistry/cie/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
              subtitle: "Last exams 2024",
            },
            {
              url: "/a-level/chemistry/cie/25/",
              links: [
                {
                  url: "/a-level/chemistry/cie/25/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/a-level/chemistry/cie/25/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/a-level/chemistry/cie/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
              subtitle: "First exams 2025",
            },
            {
              url: "/international-a-level/chemistry/edexcel/17/",
              links: [
                {
                  url: "/international-a-level/chemistry/edexcel/17/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/international-a-level/chemistry/edexcel/17/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/international-a-level/chemistry/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel IAL",
            },
            {
              url: "/international-a-level/chemistry/oxford-aqa/19/",
              links: [
                {
                  url: "/international-a-level/chemistry/oxford-aqa/19/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/international-a-level/chemistry/oxford-aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Oxford AQA IAL",
            },
          ],
        },
        {
          url: "/a-level/physics/",
          title: "Physics",
          rights: [
            {
              url: "/a-level/physics/aqa/17/",
              links: [
                {
                  url: "/a-level/physics/aqa/17/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/a-level/physics/aqa/17/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/a-level/physics/aqa/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
            },
            {
              url: "/a-level/physics/edexcel/17/",
              links: [
                {
                  url: "/a-level/physics/edexcel/17/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/a-level/physics/edexcel/17/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/a-level/physics/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/a-level/physics/ocr/17/",
              links: [
                {
                  url: "/a-level/physics/ocr/17/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/a-level/physics/ocr/17/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/a-level/physics/ocr/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
            },
            {
              url: "/a-level/physics/cie/22/",
              links: [
                {
                  url: "/a-level/physics/cie/22/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/a-level/physics/cie/22/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/a-level/physics/cie/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
              subtitle: "Last exams 2024",
            },
            {
              url: "/a-level/physics/cie/25/",
              links: [
                {
                  url: "/a-level/physics/cie/25/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/a-level/physics/cie/25/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/a-level/physics/cie/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
              subtitle: "First exams 2025",
            },
            {
              url: "/international-a-level/physics/edexcel/19/",
              links: [
                {
                  url: "/international-a-level/physics/edexcel/19/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/international-a-level/physics/edexcel/19/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/international-a-level/physics/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel IAL",
            },
            {
              url: "/international-a-level/physics/oxford-aqa/",
              links: [
                {
                  url: "/international-a-level/physics/oxford-aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Oxford AQA IAL",
            },
          ],
        },
        {
          url: "/a-level/business/",
          title: "Business",
          rights: [
            {
              url: "/a-level/business/aqa/",
              links: [
                {
                  url: "/a-level/business/aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
            },
            {
              url: "/a-level/business/edexcel/17/",
              links: [
                {
                  url: "/a-level/business/edexcel/17/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/a-level/business/edexcel/17/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/a-level/business/edexcel/17/flashcards/",
                  title: "Flashcards",
                },
                {
                  url: "/a-level/business/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/a-level/business/ocr/",
              links: [
                {
                  url: "/a-level/business/ocr/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
            },
            {
              url: "/a-level/business/cie/23/",
              links: [
                {
                  url: "/a-level/business/cie/23/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/a-level/business/cie/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
            },
            {
              url: "/international-a-level/business/oxford-aqa/",
              links: [
                {
                  url: "/international-a-level/business/oxford-aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Oxford AQA IAL",
            },
          ],
        },
        {
          url: "/a-level/computer-science/",
          title: "Computer Science",
          rights: [
            {
              url: "/a-level/computer-science/aqa/",
              links: [
                {
                  url: "/a-level/computer-science/aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
            },
            {
              url: "/a-level/computer-science/cie/",
              links: [
                {
                  url: "/a-level/computer-science/cie/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
            },
            {
              url: "/a-level/computer-science/ocr/17/",
              links: [
                {
                  url: "/a-level/computer-science/ocr/17/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/a-level/computer-science/ocr/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
            },
          ],
        },
        {
          url: "/a-level/economics/",
          title: "Economics",
          rights: [
            {
              url: "/a-level/economics/aqa/17/",
              links: [
                {
                  url: "/a-level/economics/aqa/17/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/a-level/economics/aqa/17/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/a-level/economics/aqa/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
            },
            {
              url: "/a-level/economics-a/edexcel/17/",
              links: [
                {
                  url: "/a-level/economics-a/edexcel/17/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/a-level/economics-a/edexcel/17/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/a-level/economics-a/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/a-level/economics/ocr/",
              links: [
                {
                  url: "/a-level/economics/ocr/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
            },
            {
              url: "/a-level/economics/cie/",
              links: [
                {
                  url: "/a-level/economics/cie/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
            },
            {
              url: "/international-a-level/economics/oxford-aqa/",
              links: [
                {
                  url: "/international-a-level/economics/oxford-aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Oxford AQA IAL",
            },
          ],
        },
        {
          url: "/a-level/english-language/",
          title: "English Language",
          rights: [
            {
              url: "/a-level/english-language/aqa/",
              links: [
                {
                  url: "/a-level/english-language/aqa/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
            },
            {
              url: "/a-level/english-language/edexcel/",
              links: [
                {
                  url: "/a-level/english-language/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/a-level/english-language/ocr/",
              links: [
                {
                  url: "/a-level/english-language/ocr/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
            },
            {
              url: "/a-level/english-language/cie/",
              links: [
                {
                  url: "/a-level/english-language/cie/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
            },
            {
              url: "/international-a-level/english-language/edexcel/",
              links: [
                {
                  url: "/international-a-level/english-language/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel IAL",
            },
            {
              url: "/international-a-level/english-language/oxford-aqa/",
              links: [
                {
                  url: "/international-a-level/english-language/oxford-aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Oxford AQA IAL",
            },
          ],
        },
        {
          url: "/a-level/english-language-and-literature/",
          title: "English Language & Literature",
          rights: [
            {
              url: "/a-level/english-language-and-literature/aqa/",
              links: [
                {
                  url: "/a-level/english-language-and-literature/aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
            },
          ],
        },
        {
          url: "/a-level/english-literature/",
          title: "English Literature",
          rights: [
            {
              url: "/a-level/english-literature/aqa/a/",
              links: [
                {
                  url: "/a-level/english-literature/aqa/a/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
              subjectAlias: "English Literature A",
            },
            {
              url: "/a-level/english-literature/aqa/b/",
              links: [
                {
                  url: "/a-level/english-literature/aqa/b/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
              subjectAlias: "English Literature B",
            },
            {
              url: "/a-level/english-literature/edexcel/",
              links: [
                {
                  url: "/a-level/english-language/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/a-level/english-literature/ocr/17/",
              links: [
                {
                  url: "/a-level/english-literature/ocr/17/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/a-level/english-literature/ocr/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
            },
            {
              url: "/a-level/english-literature/cie/",
              links: [
                {
                  url: "/a-level/english-literature/cie/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
            },
            {
              url: "/international-a-level/english-literature/edexcel/",
              links: [
                {
                  url: "/international-a-level/english-literature/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel IAL",
            },
            {
              url: "/international-a-level/english-literature/oxford-aqa/",
              links: [
                {
                  url: "/international-a-level/english-literature/oxford-aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Oxford AQA IAL",
            },
          ],
        },
        {
          url: "/a-level/french/",
          title: "French",
          rights: [
            {
              url: "/a-level/french/aqa/",
              links: [
                {
                  url: "/a-level/french/aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
            },
            {
              url: "/a-level/french/edexcel/",
              links: [
                {
                  url: "/a-level/french/edexcel/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/a-level/french/cie/language-and-literature/",
              links: [
                {
                  url: "/a-level/french/cie/language-and-literature/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
              subjectAlias: "French Language & Literature",
            },
          ],
        },
        {
          url: "/a-level/further-maths/",
          title: "Further Maths",
          rights: [
            {
              url: "/a-level/further-maths/aqa/",
              links: [
                {
                  url: "/a-level/further-maths/aqa/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
            },
            {
              url: "/a-level/further-maths/edexcel/",
              links: [
                {
                  url: "/a-level/further-maths_core-pure/edexcel/17/",
                  title: "Core Pure",
                },
                {
                  url: "/a-level/further-maths_further-statistics-1/edexcel/17/",
                  title: "Further Statistics 1",
                },
                {
                  url: "/a-level/further-maths_further-mechanics-1/edexcel/17/",
                  title: "Further Mechanics 1",
                },
                {
                  url: "/a-level/further-maths_decision-maths-1/edexcel/17/",
                  title: "Decision 1",
                },
                {
                  url: "/a-level/further-maths/edexcel/solution-banks/",
                  title: "Solution Banks",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/a-level/further-maths/ocr/",
              links: [
                {
                  url: "/a-level/further-maths/ocr/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
            },
            {
              url: "/a-level/further-maths/cie/",
              links: [
                {
                  url: "/a-level/further-maths/cie/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
            },
            {
              url: "/international-a-level/further-maths/edexcel/18/",
              links: [
                {
                  url: "/international-a-level/further-maths/edexcel/18/further-pure-1/",
                  title: "Further Pure 1",
                },
                {
                  url: "/international-a-level/further-maths/edexcel/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel IAL",
            },
            {
              url: "/international-a-level/further-maths/oxford-aqa/",
              links: [
                {
                  url: "/international-a-level/further-maths/oxford-aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Oxford AQA IAL",
            },
          ],
        },
        {
          url: "/a-level/geography/",
          title: "Geography",
          rights: [
            {
              url: "/a-level/geography/aqa/18/",
              links: [
                {
                  url: "/a-level/geography/aqa/18/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/a-level/geography/aqa/18/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/a-level/geography/aqa/18/flashcards/",
                  title: "Flashcards",
                },
                {
                  url: "/a-level/geography/aqa/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
            },
            {
              url: "/a-level/geography/edexcel/18/",
              links: [
                {
                  url: "/a-level/geography/edexcel/18/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/a-level/geography/edexcel/18/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/a-level/geography/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/a-level/geography/ocr/",
              links: [
                {
                  url: "/a-level/geography/ocr/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
            },
            {
              url: "/a-level/geography/cie/",
              links: [
                {
                  url: "/a-level/geography/cie/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
            },
            {
              url: "/international-a-level/geography/edexcel/",
              links: [
                {
                  url: "/international-a-level/geography/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel IAL",
            },
            {
              url: "/international-a-level/geography/oxford-aqa/",
              links: [
                {
                  url: "/international-a-level/geography/oxford-aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Oxford AQA IAL",
            },
          ],
        },
        {
          url: "/a-level/german",
          title: "German",
          rights: [
            {
              url: "/a-level/german/aqa/",
              links: [
                {
                  url: "/a-level/german/aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
            },
            {
              url: "/a-level/german/edexcel/",
              links: [
                {
                  url: "/a-level/german/edexcel/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/a-level/german/cie/language-and-literature/",
              links: [
                {
                  url: "/a-level/german/cie/language-and-literature/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
              subjectAlias: "German Language & Literature",
            },
          ],
        },
        {
          url: "/a-level/history/",
          title: "History",
          rights: [
            {
              url: "/a-level/history/aqa/",
              links: [
                {
                  url: "/a-level/history/aqa/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
            },
            {
              url: "/a-level/history/edexcel/",
              links: [
                {
                  url: "/a-level/history/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/a-level/history/ocr/",
              links: [
                {
                  url: "/a-level/history/ocr/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
            },
            {
              url: "/international-a-level/history/cie/",
              links: [
                {
                  url: "/a-level/history/cie/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE IAL",
            },
          ],
        },
        {
          url: "/a-level/psychology/",
          title: "Psychology",
          rights: [
            {
              url: "/a-level/psychology/aqa/17/",
              links: [
                {
                  url: "/a-level/psychology/aqa/17/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/a-level/psychology/aqa/17/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/a-level/psychology/aqa/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
            },
            {
              url: "/a-level/psychology/edexcel/",
              links: [
                {
                  url: "/a-level/psychology/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/a-level/psychology/ocr/",
              links: [
                {
                  url: "/a-level/psychology/ocr/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
            },
            {
              url: "/a-level/psychology/cie/",
              links: [
                {
                  url: "/a-level/psychology/cie/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
            },
            {
              url: "/international-a-level/psychology/edexcel/",
              links: [
                {
                  url: "/international-a-level/psychology/edexcel/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel IAL",
            },
            {
              url: "/international-a-level/psychology/oxford-aqa/",
              links: [
                {
                  url: "/international-a-level/psychology/oxford-aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Oxford AQA IAL",
            },
            {
              url: "/a-level/psychology/wjec/",
              links: [
                {
                  url: "/a-level/psychology/wjec/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "WJEC",
            },
            {
              url: "/a-level/psychology/wjec-eduqas/",
              links: [
                {
                  url: "/a-level/psychology/wjec-eduqas/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "WJEC Eduqas",
            },
          ],
        },
        {
          url: "/a-level/religious-studies/",
          title: "Religious Studies",
          rights: [
            {
              url: "/a-level/religious-studies/aqa/",
              links: [
                {
                  url: "/a-level/religious-studies/aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
            },
            {
              url: "/a-level/religious-studies/edexcel/",
              links: [
                {
                  url: "/a-level/religious-studies/edexcel/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/a-level/religious-studies/ocr/",
              links: [
                {
                  url: "/a-level/religious-studies/ocr/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
            },
          ],
        },
        {
          url: "/a-level/spanish/",
          title: "Spanish",
          rights: [
            {
              url: "/a-level/spanish/aqa/",
              links: [
                {
                  url: "/a-level/spanish/aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
            },
            {
              url: "/a-level/spanish/edexcel/",
              links: [
                {
                  url: "/a-level/spanish/edexcel/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
            },
            {
              url: "/a-level/spanish/cie/language-and-literature/",
              links: [
                {
                  url: "/a-level/spanish/cie/language-and-literature/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
              subjectAlias: "Spanish Language & Literature",
            },
          ],
        },
        {
          title: "Other Subjects",
          alphabetize: true,
          rights: [
            {
              url: "/a-level/accounting/aqa/",
              links: [
                {
                  url: "/a-level/accounting/aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
              subjectAlias: "Accounting",
            },
            {
              url: "/a-level/art-and-design/aqa/",
              links: [
                {
                  url: "/a-level/art-and-design/aqa/fine-art/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
              subjectAlias: "Art & Design: Fine Art",
            },
            {
              url: "/a-level/art-and-design/aqa/",
              links: [
                {
                  url: "/a-level/art-and-design/aqa/photography/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
              subjectAlias: "Art & Design: Photography",
            },
            {
              url: "/a-level/design-and-technology/aqa/product-design/",
              links: [
                {
                  url: "/a-level/design-and-technology/aqa/product-design/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
              subjectAlias: "Design & Technology: Product Design",
            },
            {
              url: "/a-level/law/aqa/",
              links: [
                {
                  url: "/a-level/law/aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
              subjectAlias: "Law",
            },
            {
              url: "/a-level/politics/aqa/",
              links: [
                {
                  url: "/a-level/politics/aqa/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
              subjectAlias: "Politics",
            },
            {
              url: "/a-level/physical-education/aqa/",
              links: [
                {
                  url: "/a-level/physical-education/aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
              subjectAlias: "Physical Education",
            },
            {
              url: "/a-level/sociology/aqa/",
              links: [
                {
                  url: "/a-level/sociology/aqa/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "AQA",
              subjectAlias: "Sociology",
            },
            {
              url: "/a-level/politics/edexcel/",
              links: [
                {
                  url: "/a-level/politics/edexcel/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Edexcel",
              subjectAlias: "Politics",
            },
            {
              url: "/a-level/law/ocr/",
              links: [
                {
                  url: "/a-level/law/ocr/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
              subjectAlias: "Law",
            },
            {
              url: "/a-level/physical-education/ocr/",
              links: [
                {
                  url: "/a-level/physical-education/ocr/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
              subjectAlias: "Physical Education",
            },
            {
              url: "/a-level/sociology/ocr/",
              links: [
                {
                  url: "/a-level/sociology/ocr/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "OCR",
              subjectAlias: "Sociology",
            },
            {
              url: "/a-level/accounting/cie/",
              links: [
                {
                  url: "/a-level/accounting/cie/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
              subjectAlias: "Accounting",
            },
            {
              url: "/a-level/ict/cie/information-technology/",
              links: [
                {
                  url: "/a-level/ict/cie/information-technology/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
              subjectAlias: "ICT (Information Technology)",
            },
            {
              url: "/a-level/law/cie/",
              links: [
                {
                  url: "/a-level/law/cie/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
              subjectAlias: "Law",
            },
            {
              url: "/a-level/marine-science/cie/",
              links: [
                {
                  url: "/a-level/marine-science/cie/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
              subjectAlias: "Marine Science",
            },
            {
              url: "/a-level/sociology/cie/",
              links: [
                {
                  url: "/a-level/sociology/cie/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "CIE",
              subjectAlias: "Sociology",
            },
          ],
        },
      ],
      title: "A Level",
    },
    {
      url: "/dp/",
      lefts: [
        {
          url: "/dp/maths/",
          title: "Maths",
          rights: [
            {
              url: "/dp/maths_aa-sl/ib/21/",
              links: [
                {
                  url: "/dp/maths_aa-sl/ib/21/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/dp/maths_aa-sl/ib/21/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/dp/maths/ib/aa/21/sl/flashcards/",
                  title: "Flashcards",
                },
                {
                  url: "/dp/maths_aa-sl/ib/21/practice-paper-questions/",
                  title: "Practice Papers",
                },
              ],
              legacyTitle: "Maths: AA SL",
              title: "DP",
              subjectAlias: "Maths: AA",
              module: IB_STANDARD,
            },
            {
              url: "/dp/maths_aa-hl/ib/21/",
              links: [
                {
                  url: "/dp/maths_aa-hl/ib/21/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/dp/maths_aa-hl/ib/21/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/dp/maths/ib/aa/21/hl/flashcards/",
                  title: "Flashcards",
                },
                {
                  url: "/dp/maths_aa-hl/ib/21/practice-paper-questions/",
                  title: "Practice Papers",
                },
              ],
              legacyTitle: "Maths: AA HL",
              title: "DP",
              subjectAlias: "Maths: AA",
              module: IB_HIGHER,
            },
            {
              url: "/dp/maths_ai-sl/ib/21/",
              links: [
                {
                  url: "/dp/maths_ai-sl/ib/21/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/dp/maths_ai-sl/ib/21/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/dp/maths/ib/ai/21/sl/flashcards/",
                  title: "Flashcards",
                },
                {
                  url: "/dp/maths_ai-sl/ib/21/practice-paper-questions/",
                  title: "Practice Papers",
                },
              ],
              legacyTitle: "Maths: AI SL",
              title: "DP",
              subjectAlias: "Maths: AI",
              module: IB_STANDARD,
            },
            {
              url: "/dp/maths_ai-hl/ib/21/",
              links: [
                {
                  url: "/dp/maths_ai-hl/ib/21/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/dp/maths_ai-hl/ib/21/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/dp/maths/ib/ai/21/hl/flashcards/",
                  title: "Flashcards",
                },
                {
                  url: "/dp/maths_ai-hl/ib/21/practice-paper-questions/",
                  title: "Practice Papers",
                },
              ],
              legacyTitle: "Maths: AI HL",
              title: "DP",
              subjectAlias: "Maths: AI",
              module: IB_HIGHER,
            },
          ],
        },
        {
          url: "/dp/biology/",
          title: "Biology",
          rights: [
            {
              url: "/dp/biology_sl/ib/16/",
              links: [
                {
                  url: "/dp/biology_sl/ib/16/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/dp/biology_sl/ib/16/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/dp/biology_sl/ib/16/practice-paper-questions/",
                  title: "Practice Papers",
                },
              ],
              title: "DP",
              module: IB_STANDARD,
              subtitle: "Last Exams 2024",
            },
            {
              url: "/dp/biology_hl/ib/16/",
              links: [
                {
                  url: "/dp/biology_hl/ib/16/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/dp/biology_hl/ib/16/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/dp/biology_hl/ib/16/practice-paper-questions/",
                  title: "Practice Papers",
                },
              ],
              title: "DP",
              module: IB_HIGHER,
              subtitle: "Last Exams 2024",
            },
            {
              url: "/dp/biology/sl/25/",
              links: [
                {
                  url: "/dp/biology/sl/25/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/dp/biology/sl/25/revision-notes/",
                  title: "Revision Notes",
                },
              ],
              title: "DP",
              module: IB_STANDARD,
              subtitle: "First Exams 2025",
            },
            {
              url: "/dp/biology/hl/25/",
              links: [
                {
                  url: "/dp/biology/hl/25/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/dp/biology/hl/25/revision-notes/",
                  title: "Revision Notes",
                },
              ],
              title: "DP",
              module: IB_HIGHER,
              subtitle: "First Exams 2025",
            },
          ],
        },
        {
          url: "/dp/chemistry/",
          title: "Chemistry",
          rights: [
            {
              url: "/dp/chemistry_sl/ib/16/",
              links: [
                {
                  url: "/dp/chemistry_sl/ib/16/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/dp/chemistry_sl/ib/16/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/dp/chemistry_sl/ib/16/practice-paper-questions/",
                  title: "Practice Papers",
                },
              ],
              title: "DP",
              module: IB_STANDARD,
              subtitle: "Last Exams 2024",
            },
            {
              url: "/dp/chemistry_hl/ib/16/",
              links: [
                {
                  url: "/dp/chemistry_hl/ib/16/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/dp/chemistry_hl/ib/16/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/dp/chemistry_hl/ib/16/practice-paper-questions/",
                  title: "Practice Papers",
                },
              ],
              title: "DP",
              module: IB_HIGHER,
              subtitle: "Last Exams 2024",
            },
            {
              url: "/dp/chemistry/sl/25/",
              links: [
                {
                  url: "/dp/chemistry/sl/25/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/dp/chemistry/sl/25/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/dp/chemistry/ib/23/sl/flashcards/",
                  title: "Flashcards",
                },
              ],
              title: "DP",
              module: IB_STANDARD,
              subtitle: "First Exams 2025",
            },
            {
              url: "/dp/chemistry/hl/25/",
              links: [
                {
                  url: "/dp/chemistry/hl/25/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/dp/chemistry/hl/25/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/dp/chemistry/ib/23/hl/flashcards/",
                  title: "Flashcards",
                },
              ],
              title: "DP",
              module: IB_HIGHER,
              subtitle: "First Exams 2025",
            },
          ],
        },
        {
          url: "/dp/physics/",
          title: "Physics",
          rights: [
            {
              url: "/dp/physics_sl/ib/16/",
              links: [
                {
                  url: "/dp/physics_sl/ib/16/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/dp/physics_sl/ib/16/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/dp/physics_sl/ib/16/practice-paper-questions/",
                  title: "Practice Papers",
                },
              ],
              title: "DP",
              module: IB_STANDARD,
              subtitle: "Last Exams 2024",
            },
            {
              url: "/dp/physics_hl/ib/16/",
              links: [
                {
                  url: "/dp/physics_hl/ib/16/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/dp/physics_hl/ib/16/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/dp/physics_hl/ib/16/practice-paper-questions/",
                  title: "Practice Papers",
                },
              ],
              title: "DP",
              module: IB_HIGHER,
              subtitle: "Last Exams 2024",
            },
            {
              url: "/dp/physics/sl/25/",
              links: [
                {
                  url: "/dp/physics/sl/25/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/dp/physics/sl/25/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/dp/physics/ib/23/sl/flashcards/",
                  title: "Flashcards",
                },
              ],
              title: "DP",
              module: IB_STANDARD,
              subtitle: "First Exams 2025",
            },
            {
              url: "/dp/physics/hl/25/",
              links: [
                {
                  url: "/dp/physics/hl/25/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/dp/physics/hl/25/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/dp/physics/ib/23/hl/flashcards/",
                  title: "Flashcards",
                },
              ],
              title: "DP",
              module: IB_HIGHER,
              subtitle: "First Exams 2025",
            },
          ],
        },
        {
          url: "/dp/business-management/",
          title: "Business Management",
          rights: [
            {
              url: "/dp/business-management/sl/24/",
              links: [
                {
                  url: "/dp/business-management/sl/24/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/dp/business/ib/management/22/sl/flashcards/",
                  title: "Flashcards",
                },
              ],
              title: "DP",
              subjectAlias: "Business Management",
              module: IB_STANDARD,
            },
            {
              url: "/dp/business-management/hl/24/",
              links: [
                {
                  url: "/dp/business-management/hl/24/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/dp/business/ib/management/22/hl/flashcards/",
                  title: "Flashcards",
                },
              ],
              title: "DP",
              subjectAlias: "Business Management",
              module: IB_HIGHER,
            },
          ],
        },
        {
          url: "/dp/economics/",
          title: "Economics",
          rights: [
            {
              url: "/dp/economics/sl/22/",
              links: [
                {
                  url: "/dp/economics/sl/22/revision-notes/",
                  title: "Revision Notes",
                },
              ],
              title: "DP",
              subjectAlias: "Economics",
              module: IB_STANDARD,
            },
            {
              url: "/dp/economics/hl/22/",
              links: [
                {
                  url: "/dp/economics/hl/22/revision-notes/",
                  title: "Revision Notes",
                },
              ],
              title: "DP",
              subjectAlias: "Economics",
              module: IB_HIGHER,
            },
          ],
        },
        {
          url: "/dp/environmental-systems-and-societies-ess/",
          title: "ESS",
          rights: [
            {
              url: "/dp/environmental-systems-and-societies-ess/sl/17/",
              links: [
                {
                  url: "/dp/environmental-systems-and-societies-ess/sl/17/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/dp/environmental-systems-and-societies-ess/sl/17/revision-notes/",
                  title: "Revision Notes",
                },
              ],
              title: "DP",
              subjectAlias: "Environmental Systems & Societies (ESS)",
              module: IB_STANDARD,
            },
            {
              url: "/dp/environmental-systems-and-societies-ess/hl/24/",
              links: [
                {
                  url: "/dp/environmental-systems-and-societies-ess/hl/24/revision-notes/",
                  title: "Revision Notes",
                },
              ],
              title: "DP",
              subjectAlias: "Environmental Systems & Societies (ESS)",
              module: IB_HIGHER,
            },
          ],
        },
        {
          url: "/dp/geography/",
          title: "Geography",
          rights: [
            {
              url: "/dp/geography/sl/19/",
              links: [
                {
                  url: "/dp/geography/sl/19/revision-notes/",
                  title: "Revision Notes",
                },
              ],
              title: "DP",
              module: IB_STANDARD,
            },
            {
              url: "/dp/geography/hl/17/",
              links: [
                {
                  url: "/dp/geography/hl/17/revision-notes/",
                  title: "Revision Notes",
                },
              ],
              title: "DP",
              module: IB_HIGHER,
            },
          ],
        },
        {
          url: "/dp/psychology/",
          title: "Psychology",
          rights: [
            {
              url: "/dp/psychology/sl/17/",
              links: [
                {
                  url: "/dp/psychology/sl/17/revision-notes/",
                  title: "Revision Notes",
                },
              ],
              title: "DP",
              module: IB_STANDARD,
            },
            {
              url: "/dp/psychology/hl/17/",
              links: [
                {
                  url: "/dp/psychology/hl/17/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/dp/psychology/hl/17/flashcards/",
                  title: "Flashcards",
                },
              ],
              title: "DP",
              module: IB_HIGHER,
            },
          ],
        },
      ],
      hasSingleBoard: true,
      title: "IB",
    },
    {
      url: "/o-level/",
      lefts: [
        {
          url: "/o-level/maths/",
          title: "Maths",
          rights: [
            {
              url: "/o-level/maths/cie/25/",
              links: [
                {
                  url: "/o-level/maths/cie/25/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/o-level/maths/cie/25/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/o-level/maths/cie/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              legacyTitle: "Cambridge",
              title: "Cambridge",
            },
            {
              url: "/o-level/additional-maths/cie/25/",
              links: [
                {
                  url: "/o-level/additional-maths/cie/25/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/o-level/additional-maths/cie/25/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/o-level/additional-maths/cie/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              legacyTitle: "Cambridge (Additional Maths)",
              title: "Cambridge",
              subjectAlias: "Additional Maths",
            },
            {
              url: "/o-level/statistics/cie/",
              links: [
                {
                  url: "/o-level/statistics/cie/past-papers/",
                  title: "Past Papers",
                },
              ],
              legacyTitle: "Cambridge (Statistics)",
              title: "Cambridge",
              subjectAlias: "Statistics",
            },
          ],
        },
        {
          url: "/o-level/biology/",
          title: "Biology",
          rights: [
            {
              url: "/o-level/biology/cie/23/",
              links: [
                {
                  url: "/o-level/biology/cie/23/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/o-level/biology/cie/23/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/o-level/biology/cie/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              legacyTitle: "Cambridge",
              title: "Cambridge",
            },
          ],
        },
        {
          url: "/o-level/chemistry/",
          title: "Chemistry",
          rights: [
            {
              url: "/o-level/chemistry/cie/23/",
              links: [
                {
                  url: "/o-level/chemistry/cie/23/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/o-level/chemistry/cie/23/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/o-level/chemistry/cie/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              legacyTitle: "Cambridge",
              title: "Cambridge",
            },
          ],
        },
        {
          url: "/o-level/physics/",
          title: "Physics",
          rights: [
            {
              url: "/o-level/physics/cie/23/",
              links: [
                {
                  url: "/o-level/physics/cie/23/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/o-level/physics/cie/23/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/o-level/physics/cie/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
              legacyTitle: "Cambridge",
              title: "Cambridge",
            },
          ],
        },
        {
          url: "/o-level/accounting/",
          title: "Accounting",
          rights: [
            {
              url: "/o-level/accounting/cie/21/",
              links: [
                {
                  url: "/o-level/accounting/cie/21/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/o-level/accounting/cie/past-papers/",
                  title: "Past Papers",
                },
              ],
              legacyTitle: "Cambridge",
              title: "Cambridge",
            },
          ],
        },
        {
          url: "/o-level/business/",
          title: "Business Studies",
          rights: [
            {
              url: "/o-level/business/cie/studies/18/",
              links: [
                {
                  url: "/o-level/business/cie/studies/18/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/o-level/business/cie/studies/past-papers/",
                  title: "Past Papers",
                },
              ],
              legacyTitle: "Cambridge",
              title: "Cambridge",
            },
          ],
        },
        {
          url: "/o-level/computer-science/",
          title: "Computer Science",
          rights: [
            {
              url: "/o-level/computer-science/cie/21/",
              links: [
                {
                  url: "/o-level/computer-science/cie/21/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/o-level/computer-science/cie/21/flashcards/",
                  title: "Flashcards",
                },
                {
                  url: "/o-level/computer-science/cie/past-papers/",
                  title: "Past Papers",
                },
              ],
              legacyTitle: "Cambridge",
              title: "Cambridge",
            },
          ],
        },
        {
          url: "/o-level/geography/",
          title: "Geography",
          rights: [
            {
              url: "/o-level/geography/cie/22/",
              links: [
                {
                  url: "/o-level/geography/cie/22/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/o-level/geography/cie/past-papers/",
                  title: "Past Papers",
                },
              ],
              legacyTitle: "Cambridge",
              title: "Cambridge",
            },
          ],
        },
        {
          title: "Other Subjects",
          alphabetize: true,
          rights: [
            {
              url: "/o-level/bangladesh-studies/cie/",
              links: [
                {
                  url: "/o-level/bangladesh-studies/cie/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Cambridge",
              subjectAlias: "Bangladesh Studies",
              legacyTitle: "Bangladesh Studies",
            },
            {
              url: "/o-level/economics/cie/18/",
              links: [
                {
                  url: "/o-level/economics/cie/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Cambridge",
              subjectAlias: "Economics",
              legacyTitle: "Economics",
            },
            {
              url: "/o-level/english-language/cie/",
              links: [
                {
                  url: "/o-level/english-language/cie/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Cambridge",
              subjectAlias: "English Language",
              legacyTitle: "English Language",
            },
            {
              url: "/o-level/environmental-management/cie/",
              links: [
                {
                  url: "/o-level/environmental-management/cie/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Cambridge",
              subjectAlias: "Environmental Management",
              legacyTitle: "Environmental Management",
            },
            {
              url: "/o-level/history/cie/",
              links: [
                {
                  url: "/o-level/history/cie/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Cambridge",
              subjectAlias: "History",
              legacyTitle: "History",
            },
            {
              url: "/o-level/islamiyat/cie/",
              links: [
                {
                  url: "/o-level/islamiyat/cie/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Cambridge",
              subjectAlias: "Islamiyat",
              legacyTitle: "Islamiyat",
            },
            {
              url: "/o-level/english-literature/cie/literature-in-english/",
              links: [
                {
                  url: "/o-level/english-literature/cie/literature-in-english/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Cambridge",
              subjectAlias: "Literature in English",
              legacyTitle: "Literature in English",
            },
            {
              url: "/o-level/pakistan-studies/cie/",
              links: [
                {
                  url: "/o-level/pakistan-studies/cie/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Cambridge",
              subjectAlias: "Pakistan Studies",
              legacyTitle: "Pakistan Studies",
            },
            {
              url: "/o-level/religious-studies/cie/bible-knowledge/",
              links: [
                {
                  url: "/o-level/religious-studies/cie/bible-knowledge/past-papers/",
                  title: "Past Papers",
                },
              ],
              title: "Cambridge",
              subjectAlias: "Religious Studies (Bible Knowledge)",
              legacyTitle: "Religious Studies (Bible Knowledge)",
            },
          ],
        },
      ],
      title: "O Level",
    },
    {
      url: "/ap/",
      lefts: [
        {
          title: "Maths",
          rights: [
            {
              title: "College Board",
              subjectAlias: "Calculus AB",
              url: "/ap/calculus-ab/college-board/",
              links: [
                {
                  url: "/ap/calculus-ab/college-board/-/pages/past-papers/",
                  title: "Past Exams",
                },
              ],
            },
            {
              title: "College Board",
              subjectAlias: "Calculus BC",
              url: "/ap/calculus-bc/college-board/",
              links: [
                {
                  url: "/ap/calculus-bc/college-board/-/pages/past-papers/",
                  title: "Past Exams",
                },
              ],
            },
            {
              title: "College Board",
              subjectAlias: "Statistics",
              url: "/ap/statistics/college-board/",
              links: [
                {
                  url: "/ap/statistics/college-board/-/pages/past-papers/",
                  title: "Past Exams",
                },
              ],
            },
          ],
        },
        {
          title: "Biology",
          url: "/ap/biology/",
          rights: [
            {
              title: "College Board",
              subjectAlias: "AP Biology",
              url: "/ap/biology/college-board/20/",
              links: [
                {
                  url: "/ap/biology/college-board/20/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/ap/biology/college-board/20/revision-notes/",
                  title: "Study Guides",
                },
                {
                  url: "/ap/biology/college-board/-/pages/past-papers/",
                  title: "Past Exams",
                },
              ],
            },
          ],
        },
        {
          title: "Chemistry",
          url: "/ap/chemistry/",
          rights: [
            {
              title: "College Board",
              subjectAlias: "AP Chemistry",
              url: "/ap/chemistry/college-board/",
              links: [
                {
                  url: "/ap/chemistry/college-board/22/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/ap/chemistry/college-board/22/revision-notes/",
                  title: "Study Guides",
                },
                {
                  url: "/ap/chemistry/college-board/-/pages/past-papers/",
                  title: "Past Exams",
                },
              ],
            },
          ],
        },
        {
          title: "Physics",
          rights: [
            {
              title: "College Board",
              subjectAlias: "Physics 1: Algebra-Based",
              url: "/ap/physics-1-algebra-based/college-board/",
              links: [
                {
                  url: "/ap/physics-1-algebra-based/college-board/-/pages/past-papers/",
                  title: "Past Exams",
                },
              ],
            },
          ],
        },
        {
          title: "Chinese Language",
          url: "/ap/chinese/",
          rights: [
            {
              title: "College Board",
              subjectAlias: "Chinese Language & Culture",
              url: "/ap/chinese/college-board/language-and-culture/",
              links: [
                {
                  url: "/ap/chinese/college-board/language-and-culture/past-papers/",
                  title: "Past Exams",
                },
              ],
            },
          ],
        },
        {
          title: "Computer Science",
          url: "/ap/computer-science/",
          rights: [
            {
              title: "College Board",
              subjectAlias: "Computer Science Principles",
              url: "/ap/computer-science/college-board/principles/",
              links: [
                {
                  url: "/ap/computer-science/college-board/principles/past-papers/",
                  title: "Past Exams",
                },
              ],
            },
          ],
        },
        {
          title: "Economics",
          url: "/ap/economics/",
          rights: [
            {
              title: "College Board",
              subjectAlias: "Macroeconomics",
              url: "/ap/economics/college-board/macroeconomics/",
              links: [
                {
                  url: "/ap/economics/college-board/macroeconomics/past-papers/",
                  title: "Past Exams",
                },
              ],
            },
            {
              title: "College Board",
              subjectAlias: "Microeconomics",
              url: "/ap/economics/college-board/microeconomics/",
              links: [
                {
                  url: "/ap/economics/college-board/microeconomics/past-papers/",
                  title: "Past Exams",
                },
              ],
            },
          ],
        },
        {
          title: "English",
          url: "/ap/english-language/",
          rights: [
            {
              title: "College Board",
              subjectAlias: "English Language and Composition",
              url: "/ap/english-language/college-board/and-composition/",
              links: [
                {
                  url: "/ap/english-language/college-board/and-composition/past-papers/",
                  title: "Past Exams",
                },
              ],
            },
          ],
        },
        {
          title: "Environmental Science",
          url: "/ap/environmental-science/",
          rights: [
            {
              title: "College Board",
              subjectAlias: "AP Environmental Science",
              url: "/ap/environmental-science/college-board/",
              links: [
                {
                  url: "/ap/environmental-science/college-board/past-papers/",
                  title: "Past Exams",
                },
              ],
            },
          ],
        },
        {
          title: "French Language",
          url: "/ap/french/",
          rights: [
            {
              title: "College Board",
              subjectAlias: "French Language & Culture",
              url: "/ap/french/college-board/language-and-culture/",
              links: [
                {
                  url: "/ap/french/college-board/language-and-culture/past-papers/",
                  title: "Past Exams",
                },
              ],
            },
          ],
        },
        {
          title: "Geography",
          rights: [
            {
              title: "College Board",
              subjectAlias: "Human Geography",
              url: "/ap/human-geography/college-board/",
              links: [
                {
                  url: "/ap/human-geography/college-board/-/pages/past-papers/",
                  title: "Past Exams",
                },
              ],
            },
          ],
        },
        {
          title: "German Language",
          url: "/ap/german/",
          rights: [
            {
              title: "College Board",
              subjectAlias: "German Language & Culture",
              url: "/ap/german/college-board/language-and-culture/",
              links: [
                {
                  url: "/ap/german/college-board/language-and-culture/past-papers/",
                  title: "Past Exams",
                },
              ],
            },
          ],
        },
        {
          title: "History",
          rights: [
            {
              title: "College Board",
              subjectAlias: "US History",
              url: "/ap/us-history/college-board/",
              links: [
                {
                  url: "/ap/us-history/college-board/-/pages/past-papers/",
                  title: "Past Exams",
                },
              ],
            },
            {
              title: "College Board",
              subjectAlias: "World History",
              url: "/ap/history/college-board/",
              links: [
                {
                  url: "/ap/history/college-board/world-history/past-papers/",
                  title: "Past Exams",
                },
              ],
            },
          ],
        },
        {
          title: "Latin",
          url: "/ap/latin/",
          rights: [
            {
              title: "College Board",
              subjectAlias: "AP Latin",
              url: "/ap/latin/college-board/",
              links: [
                {
                  url: "/ap/latin/college-board/past-papers/",
                  title: "Past Exams",
                },
              ],
            },
          ],
        },
        {
          title: "Research",
          url: "/ap/research/",
          rights: [
            {
              title: "College Board",
              subjectAlias: "AP Research",
              url: "/ap/research/college-board/",
              links: [
                {
                  url: "/ap/research/college-board/past-papers/",
                  title: "Past Exams",
                },
              ],
            },
          ],
        },
        {
          title: "Spanish Language",
          url: "/ap/spanish/",
          rights: [
            {
              title: "College Board",
              subjectAlias: "Spanish Language & Culture",
              url: "/ap/spanish/college-board/language-and-culture/",
              links: [
                {
                  url: "/ap/spanish/college-board/language-and-culture/past-papers/",
                  title: "Past Exams",
                },
              ],
            },
          ],
        },
      ],
      hasSingleBoard: true,
      title: "AP",
    },
    {
      title: "Other",
      lefts: [
        {
          title: "Level 2",
          rights: [
            {
              title: "AQA",
              subjectAlias: "Further Maths",
              url: "/gcse/further-maths/aqa/20/",
              links: [
                {
                  url: "/gcse/further-maths/aqa/20/topic-questions/",
                  title: "Topic Questions",
                },
                {
                  url: "/gcse/further-maths/aqa/20/revision-notes/",
                  title: "Revision Notes",
                },
                {
                  url: "/gcse/further-maths/aqa/-/pages/past-papers/",
                  title: "Past Papers",
                },
              ],
            },
            {
              title: "WJEC",
              subjectAlias: "Additional Maths",
              url: "/level-2/further-maths/wjec/additional-maths/",
              links: [
                {
                  url: "/level-2/further-maths/wjec/additional-maths/past-papers/",
                  title: "Past Papers",
                },
              ],
            },
          ],
        },
        {
          title: "Level 3",
          url: "/level-3/",
          rights: [
            {
              legacyTitle: "AQA Mathematical Studies (Core Maths)",
              title: "AQA",
              subjectAlias: "Mathematical Studies (Core Maths)",
              url: "/level-3/maths/aqa/mathematical-studies-core-maths/",
              links: [
                {
                  url: "/level-3/maths_paper-1-data-finance-estimation-and-modelling/aqa/mathematical-studies-core-maths/14/",
                  title: "Paper 1",
                },
                {
                  url: "/level-3/maths_paper-2a-statistical-techniques/aqa/mathematical-studies-core-maths/14/",
                  title: "Paper 2A",
                },
                {
                  url: "/level-3/maths_paper-2b-critical-path-and-risk-analysis/aqa/mathematical-studies-core-maths/14/",
                  title: "Paper 2B: Critical Path & Risk Analysis",
                },
                {
                  url: "/level-3/maths_paper-2c-graphical-techniques/aqa/mathematical-studies-core-maths/14/",
                  title: "Paper 2C: Graphical Techniques",
                },
                {
                  url: "/level-3/maths/aqa/mathematical-studies-core-maths/past-papers/",
                  title: "Past Papers",
                },
              ],
            },
          ],
        },
        {
          title: "Past Papers",
          url: "/past-papers/",
          rights: [
            {
              title: "All Past Papers",
              url: "/past-papers/",
              links: [
                {
                  title: "GCSE Past Papers",
                  url: "/gcse/past-papers/",
                },
                {
                  title: "IGCSE Past Papers",
                  url: "/igcse/past-papers/",
                },
                {
                  title: "AS Past Papers",
                  url: "/as/past-papers/",
                },
                {
                  title: "A Level Past Papers",
                  url: "/a-level/past-papers/",
                },
                {
                  title: "O Level Past Papers",
                  url: "/o-level/past-papers/",
                },
                {
                  title: "AP Past Papers",
                  url: "/ap/past-papers/",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export default CONFIG;
