const LinkedInIcon = ({ className }: { className?: string }) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 20 20"
    className={className}
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.51635 6.5769H0.33728V19.9037H4.51635V6.5769Z" />
    <path d="M15.821 6.28804C15.6669 6.26878 15.5032 6.25915 15.3395 6.24952C12.9996 6.15323 11.6805 7.53983 11.2183 8.13684C11.0931 8.30054 11.0353 8.39683 11.0353 8.39683V6.61543H7.03918V19.9422H11.0353H11.2183C11.2183 18.5845 11.2183 17.2364 11.2183 15.8787C11.2183 15.1469 11.2183 14.4151 11.2183 13.6833C11.2183 12.7781 11.1509 11.8152 11.6034 10.9871C11.9886 10.2938 12.6819 9.94713 13.4618 9.94713C15.7729 9.94713 15.821 12.0367 15.821 12.2292C15.821 12.2389 15.821 12.2485 15.821 12.2485V20H20.0001V11.3048C20.0001 8.32943 18.4883 6.57691 15.821 6.28804Z" />
    <path d="M2.42655 4.85311C3.7667 4.85311 4.85311 3.7667 4.85311 2.42655C4.85311 1.08641 3.7667 0 2.42655 0C1.0864 0 0 1.08641 0 2.42655C0 3.7667 1.0864 4.85311 2.42655 4.85311Z" />
  </svg>
);

export default LinkedInIcon;
