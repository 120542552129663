import Link from "next/link";

import useFreeTrialExperiment from "@/lib/hooks/useFreeTrialExperiment";
import Button from "src/components/Button";
import { KEYS } from "src/config/data/localStorage";
import { EVENT_TYPES } from "src/config/data/tracking";
import { useUser } from "src/lib/hooks/user";
import * as ls from "src/lib/localStorage";
import * as tracking from "src/lib/tracking";

interface JoinLinkProps {
  text: string;
  buttonLocation: string;
  pageReferralMethod: string;
  href: string;
  onClick?: () => void;
  variant?: "primary" | "gradient";
}

const JoinLink = ({
  text,
  buttonLocation,
  pageReferralMethod,
  href,
  onClick,
  variant = "primary",
}: JoinLinkProps) => {
  const { isMember, isStaff } = useUser();
  const { isFreeTrialEnabled } = useFreeTrialExperiment();

  const trackOnClick = () => {
    ls.setItem(KEYS.joinPageViewTrigger, pageReferralMethod);

    tracking.event(EVENT_TYPES.clickedNavigationBarJoinButton, {
      buttonLocation,
    });

    if (onClick) {
      onClick();
    }
  };

  if (isMember || isStaff) return null;

  return (
    <Button
      href={href}
      tag={Link}
      variant={isFreeTrialEnabled ? "gradient" : variant}
      className="ms-sm-2"
      onClick={trackOnClick}
    >
      {isFreeTrialEnabled ? "Try 7 days free" : text}
    </Button>
  );
};

export default JoinLink;
