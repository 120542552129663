import NextImage from "next/image";
import type { ImageLoaderProps } from "next/image";

const normalizeSrc = (src: string) => {
  return src.startsWith("/") ? src.slice(1) : src;
};

const cloudflareLoader = ({ src, width, quality }: ImageLoaderProps) => {
  const params = ["f=auto", `width=${width}`];

  if (quality) {
    params.push(`quality=${quality}`);
  }

  const paramsString = params.join(",");
  return `${process.env.NEXT_PUBLIC_IMAGE_OPTIMIZATION_URL}/${paramsString}/${normalizeSrc(src)}`;
};

const getLoader = (src: ImageLoaderProps["src"]) =>
  src.endsWith(".avif") ? undefined : cloudflareLoader;

const getSrc = (src: ImageLoaderProps["src"]) => {
  if (src.startsWith("https://")) return src;
  return `${process.env.NEXT_PUBLIC_IMAGES_CDN_URL}${src.startsWith("/") ? "" : "/"}${src}`;
};

type ImageProps = {
  alt: string;
  className?: string;
  fill?: boolean;
  height: number;
  priority?: boolean;
  sizes?: string;
  src: string;
  width: number;
};

const Image = ({
  alt,
  className,
  fill,
  height,
  priority,
  sizes,
  src,
  width,
}: ImageProps) => {
  return (
    <NextImage
      loader={getLoader(src)}
      className={className}
      fill={fill}
      src={getSrc(src)}
      alt={alt}
      width={width}
      height={height}
      priority={priority}
      sizes={sizes}
    />
  );
};

export default Image;
