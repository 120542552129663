import cn from "classnames";

import styles from "./index.module.scss";

export interface PillProps {
  icon?: JSX.Element;
  text: React.ReactNode;
  classNames?: string;
}

const Pill = ({ icon, text, classNames = "" }: PillProps) => (
  <span className={cn(styles.pill, classNames)}>
    {icon && icon}
    {text}
  </span>
);

export default Pill;
