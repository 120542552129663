export const SYLLABUS_VERSIONS = [
  [
    {
      basePath: "igcse/maths_extended/cie/23",
      year: "23",
      firstTeachingYear: "2021",
      examYear: "2024", // Last exam year if not newest
      newest: false,
    },
    {
      basePath: "igcse/maths_extended/cie/25",
      year: "25",
      firstTeachingYear: "2023",
      examYear: "2025", // First exam year if newest
      newest: true,
    },
  ],
  [
    {
      basePath: "igcse/maths_core/cie/23",
      year: "23",
      firstTeachingYear: "2021",
      examYear: "2024", // Last exam year if not newest
      newest: false,
    },
    {
      basePath: "igcse/maths_core/cie/25",
      year: "25",
      firstTeachingYear: "2023",
      examYear: "2025", // First exam year if newest
      newest: true,
    },
  ],
  [
    {
      basePath: "dp/biology_sl/ib/16",
      year: "16",
      firstTeachingYear: "2014",
      examYear: "2024",
      newest: false,
    },
    {
      basePath: "dp/biology/sl/25",
      year: "25",
      firstTeachingYear: "2023",
      examYear: "2025",
      newest: true,
    },
  ],
  [
    {
      basePath: "dp/biology_hl/ib/16",
      year: "16",
      firstTeachingYear: "2014",
      examYear: "2024",
      newest: false,
    },
    {
      basePath: "dp/biology/hl/25",
      year: "25",
      firstTeachingYear: "2023",
      examYear: "2025",
      newest: true,
    },
  ],
  [
    {
      basePath: "dp/chemistry_sl/ib/16",
      year: "16",
      firstTeachingYear: "2014",
      examYear: "2024",
      newest: false,
    },
    {
      basePath: "dp/chemistry/sl/25",
      year: "25",
      firstTeachingYear: "2023",
      examYear: "2025",
      newest: true,
    },
  ],
  [
    {
      basePath: "dp/chemistry_hl/ib/16",
      year: "16",
      firstTeachingYear: "2014",
      examYear: "2024",
      newest: false,
    },
    {
      basePath: "dp/chemistry/hl/25",
      year: "25",
      firstTeachingYear: "2023",
      examYear: "2025",
      newest: true,
    },
  ],
  [
    {
      basePath: "dp/physics_sl/ib/16",
      year: "16",
      firstTeachingYear: "2014",
      examYear: "2024",
      newest: false,
    },
    {
      basePath: "dp/physics/sl/25",
      year: "25",
      firstTeachingYear: "2023",
      examYear: "2025",
      newest: true,
    },
  ],
  [
    {
      basePath: "dp/physics_hl/ib/16",
      year: "16",
      firstTeachingYear: "2014",
      examYear: "2024",
      newest: false,
    },
    {
      basePath: "dp/physics/hl/25",
      year: "25",
      firstTeachingYear: "2023",
      examYear: "2025",
      newest: true,
    },
  ],
  [
    {
      basePath: "a-level/biology/cie/22",
      year: "22",
      firstTeachingYear: "2020",
      examYear: "2024",
      newest: false,
    },
    {
      basePath: "a-level/biology/cie/25",
      year: "25",
      firstTeachingYear: "2023",
      examYear: "2025",
      newest: true,
    },
  ],
  [
    {
      basePath: "a-level/chemistry/cie/22",
      year: "22",
      firstTeachingYear: "2020",
      examYear: "2024",
      newest: false,
    },
    {
      basePath: "a-level/chemistry/cie/25",
      year: "25",
      firstTeachingYear: "2023",
      examYear: "2025",
      newest: true,
    },
  ],
  [
    {
      basePath: "a-level/physics/cie/22",
      year: "22",
      firstTeachingYear: "2020",
      examYear: "2024",
      newest: false,
    },
    {
      basePath: "a-level/physics/cie/25",
      year: "25",
      firstTeachingYear: "2023",
      examYear: "2025",
      newest: true,
    },
  ],
  [
    {
      basePath: "as/biology/cie/22",
      year: "22",
      firstTeachingYear: "2020",
      examYear: "2024",
      newest: false,
    },
    {
      basePath: "as/biology/cie/25",
      year: "25",
      firstTeachingYear: "2023",
      examYear: "2025",
      newest: true,
    },
  ],
  [
    {
      basePath: "as/chemistry/cie/22",
      year: "22",
      firstTeachingYear: "2020",
      examYear: "2024",
      newest: false,
    },
    {
      basePath: "as/chemistry/cie/25",
      year: "25",
      firstTeachingYear: "2023",
      examYear: "2025",
      newest: true,
    },
  ],
  [
    {
      basePath: "as/physics/cie/22",
      year: "22",
      firstTeachingYear: "2020",
      examYear: "2024",
      newest: false,
    },
    {
      basePath: "as/physics/cie/25",
      year: "25",
      firstTeachingYear: "2023",
      examYear: "2025",
      newest: true,
    },
  ],
];
