import Script from "next/script";

const DebugBearScript = () => (
  <Script
    async
    id="debugbear-rum"
    src="https://cdn.debugbear.com/aUqtzGHsvdtL.js"
  />
);

export default DebugBearScript;
