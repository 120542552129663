import { useEffect, useReducer } from "react";

const isBrowser = typeof document !== "undefined";

const reducer = () => true;

const ProgressiveHydration = ({
  children,
  whenIdle,
  force,
}: {
  children: React.ReactElement;
  whenIdle?: boolean;
  force?: boolean;
}) => {
  // Always render on server by setting hydrated to true
  const [hydrated, hydrate] = useReducer(reducer, !isBrowser);

  useEffect(() => {
    if (hydrated) return;

    if (force) hydrate();
  }, [hydrated, force]);

  useEffect(() => {
    if (hydrated) return;

    const cleanupFns = [];

    if (whenIdle) {
      // Always hydrate after 1s
      const idleCallbackId = window.requestIdleCallback(hydrate, {
        timeout: 1000,
      });

      cleanupFns.push(() => {
        window.cancelIdleCallback(idleCallbackId);
      });
    }

    return () => {
      cleanupFns.forEach((fn) => fn());
    };
  }, [hydrated, whenIdle]);

  if (hydrated) {
    return children;
  } else {
    // React ignores dangerouslySetInnerHTML during hydration, allowing us to defer hydration of parts of the page.
    // Importantly, React does not replace the SSR generated HTML with an empty string - the HTML exists in the DOM but
    // won't be hydrated until `hydrated` is true.
    return (
      <div suppressHydrationWarning dangerouslySetInnerHTML={{ __html: "" }} />
    );
  }
};

export default ProgressiveHydration;
