import Button from "@/components/Button";
import LogoutLink from "@/components/LogoutLink";
import ROUTES from "@/config/data/routes";
import { EVENT_TYPES, EVENT_VALUES } from "@/config/data/tracking";
import { useUser } from "@/lib/hooks/user";
import * as tracking from "@/lib/tracking";
import ExpandMore from "@material-design-icons/svg/filled/expand_more.svg";
import PersonIcon from "@material-design-icons/svg/filled/person.svg";
import SettingsIcon from "@material-design-icons/svg/filled/settings.svg";
import CloseIcon from "@material-design-icons/svg/filled/close.svg";
import MenuIcon from "@material-design-icons/svg/filled/menu.svg";
import cn from "classnames";
import Link from "next/link";
import {
  forwardRef,
  type MouseEventHandler,
  type PropsWithChildren,
  useState,
} from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Icon from "src/components/Icon";
import RouterProgressBar from "src/components/RouterProgressBar";
import { KEYS } from "src/config/data/localStorage";
import * as ls from "src/lib/localStorage";
import DesktopNav from "./components/DesktopNav";
import JoinLink from "./components/JoinLink";
import LaunchpadIcon from "./components/LaunchpadIcon";
import LoginModal from "./components/LoginModal";
import Logo from "./components/Logo";
import MobileNav from "./components/MobileNav";
import NexusPanel from "./components/NexusPanel";
import styles from "./index.module.scss";
import JoinModal from "@/components/Navbar/components/JoinModal";
import DesktopNavV2 from "./components/DesktopNavV2";
import ButtonIcon from "../ButtonIcon";
import { useMediaQuery } from "@/lib/hooks/useMediaQuery";

export type TrackInteractionFn = (
  interaction: (typeof EVENT_VALUES.navigationBarInteraction)[keyof typeof EVENT_VALUES.navigationBarInteraction],
  data: string[],
) => void;
export const trackInteraction: TrackInteractionFn = (interaction, data) => {
  const levels = data.reduce<Record<string, string>>(
    (acc, item, index) => ({ ...acc, [`level_${index + 1}`]: item }),
    {},
  );
  tracking.event(EVENT_TYPES.interactedWithNavigationBar, {
    interaction,
    navigationBar: { ...levels },
  });
};

type NavbarDropdownToggleProps = PropsWithChildren<{
  "aria-expanded": boolean;
  id: string;
  className: string;
  onClick: MouseEventHandler<HTMLAnchorElement>;
}>;
const NavbarDropdownToggle = forwardRef<
  HTMLAnchorElement,
  NavbarDropdownToggleProps
>(
  (
    { children, onClick, className, id, "aria-expanded": ariaExpanded },
    ref,
  ) => {
    const { isMember, isStaff } = useUser();
    return (
      <a
        href="#"
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
        className={cn(className, "nav-link", {
          [styles.isMemberLink]: isMember && !isStaff,
        })}
        role="button"
        data-cy="my-account-dropdown"
        id={id}
        aria-expanded={ariaExpanded}
      >
        {children}
      </a>
    );
  },
);
NavbarDropdownToggle.displayName = "NavbarDropdownToggle";

export default function NavBar() {
  const { user } = useUser();
  const isNewNavEnabled = true;
  const [showMainMenu, setShowMainMenu] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 992px)", {
    initializeWithValue: false,
  });

  const handleCloseMenu = () => {
    setShowMainMenu(false);
  };

  const trackCtaClick = () => {
    ls.setItem(
      KEYS.joinPageViewTrigger,
      EVENT_VALUES.viewJoinPageTriggers.navbarJoin,
    );

    tracking.event(EVENT_TYPES.clickedNavigationBarJoinButton, {
      buttonLocation: EVENT_VALUES.buttonLocation.navigationBar,
    });
  };

  const studyMenuTitle = "Start studying";

  return (
    <nav>
      {/* The navbar is the only component that needs data-bs-theme adding directly to the HTML tag */}
      <div
        className={cn(
          styles.navbar,
          "navbar navbar-expand-lg bg-body-secondary shadow fixed-top",
        )}
      >
        <RouterProgressBar />
        <div className="container-fluid">
          <Logo />

          <div className="collapse navbar-collapse">
            {!isNewNavEnabled && <DesktopNav />}

            {isNewNavEnabled && (
              <ul className="navbar-nav ms-3">
                <li className="nav-item">
                  <Button
                    className={cn("nav-link", styles.desktopDropdownLink, {
                      [styles.desktopDropdownLinkExpanded]: showMainMenu,
                    })}
                    onClick={() => {
                      if (!showMainMenu) {
                        tracking.event(
                          EVENT_TYPES.interactedWithNavigationBar,
                          {
                            interaction:
                              EVENT_VALUES.navigationBarInteraction
                                .openedStartStudyingMenu,
                          },
                        );
                      }
                      setShowMainMenu(!showMainMenu);
                    }}
                    aria-expanded={showMainMenu}
                    aria-controls="navigation-dropdown"
                    aria-label={
                      showMainMenu
                        ? "Close site navigation"
                        : "Open site navigation"
                    }
                  >
                    {studyMenuTitle}{" "}
                    <Icon
                      svg={ExpandMore}
                      className={cn(styles.icon, {
                        [styles.expandedIcon]: showMainMenu,
                      })}
                    />
                  </Button>
                  {isNewNavEnabled && isDesktop && (
                    <DesktopNavV2
                      showMainMenu={showMainMenu}
                      handleCloseMenu={handleCloseMenu}
                    />
                  )}
                </li>
              </ul>
            )}

            <ul className="navbar-nav ms-auto">
              {!user && (
                <li className="nav-item">
                  <LoginModal buttonClassName="nav-link border-0" />
                </li>
              )}
              {user && (
                <>
                  <li className="nav-item">
                    <Dropdown>
                      <Dropdown.Toggle as={NavbarDropdownToggle}>
                        My account{" "}
                        <Icon
                          svg={ExpandMore}
                          size="S"
                          className={styles.accountChevron}
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className={styles.accountDropdown}>
                        <Dropdown.ItemText className="p-0">
                          <Button
                            tag={Link}
                            className={cn(
                              "dropdown-item focus-ring",
                              styles.dropdownItem,
                            )}
                            href={ROUTES.PAGES.MEMBERS}
                            iconAtStart
                            icon={<LaunchpadIcon />}
                            prefetch={false}
                          >
                            Launchpad
                          </Button>
                        </Dropdown.ItemText>
                        <Dropdown.ItemText className="p-0">
                          <Button
                            tag={Link}
                            className={cn(
                              "dropdown-item focus-ring",
                              styles.dropdownItem,
                            )}
                            href={ROUTES.PAGES.ONBOARDING.ABOUT_YOU}
                            iconAtStart
                            icon={
                              <Icon
                                svg={PersonIcon}
                                className={styles.dropdownItemIcon}
                              />
                            }
                            prefetch={false}
                          >
                            My learner profile
                          </Button>
                        </Dropdown.ItemText>
                        <Dropdown.ItemText className="p-0">
                          <Button
                            tag={Link}
                            className={cn(
                              "dropdown-item focus-ring",
                              styles.dropdownItem,
                            )}
                            href={ROUTES.PAGES.ACCOUNT}
                            iconAtStart
                            icon={
                              <Icon
                                svg={SettingsIcon}
                                className={styles.dropdownItemIcon}
                              />
                            }
                            prefetch={false}
                          >
                            Settings
                          </Button>
                        </Dropdown.ItemText>
                        <Dropdown.Divider />
                        <Dropdown.ItemText className="p-0">
                          <LogoutLink
                            icon
                            className={cn(
                              "dropdown-item focus-ring",
                              styles.dropdownItem,
                            )}
                            data-cy="log-out-button"
                          />
                        </Dropdown.ItemText>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                </>
              )}
            </ul>
          </div>

          <div className="d-flex align-items-center">
            {!user && (
              <JoinModal
                text="Join now"
                variant="primary"
                onClick={trackCtaClick}
                className="ms-sm-2 w-100"
                trigger={EVENT_VALUES.shownRegistrationFormTrigger.navBarCta}
              />
            )}

            {user && (
              <JoinLink
                text="Upgrade"
                buttonLocation={EVENT_VALUES.buttonLocation.navigationBar}
                pageReferralMethod={
                  EVENT_VALUES.viewJoinPageTriggers.navbarJoin
                }
                href={ROUTES.PAGES.ONBOARDING.YOUR_PLAN}
                onClick={() =>
                  ls.setItem(
                    KEYS.planPageViewTrigger,
                    EVENT_VALUES.viewJoinPageTriggers.navbarJoin,
                  )
                }
              />
            )}

            {isNewNavEnabled && (
              <ButtonIcon
                ariaLabel={
                  showMainMenu
                    ? "Close site navigation"
                    : "Open site navigation"
                }
                icon={<Icon svg={showMainMenu ? CloseIcon : MenuIcon} />}
                className={styles.toggler}
                type="button"
                aria-controls="navigation-dropdown"
                variant="outlined"
                onClick={() => {
                  if (!showMainMenu) {
                    tracking.event(EVENT_TYPES.interactedWithNavigationBar, {
                      interaction:
                        EVENT_VALUES.navigationBarInteraction
                          .openedStartStudyingMenu,
                    });
                  }
                  setShowMainMenu(!showMainMenu);
                }}
              />
            )}
            <NexusPanel />
            {!isNewNavEnabled && <MobileNav />}
          </div>
        </div>
      </div>
      {isNewNavEnabled && !isDesktop && (
        <DesktopNavV2
          showMainMenu={showMainMenu}
          handleCloseMenu={handleCloseMenu}
        />
      )}
    </nav>
  );
}
