export const PAST_PAPERS_PAGE_CONFIG = {
  levels: [
    { title: "GCSE Past Papers", url: "/gcse/" },
    { title: "IGCSE Past Papers", url: "/igcse/" },
    { title: "AS Past Papers", url: "/as/" },
    { title: "A Level Past Papers", url: "/a-level/" },
    { title: "O Level Past Papers", url: "/o-level/" },
    { title: "AP Past Exams", url: "/ap/" },
  ],
  boards: [
    { title: "AQA Past Papers", url: "/aqa/" },
    { title: "CIE Past Papers", url: "/cie/" },
    { title: "Edexcel Past Papers", url: "/edexcel/" },
    { title: "OCR Past Papers", url: "/ocr/" },
    { title: "Oxford AQA Past Papers", url: "/oxford-aqa/" },
    { title: "WJEC Past Papers", url: "/wjec/" },
    { title: "WJEC Eduqas Past Papers", url: "/wjec-eduqas/" },
  ],
};
