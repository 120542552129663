import Image from "@/components/Image";
import Link from "next/link";
import cn from "classnames";

import { useUser } from "src/lib/hooks/user";
import { useDarkMode } from "src/lib/hooks/darkMode";
import ROUTES from "src/config/data/routes";

import styles from "./index.module.scss";

const Logo = ({ reloadOnClick = false }) => {
  const { user } = useUser();
  const { isDarkMode } = useDarkMode();
  const href = user ? ROUTES.PAGES.MEMBERS : ROUTES.PAGES.HOME;

  return (
    <Link
      href={href}
      className="navbar-brand d-inline-flex align-items-center"
      prefetch={false}
      onClick={(e) => {
        /**
         * Workaround to ensure that if Logo is used on a statically generated page (like 404 or _error) the page fully
         * reloads on click so that values in public runtime config can be loaded in (only available on SSR pages).
         */
        if (reloadOnClick) {
          e.preventDefault();
          window.location.href = href;
        }
      }}
    >
      <Image
        priority
        alt={
          user ? "Your Launchpad - Save My Exams" : "Home Page - Save My Exams"
        }
        className={cn(styles.logo1Line)}
        height="40"
        src={`/logo/sme-logo-small${isDarkMode ? "-dark" : ""}.svg`}
        width="185"
      />

      <Image
        alt={
          user ? "Your Launchpad - Save My Exams" : "Home Page - Save My Exams"
        }
        className="d-none d-lg-block d-xl-none"
        height="40"
        src={`/logo/sme-logo-3line${isDarkMode ? "-dark" : ""}.svg`}
        width="67"
      />
      <span className="visually-hidden">Home</span>
    </Link>
  );
};

export default Logo;
