import useSWR from "swr";

import ROUTES from "src/config/data/routes";
import fetcher from "src/lib/fetcher";
import {
  LEGACY_TEACHER_TUTOR,
  TEACHER,
  TUTOR,
} from "src/config/data/relationships";
import * as tracking from "src/lib/tracking";

export const useUser = () => {
  const { data } = useSWR(ROUTES.API.USER, fetcher, {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
    revalidateIfStale: false, // Use fallback data if provided to SWRConfig in _app.js
    onSuccess: (data) => {
      // Once we refactor the src/lib/tracking file - the identify call will get user directly from the SWR cache via the useUser hook, rather than from the window.
      window.Sme.data.user = data.user;
      tracking.identify();
    },
  });

  const user = data?.user;
  const isMember = user?.roles?.includes("member");
  const isStaff = user?.roles?.includes("staff");
  const isTeacherOrTutor =
    user?.relationship === TEACHER ||
    user?.relationship === TUTOR ||
    user?.relationship === LEGACY_TEACHER_TUTOR;

  const isRegisteredFreeUser = user && !isMember;

  return { user, isMember, isStaff, isTeacherOrTutor, isRegisteredFreeUser };
};
