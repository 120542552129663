import { useEffect } from "react";
import { useRouter } from "next/router";
import { SWRConfig } from "swr";

import * as tracking from "src/lib/tracking";
import useHideThemeToggle from "src/lib/hooks/useHideThemeToggle";
import ROUTES from "src/config/data/routes";
import RudderstackScript from "src/components/scripts/Rudderstack";
import InitGlobalsScript from "src/components/scripts/InitGlobals";
import DebugBearScript from "src/components/scripts/DebugBear";
import ThemeScript from "src/components/scripts/Theme";
import PaddleProfitWellScript from "src/components/scripts/PaddleProfitWell";
import RequestIdleCallbackShim from "src/components/scripts/RequestIdleCallbackShim";
import { UsersnapProvider } from "src/context/usersnap";
import GoogleAnalyticsTag from "src/components/scripts/GoogleAnalyticsTag.js";
import { GlobalProvider } from "src/context/global";
import useTokenRefresh from "src/lib/hooks/tokenRefresh";
import { useExperimentClientInit } from "src/lib/hooks/useExperimentClient";
import Theme from "src/components/Theme";

import styles from "./index.module.scss";

export default function PageWrapper({ children, pageProps }) {
  const router = useRouter();
  const user = pageProps?.user;
  const needsTokenRefresh = useTokenRefresh(pageProps.fromSupertokens);
  const hideThemeToggle = useHideThemeToggle();

  useEffect(() => {
    const initSupertokens = async () => {
      const { init } = await import("src/lib/supertokens/init");
      init();
    };

    window.requestIdleCallback(initSupertokens, { timeout: 2000 });
  }, []);

  useEffect(() => {
    const handleRouteChangeStart = (_url, { shallow }) => {
      if (shallow) {
        return;
      }

      Sme.track._previousLocation = { ...window.location };
      /* Setting viewedPageEventTriggered to false on route change ensures that a viewed other page track event is fired if no other viewed page track event is fired */
      Sme.track.viewedPageEventTriggered = false;
    };

    const handleRouteChangeComplete = () => {
      tracking.trackViewedOtherPage();
    };

    router.events.on("routeChangeStart", handleRouteChangeStart);
    router.events.on("routeChangeComplete", handleRouteChangeComplete);

    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, [router]);

  useEffect(() => {
    window.Sme.data.page = pageProps.pageData || {};
  }, [pageProps.pageData]);

  useEffect(() => {
    if (pageProps.smeVersion) {
      window.sme_version = pageProps.smeVersion;
    }
  }, [pageProps.smeVersion]);

  useEffect(() => {
    window.Sme.data.user = user;
    tracking.identify();
  }, [user?.hash]);

  useEffect(() => {
    tracking.trackViewedOtherPage();
  }, []);

  const isExperimentClientReady = useExperimentClientInit(pageProps.user?.hash);

  return (
    <>
      <ThemeScript />
      <RequestIdleCallbackShim />
      <RudderstackScript />
      <InitGlobalsScript />
      <DebugBearScript />
      <SWRConfig value={{ fallback: { [ROUTES.API.USER]: { user } } }}>
        <UsersnapProvider
          user={{
            createdAt: user?.createdAt,
            email: user?.email,
            hash: user?.hash,
            name: user?.name,
            roles: user?.roles,
          }}
          version={pageProps.smeVersion}
        >
          <GlobalProvider isExperimentClientReady={isExperimentClientReady}>
            {/* Requires user so must be inside SWRConfig */}
            <GoogleAnalyticsTag />
            <PaddleProfitWellScript />
            {needsTokenRefresh ? null : (
              <div className={styles.wrapper}>
                {children}
                {!hideThemeToggle && <Theme className={styles.themeButton} />}
              </div>
            )}
          </GlobalProvider>
        </UsersnapProvider>
      </SWRConfig>
    </>
  );
}
