import SignUpModal from "@/components/SignUp/SignUpModal";
import SignUpTitle, {
  SIGN_UP_NOW_TITLE,
} from "@/components/SignUp/SignUpTitle";
import { ShownRegistrationFormTrigger } from "@/config/types/tracking";
import Button, { ButtonProps } from "src/components/Button";
import { useModal } from "src/components/Modal";

type JoinModalProps = ButtonProps & {
  text: string;
  className?: string;
  trigger: ShownRegistrationFormTrigger;
  onClick?: () => void;
};

const JoinModal = ({
  text,
  trigger,
  variant = "primary",
  onClick,
  ...buttonProps
}: JoinModalProps) => {
  const signupModal = useModal();

  const handleClick = () => {
    signupModal.show();
    onClick?.();
  };

  return (
    <>
      <Button {...buttonProps} variant={variant} onClick={handleClick}>
        {text}
      </Button>
      <SignUpModal
        modal={signupModal}
        trigger={trigger}
        signUpOptionsTitle={<SignUpTitle heading={SIGN_UP_NOW_TITLE} />}
      />
    </>
  );
};

export default JoinModal;
