import cn from "classnames";
import {
  ComponentProps,
  ElementType,
  ForwardedRef,
  ReactNode,
  Ref,
  forwardRef,
} from "react";

import type Link from "next/link";

import styles from "./index.module.scss";

const BASE_CLASSES = {
  primary: "btn-primary",
  light: "btn-light",
  outlined: "btn-outlined",
  neutral: "btn-neutral",
  floating: "btn-floating",
  link: "btn-link",
  anchor: "btn-anchor",
  "link-primary": "btn-link-primary",
  gradient: "btn-gradient",
  "neutral-outlined": "btn-neutral-outlined",
  "gradient-outlined": "btn-gradient-outlined",
};
const SIZE_CLASSES = {
  S: "btn-sm",
  M: "",
  L: "btn-lg",
};

type ValidTags =
  | ElementType<unknown, keyof JSX.IntrinsicElements>
  | typeof Link;

type Props<T extends ValidTags = "button"> = {
  tag?: T;
  children?: ReactNode;
  ariaLabel?: string;
  className?: string;
  disabled?: boolean;
  flush?: boolean;
  loading?: boolean;
  icon?: ReactNode;
  iconAtStart?: boolean;
  iconOnlyOnMobile?: boolean;
  size?: keyof typeof SIZE_CLASSES;
  variant?: keyof typeof BASE_CLASSES;
  stretch?: boolean;
  wide?: boolean;
  contentPosition?: "start" | "center" | "end";
  selected?: boolean;
} & ComponentProps<T>;

/**

---

### Usage

```jsx
import Button from 'src/components/Button'
import Icon from "src/components/Icon";
import FileDownloadOutlinedIcon from "@material-design-icons/svg/outlined/file_download.svg";

<Button iconAtStart icon={<Icon svg={FileDownloadOutlinedIcon} />} variant="primary">Download</Button>
```

---

 */
const Button = <T extends ValidTags>(
  {
    ariaLabel,
    className,
    children,
    disabled = false,
    flush = false,
    loading = false,
    icon,
    iconAtStart = false,
    iconOnlyOnMobile,
    size = "M",
    tag,
    variant,
    stretch = false,
    wide = false,
    contentPosition = "center",
    selected = false,
    ...rest
  }: Props<T>,
  ref: ForwardedRef<Ref<T>>,
) => {
  const Element = (tag || "button") as ElementType;

  return (
    <Element
      aria-label={ariaLabel}
      className={cn(
        "btn",
        BASE_CLASSES[variant],
        SIZE_CLASSES[size],
        {
          [styles.loading]: loading,
          [styles.stretch]: stretch,
          [styles.wide]: wide,
          [styles.flush]: flush,
          [styles.iconOnlyOnMobile]: iconOnlyOnMobile,
          "position-relative": loading,
          "justify-content-start": contentPosition === "start",
          "justify-content-center": contentPosition === "center",
          "justify-content-end": contentPosition === "end",
          selected,
        },
        className,
      )}
      disabled={disabled}
      ref={ref}
      {...rest}
    >
      {icon && iconAtStart && (
        <span
          className={cn(styles.icon, {
            "me-md-2": iconOnlyOnMobile,
            "me-2": !iconOnlyOnMobile,
            "opacity-0": loading,
          })}
        >
          {icon}
        </span>
      )}

      {loading && (
        <div className="d-flex gap-2 align-items-center position-absolute top-50 start-50 translate-middle">
          <div className="spinner-border spinner-border-sm"></div>
        </div>
      )}
      <span
        className={cn({
          "d-none d-md-inline": iconOnlyOnMobile,
          "flex-grow-1": !icon,
          "opacity-0": loading,
        })}
      >
        {children}
      </span>

      {icon && !iconAtStart && (
        <span
          className={cn(styles.icon, {
            "ms-md-2": iconOnlyOnMobile,
            "ms-2": !iconOnlyOnMobile,
            "opacity-0": loading,
          })}
        >
          {icon}
        </span>
      )}
    </Element>
  );
};

export default forwardRef(Button) as <T extends ValidTags = "button">(
  props: Props<T>,
) => ReturnType<typeof Button>;
export type { Props as ButtonProps };
