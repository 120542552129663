import PropTypes from "prop-types";

import styles from "./index.module.scss";

const Switch = ({ label, id, checked, onChange, value, dataCy }) => {
  return (
    <div className={styles.wrapper}>
      <label className={styles.switch}>
        <input
          type="checkbox"
          id={id}
          checked={checked}
          onChange={onChange}
          role="switch"
          value={value}
          data-cy={dataCy}
        />
        <span className={styles.slider}></span>
      </label>
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export default Switch;

Switch.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.bool,
  dataCy: PropTypes.string,
};
