const pearson = "Pearson";

const solutionBankBaseUrl = "/learning-hub/useful-resources/";

const courses = {
  "a-level/maths/edexcel": {
    pageTitle: "Edexcel A Level Maths",
    resources: [
      {
        publisherName: pearson,
        courseName: "Pure Maths 1",
        url: `${solutionBankBaseUrl}edexcel-core-pure-maths-1-a-level-solution-bank/`,
      },
      {
        publisherName: pearson,
        courseName: "Pure Maths 2",
        url: `${solutionBankBaseUrl}edexcel-core-pure-maths-2-a-level-solution-bank/`,
      },
      {
        publisherName: pearson,
        courseName: "Decision Maths 1",
        url: `${solutionBankBaseUrl}edexcel-decision-maths-1-a-level-solution-bank/`,
      },
      {
        publisherName: pearson,
        courseName: "Decision Maths 2",
        url: `${solutionBankBaseUrl}edexcel-decision-maths-2-a-level-solution-bank/`,
      },
      {
        publisherName: pearson,
        courseName: "Pure Maths Year 1",
        url: `${solutionBankBaseUrl}edexcel-pure-maths-year-1-a-level-solution-bank/`,
      },
      {
        publisherName: pearson,
        courseName: "Pure Maths Year 2",
        url: `${solutionBankBaseUrl}edexcel-pure-maths-year-2-a-level-solution-bank/`,
      },
      {
        publisherName: pearson,
        courseName: "Stats and Mechanics Year 1",
        url: `${solutionBankBaseUrl}edexcel-stats-and-mechanics-year-1-a-level-solution-bank/`,
      },
      {
        publisherName: pearson,
        courseName: "Stats and Mechanics Year 2",
        url: `${solutionBankBaseUrl}edexcel-stats-and-mechanics-year-2-a-level-solution-bank/`,
      },
    ],
  },
  "a-level/further-maths/edexcel": {
    pageTitle: "Edexcel A Level Further Maths",
    resources: [
      {
        publisherName: pearson,
        courseName: "Further Mechanics 1",
        url: `${solutionBankBaseUrl}edexcel-further-mechanics-1-a-level-solution-bank/`,
      },
      {
        publisherName: pearson,
        courseName: "Further Mechanics 2",
        url: `${solutionBankBaseUrl}edexcel-further-mechanics-2-a-level-solution-bank/`,
      },
      {
        publisherName: pearson,
        courseName: "Further Pure Maths 1",
        url: `${solutionBankBaseUrl}edexcel-further-pure-maths-1-a-level-solution-bank/`,
      },
      {
        publisherName: pearson,
        courseName: "Further Pure Maths 2",
        url: `${solutionBankBaseUrl}edexcel-further-pure-maths-2-a-level-solution-bank/`,
      },
      {
        publisherName: pearson,
        courseName: "Further Statistics 1",
        url: `${solutionBankBaseUrl}edexcel-further-statistics-1-a-level-solution-bank/`,
      },
      {
        publisherName: pearson,
        courseName: "Further Statistics 2",
        url: `${solutionBankBaseUrl}edexcel-further-statistics-2-a-level-solution-bank/`,
      },
    ],
  },
};

export const SOLUTION_BANKS_CONFIG = {
  courses,
  creatorQuotes: [
    {
      creator: {
        name: "Dan Finlay",
        role: "Maths Lead",
        url: "/authors/daniel-finlay/",
        image: {
          url: "/images/experts/expert-daniel-finlay.webp",
          alt: "Maths Lead Dan Finlay",
        },
      },
      content:
        "If you get the correct answer in an exam using an incorrect method then you will not get full marks. Therefore it is important to check your method against the worked solutions when practising questions. You might even learn a quicker way to solve the problem.",
    },
    {
      creator: {
        name: "Jamie Wood",
        role: "Maths Expert",
        url: "/authors/jamie-wood/",
        image: {
          url: "/images/experts/jamie.jpg",
          alt: "Maths Expert Jamie Wood",
        },
      },
      content:
        "A source of frustration when revising can be when you check your final answer, and see that it's wrong! The best way to move forward is to use a worked solution to see where you went wrong. If you can't get started on a problem, then a worked solution can give you a hint on how to start unlocking it. Even if your answer is correct, worked solutions may show you an interesting or more effective way to answer the question.",
    },
  ],
};
