import { useUser } from "src/lib/hooks/user";
import Script from "next/script";
import getConfig from "next/config";

const {
  publicRuntimeConfig: { googleAnalyticsMeasurementId },
} = getConfig();

const GoogleAnalyticsTag = () => {
  const { user } = useUser();
  return (
    <>
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsMeasurementId}`}
      />
      <Script
        id="google-analytics-4-tag"
        dangerouslySetInnerHTML={{
          __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${googleAnalyticsMeasurementId}', {
                        'user_id': '${user?.hash || ""}' || null,
                        'user_type': '${user?.relationship || ""}' || null
                    });
                `,
        }}
      />
    </>
  );
};

export default GoogleAnalyticsTag;
