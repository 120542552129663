import Link from "next/link";
import cn from "classnames";
import {
  FacebookIcon,
  InstagramIcon,
  TikTokIcon,
  TwitterXIcon,
} from "../icons";

import * as ls from "@/lib/localStorage";

import Image from "@/components/Image";
import { P } from "@/components/text";
import Button from "@/components/Button";
import LogoutLink from "@/components/LogoutLink";
import LoginLink from "@/components/LoginLink";
import { useUser } from "@/lib/hooks/user";
import { useUsersnapContext } from "@/context/usersnap";

import ROUTES from "@/config/data/routes";
import { KEYS } from "@/config/data/localStorage";
import { EVENT_VALUES } from "@/config/data/tracking";

import styles from "./index.module.scss";

const FooterLink = ({ children, ...rest }) => (
  <Link className={styles.link} prefetch={false} {...rest}>
    {children}
  </Link>
);

const Footer = () => {
  const { user } = useUser();
  const usersnapApi = useUsersnapContext();

  const handleJoinClick = () => {
    ls.setItem(
      KEYS.joinPageViewTrigger,
      EVENT_VALUES.viewJoinPageTriggers.websiteFooter,
    );
  };

  return (
    <footer className={cn("container-fluid", styles.footer)}>
      <div className={cn("container", styles.footerContainer)}>
        <div className="row mb-4">
          <div className="col-lg-3 col-md-12 mb-4 mb-lg-0">
            <Link
              href={user ? ROUTES.PAGES.MEMBERS : ROUTES.PAGES.HOME}
              prefetch={false}
            >
              <Image alt="" height={35} src="/logo/sme-icon.svg" width={22} />
              <span className="visually-hidden">
                {user ? "Your Launchpad" : "Home"}
              </span>
            </Link>
            <br />
          </div>
          <div className="col-6 col-md-3 col-lg-2 mb-4 mb-lg-0">
            <span className="h6 d-block">Resources</span>
            <ul className="list-unstyled mb-0">
              {!user && (
                <li>
                  <FooterLink href={ROUTES.PAGES.HOME}>Home</FooterLink>
                </li>
              )}
              <li>
                <FooterLink href={ROUTES.PAGES.BLOG}>Learning Hub</FooterLink>
              </li>
              <li>
                <FooterLink href={ROUTES.PAGES.CONTACT}>
                  Help and Support
                </FooterLink>
              </li>
              <li>
                <FooterLink href={ROUTES.PAGES.SCHOLARSHIP}>
                  Scholarship
                </FooterLink>
              </li>
              <li>
                <FooterLink href={ROUTES.PAGES.JOIN} onClick={handleJoinClick}>
                  Join
                </FooterLink>
              </li>
              <li>
                <FooterLink href={ROUTES.PAGES.ARCHIVE}>Archive</FooterLink>
              </li>
              <li>
                <FooterLink href={ROUTES.PAGES.PAST_PAPERS}>
                  Past Papers
                </FooterLink>
              </li>
              <li>
                <FooterLink href={ROUTES.PAGES.SOLUTION_BANKS}>
                  Solution Banks
                </FooterLink>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md-3 col-lg-2 mb-4 mb-lg-0">
            <span className="h6 d-block">Members</span>
            <ul className="list-unstyled mb-0">
              {user && (
                <>
                  <li>
                    <FooterLink href={ROUTES.PAGES.MEMBERS}>
                      Launchpad
                    </FooterLink>
                  </li>
                  <li>
                    <FooterLink href={ROUTES.PAGES.ACCOUNT}>Account</FooterLink>
                  </li>
                  <li>
                    <LogoutLink className={styles.link} />
                  </li>
                </>
              )}
              {!user && (
                <li>
                  <LoginLink
                    data-cy="footer-log-in-button"
                    className={styles.link}
                  />
                </li>
              )}
            </ul>
          </div>
          <div className="col-6 col-md-3 col-lg-2">
            <span className="h6 d-block">Company</span>
            <ul className="list-unstyled mb-0">
              <li>
                <FooterLink href={ROUTES.PAGES.ABOUT}>About us</FooterLink>
              </li>
              <li>
                <FooterLink href={ROUTES.PAGES.CONTENT_QUALITY}>
                  Content Quality
                </FooterLink>
              </li>
              <li>
                <FooterLink href={ROUTES.PAGES.JOBS}>Jobs</FooterLink>
              </li>
              <li>
                <FooterLink href={ROUTES.PAGES.TERMS}>Terms</FooterLink>
              </li>
              <li>
                <FooterLink href={ROUTES.PAGES.PRIVACY}>Privacy</FooterLink>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md-3 col-lg-3">
            <span className="h6 d-block">Subjects</span>
            <ul className="list-unstyled mb-0">
              <li>
                <FooterLink href="/-/biology/">Biology</FooterLink>
              </li>
              <li>
                <FooterLink href="/-/chemistry/">Chemistry</FooterLink>
              </li>
              <li>
                <FooterLink href="/-/physics/">Physics</FooterLink>
              </li>
              <li>
                <FooterLink href="/-/maths/">Maths</FooterLink>
              </li>
              <li>
                <FooterLink href="/-/geography/">Geography</FooterLink>
              </li>
              <li>
                <FooterLink href="/-/english-literature/">
                  English Literature
                </FooterLink>
              </li>
              <li>
                <FooterLink href="/-/psychology/">Psychology</FooterLink>
              </li>
              <li>
                <FooterLink href={ROUTES.PAGES.ALL_SUBJECTS}>
                  All Subjects
                </FooterLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="row flex-column flex-nowrap flex-md-row mb-4">
          <div className="col text-center text-md-start">
            <Button
              variant="link-primary"
              className="text-decoration-underline fw-normal"
              onClick={() =>
                usersnapApi?.logEvent("missing_something_button_clicked")
              }
            >
              Missing a Subject or Exam Board? Tell us
            </Button>
          </div>
          <div className="col col-md-4 d-flex justify-content-evenly justify-content-md-between justify-content-lg-evenly align-items-center mt-5 mt-md-0 mb-4">
            <a
              href="https://www.tiktok.com/@savemyexams"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              <TikTokIcon className="me-3" />
              <span className="visually-hidden">TikTok</span>
            </a>
            <a
              href="https://www.instagram.com/savemyexams/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              <InstagramIcon className="me-3 ms-3" />
              <span className="visually-hidden">Instagram</span>
            </a>
            <a
              href="https://www.facebook.com/SaveMyExamsUK/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              <FacebookIcon className="me-3 ms-3" />
              <span className="visually-hidden">Facebook</span>
            </a>
            <a
              href="https://twitter.com/SaveMyExams/"
              target="_blank"
              rel="noopener noreferrer"
              className={cn(styles.link)}
            >
              <TwitterXIcon className="ms-3" />
              <span className="visually-hidden">Twitter</span>
            </a>
          </div>
        </div>
        <div className="mx-2">
          <P size="S" className={styles.copyright}>
            &copy; Copyright 2015-{new Date().getFullYear()} Save My Exams Ltd.
            All Rights Reserved.
          </P>
          <P size="S" className={styles.disclaimer}>
            IBO was not involved in the production of, and does not endorse, the
            resources created by Save My Exams.
          </P>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
