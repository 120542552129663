import Footer from "src/components/Footer";
import NavBar from "src/components/Navbar";

import styles from "./index.module.scss";

export default function DefaultLayout({ children }) {
  return (
    <>
      <NavBar />
      <div id="page" className={styles.page}>
        {children}
      </div>

      <Footer />
    </>
  );
}
