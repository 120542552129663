import { useRouter } from "next/router";
import { Suspense, lazy, useEffect } from "react";

import SignUpLoading from "@/components/AuthForms/SignUpLoading";
import Modal from "@/components/Modal";
import type { SignUpProps } from "src/components/AuthForms/SignUp";
import { trackInteractedWithRegistrationPage } from "src/components/AuthForms/helpers/tracking";
import { EVENT_VALUES } from "src/config/data/tracking";
import Banner from "../SignUpBanner";

const SignUp = lazy(() => import("src/components/AuthForms/SignUp"));

export interface SignUpModalProps extends SignUpProps {
  bannerCopy?: React.ReactNode;
  signUpOptionsTitle?: React.ReactElement;
  modal: {
    isShown: boolean;
    isShowing: boolean;
    hasClosed: boolean;
    show: () => void;
    hide: () => void;
  };
}

const SignUpModal = ({ bannerCopy, modal, ...props }: SignUpModalProps) => {
  const router = useRouter();

  const hideModal = () => modal.hide();

  useEffect(() => {
    router.events.on("routeChangeComplete", hideModal);

    return () => {
      router.events.off("routeChangeComplete", hideModal);
    };
  }, [router, modal]);

  return (
    <Modal
      {...modal}
      header={bannerCopy && <Banner copy={bannerCopy} onClose={hideModal} />}
      onExit={() =>
        trackInteractedWithRegistrationPage({
          interaction: EVENT_VALUES.engageRegistrationInteraction.closedModal,
          pageReferralMethod: props.trigger,
        })
      }
      content={
        <Suspense fallback={<SignUpLoading />}>
          <SignUp {...props} />
        </Suspense>
      }
    />
  );
};

export default SignUpModal;
