import PropTypes from "prop-types";
import cn from "classnames";

import styles from "./index.module.scss";

const SIZE_CLASS_NAMES = {
  S: styles.small,
  M: styles.medium,
  L: styles.large,
  XL: styles.xlarge,
};

const VARIANT_CLASS_NAMES = {
  secondary: styles.secondary,
};

const P = ({ children, className, size, strong, variant }) => {
  return (
    <p
      className={cn(
        className,
        SIZE_CLASS_NAMES[size],
        VARIANT_CLASS_NAMES[variant],
        {
          [styles.strong]: strong,
        },
      )}
    >
      {children}
    </p>
  );
};

export default P;

P.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(SIZE_CLASS_NAMES)),
  variant: PropTypes.oneOf(Object.keys(VARIANT_CLASS_NAMES)),
  strong: PropTypes.bool,
};

P.defaultProps = {
  strong: false,
  className: undefined,
  size: "M",
};
