/**
 * Search for icons here: https://marella.me/material-design-icons/demo/svg/
 */

import cn from "classnames";
import {
  forwardRef,
  type ComponentType,
  type ElementRef,
  type ElementType,
  type ForwardedRef,
  type PropsWithChildren,
  type ReactNode,
} from "react";
import styles from "./index.module.scss";

type ValidTags = keyof JSX.IntrinsicElements;

type Props<T extends ValidTags> = PropsWithChildren<
  {
    /**
     * Change the tag from a button to something else like a Next.js Link
     */
    tag?: T | ComponentType;
    className?: string;
    ariaLabel: string;
    icon: ReactNode;
    loading?: boolean;
    size?: keyof typeof SIZE_CLASSES;
    variant?: keyof typeof BASE_CLASSES;
  } & JSX.IntrinsicElements[T]
>;

const BASE_CLASSES = {
  primary: "btn-primary",
  light: "btn-light",
  shadow: "btn-shadow",
  clear: "btn-clear",
  neutral: "btn-neutral",
  floating: "btn-floating",
  outlined: "btn-outlined",
};

const SIZE_CLASSES = {
  XS: styles.extraSmall,
  S: styles.small,
  M: styles.medium,
  L: styles.large,
};

/**

---

### Usage

```js
import ButtonIcon from 'src/components/ButtonIcon'
import Icon from "src/components/Icon";
import ArrowForwardIcon from "@material-design-icons/svg/filled/arrow_forward.svg";

<ButtonIcon ariaLabel="Next page" icon={<Icon svg={ArrowForwardIcon} />} variant="primary" />
```

### Notes

Search for icons [here](https://marella.me/material-design-icons/demo/svg/).
Additional props can be passed to the Icon component if needed (className and size):

`<ButtonIcon ariaLabel="Next page" icon={<Icon svg={ArrowForwardIcon} size="S" />} variant="primary" />`

---

 */
const ButtonIcon = <T extends ValidTags>(
  {
    ariaLabel,
    className,
    icon,
    loading,
    tag = "button" as T,
    variant,
    size = "M",
    ...rest
  }: Props<T>,
  ref: ForwardedRef<ElementRef<T>>,
) => {
  const Element = tag as ElementType;

  return (
    <Element
      ref={ref}
      aria-label={ariaLabel}
      className={cn(
        "btn",
        "justify-content-center",
        BASE_CLASSES[variant],
        SIZE_CLASSES[size],
        styles.button,
        { [styles.loading]: loading },
        className,
      )}
      title={ariaLabel}
      {...rest}
    >
      {loading ? (
        <>
          <span
            className="spinner-border spinner-border-sm flex-shrink-0"
            role="status"
            aria-hidden="true"
          />
          <span className="visually-hidden">Loading...</span>
        </>
      ) : (
        icon
      )}
    </Element>
  );
};

export default forwardRef(ButtonIcon) as <T extends ValidTags>(
  props: Props<T>,
) => ReturnType<typeof ButtonIcon>;
