import { useRouter } from "next/router";
import { Suspense, lazy, useEffect } from "react";

import SignInLoading from "src/components/AuthForms/SignInLoading";
import Button, { ButtonProps } from "src/components/Button";
import Modal, { useModal } from "src/components/Modal";
import { useUser } from "src/lib/hooks/user";
const SignIn = lazy(() => import("src/components/AuthForms/SignIn"));

type LoginModalProps = {
  buttonVariant?: ButtonProps["variant"];
  buttonClassName?: string;
  onClick?: () => void;
};

const LoginModal = ({
  buttonVariant,
  buttonClassName,
  onClick,
}: LoginModalProps) => {
  const { user } = useUser();
  const isLoggedIn = !!user;
  const router = useRouter();
  const loginModal = useModal();

  const handleClick = () => {
    onClick?.();
    loginModal.show();
  };

  /* Close modal when user successfully logs in */
  useEffect(() => {
    if (isLoggedIn) {
      loginModal.hide();
    }
  }, [isLoggedIn, loginModal]);

  /* Ensure modal is closed when any link inside the modal is clicked. */
  useEffect(() => {
    const hideModal = () => loginModal.hide();

    router.events.on("routeChangeComplete", hideModal);

    return () => {
      router.events.off("routeChangeComplete", hideModal);
    };
  }, [loginModal, router]);

  return (
    <>
      <Button
        className={buttonClassName}
        data-cy="log-in-button"
        onClick={handleClick}
        type="button"
        variant={buttonVariant}
      >
        Log in
      </Button>
      <Modal
        {...loginModal}
        content={
          <Suspense fallback={<SignInLoading />}>
            <SignIn parent="modal" />
          </Suspense>
        }
      />
    </>
  );
};

export default LoginModal;
