import PropTypes from "prop-types";
import cn from "classnames";

import styles from "./index.module.scss";

const BASE_CLASSES = {
  default: "default",
  muted: "muted",
};

const Tag = ({ className, labels, variant = "default" }) => {
  return (
    <span className={cn(className, styles[BASE_CLASSES[variant]])}>
      {labels
        .filter((label) => label)
        .map((label, index) => (
          <span key={index} className={styles.label}>
            {label}
          </span>
        ))}
    </span>
  );
};

export default Tag;

Tag.propTypes = {
  className: PropTypes.string,
  labels: PropTypes.array.isRequired,
  variant: PropTypes.oneOf(Object.keys(BASE_CLASSES)),
};
