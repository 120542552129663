/**
 * Inspired by Usersnap’s React example:
 * https://github.com/usersnap/public/blob/master/react-js/src/UsersnapContext.js
 */

import { useEffect, useState, createContext, useContext } from "react";
import getConfig from "next/config";

const {
  publicRuntimeConfig: { usersnapNpsProjectApiKey, usersnapKey },
} = getConfig();

const USERSNAP_SURVEY_LAUNCH_DATE = "2022-11-26";

const UsersnapContext = createContext(null);

export const useUsersnapContext = () => {
  const context = useContext(UsersnapContext);
  if (context === undefined) {
    throw new Error(
      "useUsersnapContext must be used within a UsersnapProvider",
    );
  }
  return context;
};

const getCreatedAtWeeksAgo = (createdAt) => {
  return Math.floor(
    (new Date() - new Date(createdAt)) / (7 * 24 * 60 * 60 * 1000),
  );
};

const init = ({ api, user, version }) => {
  api.init({
    user: {
      userId: user.hash || "",
      email: user.email || "",
    },
    custom: {
      name: user.name || "",
      version,
    },
  });

  // Display Usersnap NPS and PMF surveys if eligible.
  // Do not show either survey if user is not logged in.
  if (!user.createdAt) return;

  // Do not show either survey if user registered less than a week ago.
  if (new Date(user.createdAt) < new Date(USERSNAP_SURVEY_LAUNCH_DATE)) return;

  const createdAtWeeksAgo = getCreatedAtWeeksAgo(user.createdAt);

  if (createdAtWeeksAgo >= 1) {
    api.logEvent("SME.user-eligible-for-nps-survey");
  }

  // Do not show PMF survey if user is not a member
  const userIsMember = user.roles?.includes("member");
  if (!userIsMember) return;

  if (createdAtWeeksAgo >= 4) {
    // Wait 1s for NPS survey to open before checking whether to open PMF survey.
    setTimeout(() => {
      if (
        !window.Usersnap.openSurveyApiKeys.includes(usersnapNpsProjectApiKey)
      ) {
        api.logEvent("SME.user-eligible-for-pmf-survey");
      }
    }, 1000);
  }
};

export const UsersnapProvider = ({ user, version, children }) => {
  const [usersnapApi, setUsersnapApi] = useState(null);

  useEffect(() => {
    let script = null;
    window.Usersnap = {};
    window.Usersnap.openSurveyApiKeys = [];

    window.onUsersnapCXLoad = (api) => {
      setUsersnapApi(api);

      api.on("open", (event) => {
        window.Usersnap.openSurveyApiKeys.push(event.apiKey);
      });

      init({ api, user, version });
    };

    window.requestIdleCallback(
      () => {
        script = document.createElement("script");
        script.defer = 1;
        script.src = `https://widget.usersnap.com/global/load/${usersnapKey}?onload=onUsersnapCXLoad`;
        document.head.appendChild(script);
      },
      { timeout: 5000 },
    );

    return () => {
      if (usersnapApi) usersnapApi.destroy();
      script?.remove();
    };
    // Only need to run once on mount
  }, []);

  return (
    <UsersnapContext.Provider value={usersnapApi}>
      {children}
    </UsersnapContext.Provider>
  );
};
