/**
 * Basic fetch helper that turns response into JSON, and catches errors.
 * To be used when fetching data from the API in getServerSideProps, or when
 * using the swr package.
 */

const fetcher = async (...args) => {
  try {
    if (args[1] && !args[1].redirect) {
      // fetcher throws an error if we try to intepret the response body of a 301 response
      // without specifying a manual redirect
      args[1].redirect = "manual";
    }
    let response = await fetch(...args);

    const location = response.headers.get("location");

    // Just go one redirect deep to handle the case of receiving a trailing slash redirect and avoid getting into redirect loops
    if (location && (response.status > 299) & (response.status < 400)) {
      const url = new URL(args[0]);

      const requestPath = url.pathname;
      const requestOrigin = url.origin;

      let locationPath;
      let locationIsFullUrl = false;

      // location header can either be a full url or a path so we need to handle both cases
      if (location.startsWith("http")) {
        locationPath = new URL(location).pathname;
        locationIsFullUrl = true;
      } else {
        locationPath = location.split("?")[0];
      }

      const isTrailingSlashRedirect =
        locationPath.endsWith("/") &&
        !requestPath.endsWith("/") &&
        `${requestPath}/` === locationPath;

      if (isTrailingSlashRedirect) {
        args[0] = locationIsFullUrl ? location : `${requestOrigin}${location}`;
        response = await fetch(...args);
      }
    }

    const json = await response.json();
    return json;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export default fetcher;
