import cn from "classnames";
import PropTypes from "prop-types";

import styles from "./index.module.scss";

const CLASS_NAMES = {
  S: styles.small,
  M: styles.medium,
  L: styles.large,
};

const Uppercase = ({ children, className, tag, size, ...rest } = {}) => {
  const Component = tag;
  return (
    <Component
      className={cn(CLASS_NAMES[size], styles.uppercase, className)}
      {...rest}
    >
      {children}
    </Component>
  );
};

export default Uppercase;

Uppercase.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  tag: PropTypes.oneOf(["p", "span"]).isRequired,
  size: PropTypes.oneOf(Object.keys(CLASS_NAMES)),
};

Uppercase.defaultProps = {
  className: undefined,
  size: "M",
  tag: "span",
};
