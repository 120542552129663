import CloseIcon from "@material-design-icons/svg/filled/close.svg";

import Button from "@/components/Button";
import Icon from "@/components/Icon";

import styles from "./index.module.scss";
import classNames from "classnames";

interface SignUpBannerProps {
  copy: React.ReactNode;
  onClose?: () => void;
}

const SignUpBanner = ({ copy, onClose }: SignUpBannerProps) => {
  return (
    <div
      className={classNames(styles.banner, {
        [styles.modal]: onClose,
        [styles.card]: !onClose,
      })}
    >
      <div className={styles.copy}>{copy}</div>

      {onClose && (
        <Button
          variant="anchor"
          className={styles.button}
          onClick={onClose}
          aria-label="Close"
        >
          <Icon svg={CloseIcon} />
        </Button>
      )}
    </div>
  );
};

export default SignUpBanner;
