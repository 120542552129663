import { SUPPORTED_CURRENCIES } from "src/config/data/currency";
import {
  NERD_OUT_PLAN,
  GEEK_OUT_PLAN,
  FREAK_OUT_PLAN,
  UK_ANNUAL_PLAN,
  UK_MONTHLY_EXPERIMENTAL_PLAN,
} from "src/config/data/plans";
import { Currency, MembershipType } from "src/config/types/billing";

const ANNUAL_PRICING_NAME = "Gold Annual";
const QUARTERLY_PRICING_NAME = "Gold Quarterly";
const MONTHLY_PRICING_NAME = "Gold Monthly";

interface Product {
  planId: string;
  id: string;
  currency?: Currency;
  price: number;
  sku: string;
  name: string;
}

export type TrackingMembershipType =
  | MembershipType
  | "1month"
  | "3month"
  | "1year"
  | "annual";

export const parseMembershipType = (membershipType: TrackingMembershipType) => {
  const membershipMappings = {
    "1month": "1months",
    "1months": "1months",
    "3months": "3months",
    "12months": "12months",
    "3month": "3months",
    "1year": "12months",
    annual: "12months",
  };

  return membershipMappings[membershipType];
};

export const formatSubscriptionProduct = (product: Product) => {
  return {
    planId: product.planId,
    category: "product",
    currency: product.currency ?? "gbp",
    price: product.price,
    revenue: product.price,
    total: product.price,
    quantity: 1,
    product_id: product.id,
    sku: product.sku,
    name: product.name,
    tax: 0,
  };
};

export const getLegacyProducts = () => {
  return [
    formatSubscriptionProduct({
      planId: NERD_OUT_PLAN.id,
      name: ANNUAL_PRICING_NAME,
      price: NERD_OUT_PLAN.prices.gbp.billedAt,
      id: parseMembershipType(NERD_OUT_PLAN.membershipType),
      sku: parseMembershipType(NERD_OUT_PLAN.membershipType),
      currency: SUPPORTED_CURRENCIES.gbp,
    }),
    formatSubscriptionProduct({
      planId: GEEK_OUT_PLAN.id,
      name: QUARTERLY_PRICING_NAME,
      price: GEEK_OUT_PLAN.prices.gbp.billedAt,
      id: parseMembershipType(GEEK_OUT_PLAN.membershipType),
      sku: parseMembershipType(GEEK_OUT_PLAN.membershipType),
      currency: SUPPORTED_CURRENCIES.gbp,
    }),
    formatSubscriptionProduct({
      planId: FREAK_OUT_PLAN.id,
      name: MONTHLY_PRICING_NAME,
      price: FREAK_OUT_PLAN.prices.gbp.billedAt,
      id: parseMembershipType(FREAK_OUT_PLAN.membershipType),
      sku: parseMembershipType(FREAK_OUT_PLAN.membershipType),
      currency: SUPPORTED_CURRENCIES.gbp,
    }),
  ];
};

const getDollarPricingProducts = () => {
  return [
    formatSubscriptionProduct({
      planId: NERD_OUT_PLAN.id,
      name: ANNUAL_PRICING_NAME,
      price: NERD_OUT_PLAN.prices.usd.billedAt,
      id: parseMembershipType(NERD_OUT_PLAN.membershipType),
      sku: parseMembershipType(NERD_OUT_PLAN.membershipType),
      currency: SUPPORTED_CURRENCIES.usd,
    }),
    formatSubscriptionProduct({
      planId: GEEK_OUT_PLAN.id,
      name: QUARTERLY_PRICING_NAME,
      price: GEEK_OUT_PLAN.prices.usd.billedAt,
      id: parseMembershipType(GEEK_OUT_PLAN.membershipType),
      sku: parseMembershipType(GEEK_OUT_PLAN.membershipType),
      currency: SUPPORTED_CURRENCIES.usd,
    }),
    formatSubscriptionProduct({
      planId: FREAK_OUT_PLAN.id,
      name: MONTHLY_PRICING_NAME,
      price: FREAK_OUT_PLAN.prices.usd.billedAt,
      id: parseMembershipType(FREAK_OUT_PLAN.membershipType),
      sku: parseMembershipType(FREAK_OUT_PLAN.membershipType),
      currency: SUPPORTED_CURRENCIES.usd,
    }),
  ];
};

const getUkPricingProducts = () => {
  return [
    formatSubscriptionProduct({
      planId: UK_ANNUAL_PLAN.id,
      name: ANNUAL_PRICING_NAME,
      price: UK_ANNUAL_PLAN.prices.gbp.billedAt,
      id: parseMembershipType(UK_ANNUAL_PLAN.membershipType),
      sku: parseMembershipType(UK_ANNUAL_PLAN.membershipType),
      currency: SUPPORTED_CURRENCIES.gbp,
    }),
  ];
};

const getUkPricingExperimentProducts = () => {
  return [
    formatSubscriptionProduct({
      planId: UK_MONTHLY_EXPERIMENTAL_PLAN.id,
      name: MONTHLY_PRICING_NAME,
      price: UK_MONTHLY_EXPERIMENTAL_PLAN.prices.gbp.billedAt,
      id: parseMembershipType(UK_MONTHLY_EXPERIMENTAL_PLAN.membershipType),
      sku: parseMembershipType(UK_MONTHLY_EXPERIMENTAL_PLAN.membershipType),
      currency: SUPPORTED_CURRENCIES.gbp,
    }),
  ];
};

export const allSubscriptionProducts = () => {
  return [
    ...getLegacyProducts(),
    ...getDollarPricingProducts(),
    ...getUkPricingProducts(),
    ...getUkPricingExperimentProducts(),
  ];
};
