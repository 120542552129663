import FacebookIcon from "./Facebook";
import LinkedInIcon from "./LinkedInIcon";
import TwitterXIcon from "./TwitterX";
import TikTokIcon from "./TikTok";
import InstagramIcon from "./Instagram";
import FlashcardsIcon from "./Flashcards";

export {
  FacebookIcon,
  LinkedInIcon,
  TwitterXIcon,
  TikTokIcon,
  InstagramIcon,
  FlashcardsIcon,
};
