const FlashcardsIcon = ({ className }: { className?: string }) => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    className={className}
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M20.1982 2.60006L18.8582 2.04006L18.8582 11.0701L21.2882 5.21006C21.6982 4.19006 21.2082 3.02006 20.1982 2.60006ZM0.69817 6.30006L5.65817 18.2701C5.96817 19.0201 6.69817 19.4801 7.46817 19.5001C7.72817 19.5001 7.99817 19.4601 8.25817 19.3501L15.6282 16.3001C16.3782 15.9901 16.8382 15.2701 16.8582 14.5001C16.8682 14.2301 16.8182 13.9601 16.7082 13.7001L11.7482 1.73006C11.4382 0.970061 10.6982 0.510062 9.91817 0.500062C9.65817 0.500062 9.39817 0.550061 9.14817 0.650062L1.78817 3.70006C0.76817 4.12006 0.27817 5.29006 0.69817 6.30006ZM16.8482 2.50006C16.8482 1.40006 15.9482 0.500062 14.8482 0.500062L13.3982 0.500062L16.8482 8.84006L16.8482 2.50006Z" />
  </svg>
);

export default FlashcardsIcon;
