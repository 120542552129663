const TwitterXIcon = ({ className = "" }) => (
  <svg
    className={className}
    width="26"
    height="26"
    viewBox="0 0 32 32"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.0780192 0.999878L12.4329 17.5195L0 30.9506H2.79814L13.6831 19.1914L22.4778 30.9506H32L18.95 13.5018L30.5224 0.999878H27.7243L17.6998 11.8298L9.60019 0.999878H0.0780192ZM4.19289 3.06099H8.5674L27.8845 28.8892H23.51L4.19289 3.06099Z" />
  </svg>
);

export default TwitterXIcon;
