import Script from "next/script";
import { useUser } from "src/lib/hooks/user";

const PaddleProfitWellScript = () => {
  const { user }: { user?: { email?: string } } = useUser();

  const data = user?.email ? { user_email: user.email } : {};
  return (
    <Script
      id="profitwell-js"
      // ProfitWell public API token
      data-pw-auth="95d58031f04bf394ba5145b3d80ef28a"
      dangerouslySetInnerHTML={{
        __html: `
					(function(i,s,o,g,r,a,m){i[o]=i[o]||function(){(i[o].q=i[o].q||[]).push(arguments)};
					a=s.createElement(g);m=s.getElementsByTagName(g)[0];a.async=1;a.src=r+'?auth='+
					s.getElementById(o+'-js').getAttribute('data-pw-auth');m.parentNode.insertBefore(a,m);
					})(window,document,'profitwell','script','https://public.profitwell.com/js/profitwell.js');
					profitwell('start', ${JSON.stringify(data)});
				`,
      }}
    />
  );
};

export default PaddleProfitWellScript;
