import { useRouter } from "next/router";
import cn from "classnames";
import styles from "./index.module.scss";
import { useEffect, useState } from "react";

const RouterProgressBar = () => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    router.events.on("routeChangeStart", () => {
      setLoading(true);
    });

    router.events.on("routeChangeComplete", () => {
      setLoading(false);
    });

    router.events.on("routeChangeError", () => {
      setLoading(false);
    });
  }, [router]);

  return (
    <div
      className={cn("fixed-top", styles.progressBar, {
        [styles.loading]: loading,
      })}
    ></div>
  );
};

export default RouterProgressBar;
