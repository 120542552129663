import PropTypes from "prop-types";
import Link from "next/link";
import { useRouter } from "next/router";
import LogoutIcon from "@material-design-icons/svg/filled/logout.svg";

import * as tracking from "src/lib/tracking";
import ROUTES from "src/config/data/routes";
import { EVENT_TYPES } from "src/config/data/tracking";
import * as ls from "src/lib/localStorage";
import api from "src/lib/api";
import { KEYS } from "src/config/data/localStorage";
import { generatePostAuthenticationRedirect } from "src/lib/helpers";
import Button from "src/components/Button";
import Icon from "src/components/Icon";

import styles from "./index.module.scss";

export const PERSISTENT_LOCAL_STORAGE_KEYS = [
  KEYS.revisionNoteViews,
  KEYS.topicQuestionPartSolutionViews,
  KEYS.revisionNotePdfDownloadViews,
  KEYS.theme,
  KEYS.archiveBannerDismissed,
  KEYS.aiMarkingEssayDisclaimerDismissed,
];

const LogoutLink = ({ icon = false, ...rest }) => {
  const router = useRouter();
  const redirect = generatePostAuthenticationRedirect(router.asPath);

  const href = `${ROUTES.PAGES.LOGOUT}${redirect}`;

  const handleLogoutClick = async (event) => {
    event.preventDefault();

    tracking.event(EVENT_TYPES.loggedOut);
    tracking.reset();
    Object.values(KEYS).forEach((key) => {
      if (PERSISTENT_LOCAL_STORAGE_KEYS.includes(key)) {
        // Do not remove RN views or theme or whether they've dismissed the archive banner - persist on device across sessions.
        return;
      }

      ls.removeItem(key);
    });

    const { signOut } = await import("src/lib/supertokens");
    await signOut();
    await api.get(ROUTES.API.SIGNOUT);
    window.location.reload();
  };

  if (icon) {
    return (
      <Button
        tag={Link}
        href={href}
        iconAtStart
        icon={<Icon svg={LogoutIcon} className={styles.dropdownItemIcon} />}
        onClick={handleLogoutClick}
        {...rest}
      >
        Log out
      </Button>
    );
  }

  return (
    <Link onClick={handleLogoutClick} href={href} {...rest}>
      Log out
    </Link>
  );
};

export default LogoutLink;

LogoutLink.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.bool,
};
