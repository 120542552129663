// 2 letter ISO country codes: https://www.iban.com/country-codes
export const SUPPORTED_COUNTRY_CODES = {
  GB: "GB", // UK / Northern Ireland
  US: "US", // USA
  AE: "AE", // United Arab Emirates
  KW: "KW", // Kuwait
  SA: "SA", // Saudi Arabia
  SG: "SG", // Singapore
  JO: "JO", // Jordan
  IL: "IL", // Israel
  YE: "YE", // Yemen
  IQ: "IQ", // Iraq
  QA: "QA", // Qatar
  OM: "OM", // Oman
  BH: "BH", // Bahrain
  IN: "IN", // India
} as const;

export type CountryCode = keyof typeof SUPPORTED_COUNTRY_CODES;
