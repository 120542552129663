import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { KEYS, QUEUE_KEYS } from "src/config/data/localStorage";
import * as ls from "src/lib/localStorage";

const Context = createContext();

const useGlobalContext = () => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error("useGlobalContext must be used within a GlobalProvider");
  }
  return context;
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_THEME":
      return { ...state, theme: action.payload };
    case "SET_EDIT_MODE":
      return { ...state, editMode: action.payload };
    case "SET_IMPORT_QUEUE_ITEMS":
      return { ...state, importQueueItems: action.payload };
    case "REMOVE_IMPORT_QUEUE_ITEM":
      return {
        ...state,
        importQueueItems: state.importQueueItems.filter(
          (_item, itemIndex) => itemIndex !== action.payload,
        ),
      };
    case "ADD_REVISION_NOTE_VIEW":
      return {
        ...state,
        revisionNoteViews: [action.payload, ...state.revisionNoteViews],
      };
    default:
      return state;
  }
};

const DEFAULT_INITIAL_STATE = {
  editMode: false,
  importQueueItems: [],
  revisionNoteViews: [],
};

const GlobalProvider = ({
  children,
  initialState = {},
  isExperimentClientReady = false,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    ...DEFAULT_INITIAL_STATE,
    ...initialState,
  });

  const setTheme = useCallback(
    (payload) => {
      dispatch({ type: "SET_THEME", payload });
      document.querySelector("html").dataset.bsTheme = payload;
      ls.setItem(KEYS.theme, payload);
    },
    [dispatch],
  );

  const setEditMode = useCallback(
    (payload) => dispatch({ type: "SET_EDIT_MODE", payload }),
    [dispatch],
  );

  const setImportQueueItems = useCallback(
    (payload) => dispatch({ type: "SET_IMPORT_QUEUE_ITEMS", payload }),
    [dispatch],
  );

  const removeImportQueueItem = useCallback(
    (payload) => dispatch({ type: "REMOVE_IMPORT_QUEUE_ITEM", payload }),
    [dispatch],
  );

  const addRevisionNoteView = useCallback(
    (payload) => dispatch({ type: "ADD_REVISION_NOTE_VIEW", payload }),
    [dispatch],
  );

  useEffect(() => {
    setTheme(document.querySelector("html").dataset.bsTheme);
  }, []);

  useEffect(() => {
    const syncWithLocalStorageImportQueueItems = () => {
      const initialImportQueueItems = ls.getItem(KEYS.queue)?.[
        QUEUE_KEYS.importQueue
      ];
      if (initialImportQueueItems) setImportQueueItems(initialImportQueueItems);
    };
    // On page load
    syncWithLocalStorageImportQueueItems();
    // On window focus (refresh when switching betweent tabs)
    window.addEventListener("focus", syncWithLocalStorageImportQueueItems);
    return () =>
      window.removeEventListener("focus", syncWithLocalStorageImportQueueItems);
  }, []);

  useEffect(() => {
    // Update local storage if state.importQueueItems is updated
    const queue = ls.getItem(KEYS.queue) || {};
    const newQueue = {
      ...queue,
      [QUEUE_KEYS.importQueue]: state.importQueueItems,
    };
    ls.setItem(KEYS.queue, JSON.stringify(newQueue));
  }, [state.importQueueItems]);

  const value = {
    state: {
      ...state,
      isExperimentClientReady,
    },
    actions: {
      setTheme,
      setEditMode,
      setImportQueueItems,
      removeImportQueueItem,
      addRevisionNoteView,
    },
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export { GlobalProvider, useGlobalContext };
