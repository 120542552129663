export const SITE_LANGUAGE = "en";

export const ADDRESS = {
  STREET_ADDRESS: "101 New Cavendish Street",
  LOCALITY: "London",
  REGION: "England",
  COUNTRY: "United Kingdom",
  POSTAL_CODE: "W1W 6XH",
};

export const TEAM_EMAIL = "team@savemyexams.co.uk";

export const MEMBER_COUNT = "100,000";
export const PRACTICE_QUESTION_COUNT = "100,000";
export const REVISION_NOTE_COUNT = "20,000";
export const PAST_PAPERS_MARK_SCHEME_COUNT = "30,000";

export const TRIAL_DURATION_DAYS = 7;
